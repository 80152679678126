import React, {useContext} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {adminRoutes, IRoute, privateRoutes, publicRoutes, RouteName} from '../routers';
import {Context} from '../index';
import {observer} from 'mobx-react-lite';

const AppRouter = () => {
  const {authStore} = useContext(Context);
  const mapRouterCallback = (route: IRoute) => <Route {...route} key={route.path} />;

  return (
    authStore.isAuth ?
      (authStore.user.role?.name === 'ADMIN' ?
        <Switch>
          {adminRoutes.map(mapRouterCallback)}
          {privateRoutes.map(mapRouterCallback)}
          {publicRoutes.map(mapRouterCallback)}
          <Redirect to={RouteName.ADMIN}/>
        </Switch>
        :
        <Switch>
          {privateRoutes.map(mapRouterCallback)}
          {publicRoutes.map(mapRouterCallback)}
          <Redirect to={RouteName.PROFILE}/>
        </Switch>)
        :
        <Switch>
          {publicRoutes.map(mapRouterCallback)}
          <Redirect to={RouteName.MAIN}/>
        </Switch>
  );
};

export default observer(AppRouter);