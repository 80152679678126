import React, { useState } from "react";
import "./miniTv.scss";
import FilterRow from "../filterRow/FilterRow";
import { DownOutlined } from "@ant-design/icons";
import MiniTVCard from "./miniTVCard/MiniTVCard";
import MiniTvProps, { GameType } from "../../types/props/miniTvProps";
import history from "../../history";
import gamesStore, { IGamePlayer } from "../../store/games.store";
import { observer } from "mobx-react-lite";
import PlayerCardProps from "../../types/props/playerCardProps";
import { useTranslation } from "react-i18next";

const testData: MiniTvProps[] = [];

const translatePlayerToPlayerProps = (data: IGamePlayer): PlayerCardProps => {
  return {
    country: data.country || "ru",
    name: data.lastName ? `${data.lastName} ${data.firstName}` : 'unknown',
    photo: data.photo,
    rank: data.rank,
  };
};

const MiniTv = () => {
  const [filter, setFilter] = useState<'blitz'| "all"|'rapid'|'classic'>("all");
  const handlerSetFilter = (filter: 'blitz'| "all"|'rapid'|'classic') => setFilter(filter);
  const { t } = useTranslation();

  return (
    <div className={"mini-tv"}>
      <div className="d-flex justify-between align-items-center title-wrapper w-100">
        <h2 className={"title"}>{ t('games') }</h2>
        <span className={"more"} onClick={() => history.push("/tv")}>
          { t('all') } <DownOutlined style={{ transform: "rotate(-90deg)" }} />
        </span>
      </div>
      <FilterRow>
        <div
          className={filter === "all" ? "active" : ""}
          onClick={() => handlerSetFilter("all")}
        >
          { t('all') }
        </div>
      </FilterRow>
      {gamesStore.games.map((data) => {
        const playerBlack: PlayerCardProps = translatePlayerToPlayerProps(
          data.playerBlack
        );
        const playerWhite: PlayerCardProps = translatePlayerToPlayerProps(
          data.playerWhite
        );

        return (
          (data.type === filter || filter === 'all') &&
          <MiniTVCard
            key={data.gameId}
            time={`${+data.startingTime / 60} + ${data.additionalTime}`}
            type={data.type || GameType.blitz}
            userBlack={playerBlack}
            userWhite={playerWhite}
            gameId={data.gameId}
          />
        );
      })}
    </div>
  );
};

export default observer(MiniTv);
