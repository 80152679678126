import React from 'react';

const FlagSvg = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.34279 6.68824C5.77373 6.65774 6.46707 7.57567 7.89833 7.57859C9.0784 7.58099 10.565 7.28032 10.8613 6.98428C11.1576 6.68824 11.1576 1.34537 10.8613 1.04935C10.565 0.753328 9.10507 1.88348 7.89833 1.9397C6.46862 2.00631 5.77373 1.01885 4.34279 1.04935C3.02379 1.07746 1.08366 1.64364 1.08355 1.9397C1.08345 2.23575 1.08354 12.6232 1.08355 12.3271V7.57859C1.08355 7.57859 3.02379 6.71635 4.34279 6.68824Z" stroke="#8797F5"/>
    </svg>
  );
};

export default FlagSvg;