import React, {useContext, useState} from 'react';
import {Form, Input, Modal} from 'antd';
import '../modal.scss';
import FilledButton from '../../buttons/filledButton/FilledButton';
import OutlineButton from '../../buttons/outlineButton/OutlineButton';
import {ModalProps} from '../types';
import AuthModal from '../modal/AuthModal';
import {Context} from '../../../index';
import MailConfirm from './MailConfirm';
import emitter from '../../../emitter/Emitter';
import { useTranslation } from 'react-i18next';



const Registration = (props: ModalProps) => {
  const {authStore} = useContext(Context);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation(); 

  const submit = (e: {email: string, password: string}) => {
    authStore.registration(e.email, e.password)
      .then(() => {
        emitter.emit('openMailConfirm', e.email);
      })
      .catch((err : string) => {
        if(err.includes("@")){
          setError(`${err} ${t("hasBeenRegistrated")}`);
        }
        else{
          setError(t(err));
        }
      });
  }

  const _ = () => {};

  const modalProps = {
    className: 'registration',
    title: t('registration'),
    visible: props.visible,
    handleCancel: props.handleCancel
  }

  return (
    <AuthModal {...modalProps}>
      <Form onFinish={submit}>
        <Form.Item label={null} name='email'
                   rules={[
                     {required: true, message: t('requiredField')},
                     {type: 'email', message: t('fieldForEmail')}
                   ]}>
          <Input className={error && 'input-error'} type="email" placeholder={ t('email') }/>
        </Form.Item>
        <Form.Item label={null} name='password'
                   rules={[
                     {required: true, message : t('requiredField')},
                     {min: 8, message: t('minimumSymbols')}
                   ]} >
          <Input className={error && 'input-error'} type="password" placeholder={ t('password') }/>
        </Form.Item>
        <p>{ t('register') }</p>
        {error &&
          <p className='error'>{error}</p>
        }
        <FilledButton title={ t('signUp') } />
        <OutlineButton type={'button'} title={ t('loginVerb') } handleOnClick={props.openModal || _} />
      </Form>
    </AuthModal>
  );
};

export default Registration;