import React from 'react';

const BlitzSvg = () => {
  return (
    <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.0933 0.927415C18.6235 1.15316 18.9677 1.67375 18.9677 2.25004V15.6632H26.5002C27.0113 15.6632 27.4841 15.9347 27.7417 16.3762C27.9994 16.8177 28.0032 17.3628 27.7517 17.8079L26.8086 19.477C23.2318 25.8071 18.6704 31.5276 13.2954 36.4238L11.7707 37.8127C11.3499 38.1961 10.7425 38.295 10.2218 38.0649C9.70108 37.8349 9.36516 37.3193 9.36516 36.75V23.4506H1.5835C0.789587 23.4506 0.145996 22.807 0.145996 22.0131C0.145996 21.7559 0.213511 21.5145 0.331782 21.3057C3.7028 15.0738 8.01871 9.40145 13.1261 4.49051L16.5338 1.21384C16.9492 0.814407 17.563 0.701671 18.0933 0.927415ZM4.0456 20.5756H10.8027C11.5966 20.5756 12.2402 21.2191 12.2402 22.0131V33.4812C16.8758 29.1005 20.8492 24.0668 24.0342 18.5382H17.5302C16.7363 18.5382 16.0927 17.8946 16.0927 17.1007V5.62647L15.1188 6.56291C10.8048 10.711 7.08014 15.426 4.0456 20.5756Z" fill="#555770"/>
    </svg>
  );
};

export default BlitzSvg;