import {makeAutoObservable} from 'mobx';
import {IContest} from '../models/IContest';
import ContestService from '../services/Contest.service';

class ContestStore {
  isLoading: boolean = false;
  contestsList: IContest[]|null = null;
  contest: IContest|null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(bool: boolean) {
    this.isLoading = bool;
  }

  setContestsList(contestsList: IContest[]|null) {
    this.contestsList = contestsList;
  }

  setContest(contest: IContest|null) {
    this.contest = contest;
  }

  async createContest(data: IContest) {
    this.setIsLoading(true);

    try {
      const contest = await ContestService.postContest(data);
      return contest;
    } catch (e) {
      throw e;
    } finally {
      this.setIsLoading(false);
    }
  }

  async getContestList(filter = "") {
    this.setIsLoading(true);

    try {
      const contestsData = await ContestService.getContestList(filter);
      this.setContestsList(contestsData.data);

      return {message: 'success'};
    } catch (e) {
      throw e;
    } finally {
      this.setIsLoading(false);
    }
  }

  async getContestById(id: string) {
    this.setIsLoading(true);

    try {
      const contestsData = await ContestService.getContestById(id);
      this.setContest(contestsData.data);

      return {message: 'success'};
    } catch (e) {
      throw e;
    } finally {
      this.setIsLoading(false);
    }
  }


  clearContestList() {
    this.setContestsList(null);
  }

  clearContest() {
    this.setContest(null);
  }
}

export default new ContestStore();