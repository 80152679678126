import React, { Ref, useState } from "react";
import { Checkbox, Form, Input, InputNumber } from "antd";
import SelectUi from "../../../../ui/SelectUI";
import "./gameSettingParam.scss";
import themeStore from "../../../../../store/theme.store";
import { FormInstance } from "antd/es/form/Form";
interface IProps {
  title: string;
  formName: string;
  type: "checkbox" | "select" | "input";
  text?: string;
  list?: string[];
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  value?: string;
  valueList?: string[];
  defaultChecked?: boolean | false;
  withStar? : boolean | false;
  formRef: React.RefObject<FormInstance<any>>;
  withTranslation? : boolean;
  onlyNumber? : boolean;
}
const GameSettingParam = ({
  title,
  formName,
  type,
  text,
  list,
  defaultValue,
  onChange,
  value,
  valueList,
  defaultChecked,
  withStar,
  formRef,
  withTranslation,
  onlyNumber,

}: IProps) => {
  return (
    <div className={`gameSettingParam ${themeStore.theme}`}>
      <span className="gameSettingParam__title">{title}{withStar === true ? (<span>&nbsp;&nbsp;&nbsp;*</span>) : ""}
</span>

      <div className="gameSettingParam__control">
        {type === "select" && list ? (
          <Form.Item
            name={formName}
            initialValue={defaultValue}
            rules={[{ required: true }]}
            valuePropName={value}
          >
            <SelectUi
              list={list}
              defaultValue={defaultValue}
              onChange={(value) =>{
                formRef.current?.setFieldsValue({ [formName]: value });
                if (onChange) {
                  onChange(value);
                  // if(formName == "speed") formRef.current?.resetFields(["startingTime"]);
                };
              }
              }
              value={value}
              valueList={valueList}
              withTranslation={withTranslation}
            />
          </Form.Item>
        ) : type === "checkbox" ? (
          <Form.Item
            name={formName}
            valuePropName="checked"
            initialValue={defaultChecked}
            rules={[{ required: true }]}
          >
            <Checkbox
              defaultChecked={defaultChecked}
              onChange={(e) => console.log(e)}
            >
              {text}
            </Checkbox>
          </Form.Item>
        ) : (
          <Form.Item
            name={formName}
            rules={[{ required: true }]}
          >
            {onlyNumber ? <InputNumber controls={false}/> : <Input />}
          </Form.Item>
        )}
      </div>
    </div>
  );
};

export default GameSettingParam;
