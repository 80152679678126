import "./sidebar.scss";
import React, { useContext, useEffect, useState } from "react";
import { slide as MenuSide } from "react-burger-menu";
import Logo from "../../svg/Logo";
import { useHistory } from "react-router-dom";
import { Context } from "../../index";
import { ThemeContext } from "../../providers/ThemeProvider";
import Age from "../../svg/Age";
import SocketService from "../../services/Socket.service";
import makeRandomString from "../../utils/makeRandomString";
import { observer } from "mobx-react-lite";
import emitter from "../../emitter/Emitter";
import { RouteName } from "../../routers";
import ru_LogoDark from "../../assets/images/logo/ru_logoDark.png";
import ru_LogoLight from "../../assets/images/logo/ru_logoLight.png";
import en_LogoDark from "../../assets/images/logo/en_logoDark.png";
import en_LogoLight from "../../assets/images/logo/en_logoLight.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Dropdown, Menu, Avatar, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import history from "../../history";

const Sidebar = (props: any) => {
  const router = useHistory();
  const { authStore } = useContext(Context);
  const { themeStore } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const [imgLight, setImgLight] = React.useState<string>();
  const [imgDark, setImgDark] = React.useState<string>();

  useEffect(() => {
    import(
      `../../assets/images/logo/${localStorage.getItem("lang")}_logoDark.png`
    ).then((img) => setImgDark(img.default));
    import(
      `../../assets/images/logo/${localStorage.getItem("lang")}_logoLight.png`
    ).then((img) => setImgLight(img.default));
  });

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const handlerLogout = () => {
    authStore.logout();
    SocketService.disconnectRoom();
    SocketService.userListener(makeRandomString());
  };

  const openLoginModal = () => {
    emitter.emit("openLogin", null);
  };

  const languageOverlay = (
    <Menu theme={themeStore.theme}>
      {i18next.languages
        .filter((lang) => lang !== i18next.language)
        .map((lang) => (
          <Menu.Item
            key={lang}
            onClick={() => {
              i18next.changeLanguage(lang);
              localStorage.setItem("lang", lang);
            }}
          >
            {lang.toUpperCase()}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className={`sidebar ${themeStore.theme}`}>
      <MenuSide
        {...props}
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
      >
        {/*<Logo*/}
        {/*  onClick={() => {*/}
        {/*    router.push("/");*/}
        {/*    setOpen(false);*/}
        {/*  }}*/}
        {/*/>*/}
        {themeStore.theme === "dark" ? (
          // <img
          //   src={imgDark}
          //   alt=""
          //   className="logo"
          //   onClick={() => {
          //     router.push("/");
          //     setOpen(false);
          //   }}
          // />
          <h1>下围棋</h1>
        ) : (
          // <img
          //   src={imgLight}
          //   alt=""
          //   className="logo"
          //   onClick={() => {
          //     router.push("/");
          //     setOpen(false);
          //   }}
          // />
          <h1>下围棋</h1>
        )}

        <div className="links">
          {/* <div
            className={"link ratings"}
            onClick={() => {
              router.push("/ratings");
              setOpen(false);
            }}
          >
            {t("rating")}
          </div> */}
          <div
            className={"link games"}
            onClick={() => {
              router.push("/tv");
              setOpen(false);
            }}
          >
            {t("games")}
          </div>
        </div>
        <div className="links">
          {authStore.isAuth && (
            <div
              className={"link user"}
              onClick={() => {
                router.push(`/profile/${authStore.user.id}`);
                setOpen(false);
              }}
            >
              {t("profile")}
            </div>
          )}
          {authStore.user.role && authStore.user.role.name === "ADMIN" && (
            <>
              <div
                className={"link user"}
                onClick={() => {
                  router.push("/admin/contests");
                  setOpen(false);
                }}
              >
                {t("manage")}
              </div>
              <div
                className={"link user"}
                onClick={() => {
                  router.push(`/users`);
                  setOpen(false);
                }}
              >
                {t("users")}
              </div>
            </>
          )}
          <div
            className="link theme"
            onClick={() =>
              (themeStore.theme =
                themeStore.theme === "light" ? "dark" : "light")
            }
          >
            {t("theme")}
            <div className={"tab"}>
              <div
                className={`${
                  themeStore.theme === "dark" ? "active" : ""
                } moon`}
              />
              <div
                className={`${
                  themeStore.theme === "light" ? "active" : ""
                } sun`}
              />
            </div>
          </div>
          <div className="link language">
            <Dropdown overlay={languageOverlay} placement="bottomRight">
              <div onClick={(e) => e.preventDefault()}>{t("language")}</div>
            </Dropdown>
          </div>
          {authStore.user.role && authStore.user.role.name === "ADMIN" && (
            <div
              className="link language config"
              onClick={() => {
                history.push(RouteName.LANGUAGE_CONFIG);
                setOpen(false);
              }}
            >
              {t("language_config")}
            </div>
          )}
        </div>
        <div className="links">
          <div className="link">
            <a target={"_blank"} href="/public_off.pdf" download>
              {t("userAgreement")}
            </a>
          </div>
          <div className="link">
            <a target={"_blank"} href="/politic_conf.pdf" download>
              {t("privacyPolicy")}
            </a>
          </div>
          <div className={"link"}>
            <div>
              <div
                style={{ marginBottom: "0.7rem" }}
                className={"d-flex align-items-center credits"}
              >
                <Age />{" "}
                <span style={{ marginLeft: ".6rem" }}>{t("for18")}</span>
              </div>
              <div className={"credits"}>{t("project")}. </div>
            </div>
          </div>
        </div>
        <div className="links">
          {!authStore.isAuth ? (
            <div className="link in-door" onClick={openLoginModal}>
              {t("loginVerb")}
            </div>
          ) : (
            <div className="link out-door" onClick={handlerLogout}>
              {t("logout")}
            </div>
          )}
        </div>
      </MenuSide>
    </div>
  );
};

export default observer(Sidebar);
