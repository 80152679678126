import "./transSetting.scss";

import React, {
  ChangeEventHandler,
  FC,
  useEffect,
  useState,
} from "react";
import { Form, Input } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import themeStore from "../../../../../store/theme.store";
import SelectUI from "../../../../../components/ui/SelectUI";
import OutlineButton from "../../../../../components/buttons/outlineButton/OutlineButton";
import ModalUI from "../../../../../components/ui/ModalUI";
import { useForm } from "antd/es/form/Form";
import languageStore from "../../../../../store/language.store";
import openNotification from "../../../../../utils/openNotification";
import { observer } from "mobx-react-lite";

const { TextArea } = Input;

interface ITransSettingProps {
  currentKey: [string, string];
  translate: string;
  handlerOnChangeLanguage: (value: string) => void;
  handlerOnChangeTranslate: ChangeEventHandler<HTMLTextAreaElement>;
}

const TransSetting: FC<ITransSettingProps> = ({
  currentKey,
  handlerOnChangeLanguage,
  handlerOnChangeTranslate,
  translate,
}) => {
  const { t } = useTranslation();

  const [isVisibleCreateLangModal, setIsVisibleCreateLangModal] =
    useState<boolean>(false);
  const handleOpenModal = (): void => setIsVisibleCreateLangModal(true);
  const handleCloseModal = (): void => setIsVisibleCreateLangModal(false);

  const [form] = useForm();
  const handleOnOkModal = (): void => form.submit();
  const handleFormSubmit = (e: { lang: string }): void => {
    languageStore
      .createLang(e)
      .then(() => openNotification("success", t("success"), t("createLang")))
      .catch(() => openNotification("error", t("fail"), t("errCreateLang")))
      .finally(handleCloseModal);
  };

  return (
    <>
      <div className={`trans-setting ${themeStore.theme}`}>
        <div className="d-flex align-items-center">
          <SelectUI
            list={languageStore.languages}
            defaultValue={i18next.language}
            placeholder={t("select_lang")}
            onChange={handlerOnChangeLanguage}
          />
          <OutlineButton
            title={"Создать новый перевод"}
            style={{ height: 32 }}
            handleOnClick={handleOpenModal}
          />
        </div>
        <TextArea
          className={`trans-textarea`}
          size="large"
          value={translate}
          key={currentKey[0]}
          placeholder={t("type_sentences")}
          onChange={handlerOnChangeTranslate}
        />
      </div>
      <ModalUI
        visible={isVisibleCreateLangModal}
        title={"Создать новый перевод"}
        className={"trans-setting"}
        onCancel={handleCloseModal}
        onOk={handleOnOkModal}
      >
        <Form form={form} onFinish={handleFormSubmit}>
          <Form.Item name={"lang"}>
            <Input
              placeholder={
                "Введите название языка в формате ISO 639-1 (2 буквы)"
              }
            />
          </Form.Item>
        </Form>
      </ModalUI>
    </>
  );
};

export default observer(TransSetting);
