import React, { FC, useContext, useEffect, useState } from "react";
import { Header } from "antd/es/layout/layout";
import { Avatar, Dropdown, Menu, Row, Skeleton, Space } from "antd";
import Logo from "../../svg/Logo";
import { useHistory } from "react-router-dom";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import "./navbar.scss";
import FilledButton from "../buttons/filledButton/FilledButton";
import OutlineButton from "../buttons/outlineButton/OutlineButton";
import Registration from "../auth/registration/Registration";
import Login from "../auth/login/Login";
import RemindPassword from "../auth/remindPassword/RemindPassword";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import emitter from "../../emitter/Emitter";
import userStore from "../../store/user.store";
import MailConfirm, {
  MailConfirmProps,
} from "../auth/registration/MailConfirm";
import SocketService from "../../services/Socket.service";
import makeRandomString from "../../utils/makeRandomString";
import history from "../../history";
import { RouteName } from "../../routers";
import { ThemeContext } from "../../providers/ThemeProvider";
import ru_LogoDark from "../../assets/images/logo/ru_logoDark.png";
import ru_LogoLight from "../../assets/images/logo/ru_logoLight.png";
import en_LogoDark from "../../assets/images/logo/en_logoDark.png";
import en_LogoLight from "../../assets/images/logo/en_logoLight.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LanguageMenu from "./components/LanguageMenu";
import moment from "moment";

const Navbar: FC = () => {
  const { t } = useTranslation();

  const router = useHistory();
  const [visibleReg, setVisibleReg] = useState(false);
  const [visibleLogin, setVisibleLogin] = useState(false);
  const [visibleRemindPassword, setVisibleRemindPassword] = useState(false);
  const [visibleMailConfirm, setVisibleMailConfirm] =
    useState<MailConfirmProps>({
      visible: false,
      email: "",
      className: "mail-confirm",
      handleCancel: () =>
        setVisibleMailConfirm((prevState) => ({
          ...prevState,
          visible: false,
        })),
    });

  const { authStore } = useContext(Context);
  const { themeStore } = useContext(ThemeContext);
  const [imgLight, setImgLight] = React.useState<string>();
  const [imgDark, setImgDark] = React.useState<string>();

  useEffect(() => {
    emitter.on("openLogin", openLoginModal);

    return () => {
      emitter.removeListener("openLogin", openLoginModal);
    };
  }, []);

  useEffect(() => {
    emitter.on("openRegistration", openRegistrationModal);
    return () => {
      emitter.removeListener("openRegistration", openRegistrationModal);
    };
  }, []);

  useEffect(() => {
    if (authStore.isAuth) {
      userStore.getMe();
    }
  }, []);

  useEffect(() => {
    emitter.on("openMailConfirm", (data: any) => {
      setVisibleMailConfirm((prevState) => ({
        ...prevState,
        visible: true,
        email: data,
      }));
    });
  }, []);

  useEffect(() => {
    import(
      `../../assets/images/logo/${localStorage.getItem("lang")}_logoDark.png`
    ).then((img) => setImgDark(img.default));
    import(
      `../../assets/images/logo/${localStorage.getItem("lang")}_logoLight.png`
    ).then((img) => setImgLight(img.default));
  });

  const handleCancelReg = () => {
    setVisibleReg(false);
  };
  const handleCancelLogin = () => {
    setVisibleLogin(false);
  };
  const handleCancelRemindPassword = () => {
    setVisibleRemindPassword(false);
  };
  const handlerLogout = () => {
    authStore.logout();
    SocketService.disconnectRoom();
    SocketService.userListener(makeRandomString());
  };

  const openRegistrationModal = () => {
    setVisibleLogin(false);
    setVisibleRemindPassword(false);
    setVisibleReg(true);
  };
  const openLoginModal = () => {
    setVisibleReg(false);
    setVisibleRemindPassword(false);
    setVisibleLogin(true);
  };
  const openRemindPasswordModal = () => {
    setVisibleReg(false);
    setVisibleLogin(false);
    setVisibleRemindPassword(true);
  };

  const redirect = (page: string) => () => history.push(page);

  const languageOverlay = (
    <Menu theme="dark">
      {
        i18next.languages
          .filter((lang) => lang !== i18next.language)
          .map((lang) => (
            <Menu.Item
              key={lang}
              onClick={()=>{
                i18next.changeLanguage(lang);
                localStorage.setItem('lang', lang);
                moment.locale(lang);
              }}
            >
              {lang.toUpperCase()}
            </Menu.Item>
          ))
      }
    </Menu>
  );

  const overlayProfile = (
    <Menu theme={themeStore.theme}>
      <Menu.Item
        key={"sub.item1"}
        onClick={() => {
          history.push(RouteName.PROFILE + `/${authStore.user.id}`);
        }}
      >
        {t("profile")}
      </Menu.Item>
      <Menu.Item
        key={"sub.item3"}
        onClick={() => {
          themeStore.theme = themeStore.theme === "light" ? "dark" : "light";
        }}
      >
        {t("theme")}{" "}
        <div className={"tab"}>
          <div
            className={`${themeStore.theme === "dark" ? "active" : ""} moon`}
          ></div>
          <div
            className={`${themeStore.theme === "light" ? "active" : ""} sun`}
          ></div>
        </div>
      </Menu.Item>
      <Menu.Item key={"sub.item4"}>
        <Dropdown overlay={languageOverlay} placement="bottomCenter">
          <div>{`${t(
            "language"
          )} : ${i18next.language.toLocaleUpperCase()}`}</div>
        </Dropdown>
      </Menu.Item>

      <Menu.Item onClick={handlerLogout} key={"sub.item5"}>
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const adminOverlay = (
    <Menu theme={themeStore.theme}>
      <Menu.Item
        key={"sub.item1"}
        onClick={() => {
          history.push(RouteName.PROFILE + `/${authStore.user.id}`);
        }}
      >
        {t("profile")}
      </Menu.Item>
      <Menu.Item
        key={"sub.item2"}
        onClick={() => {
          history.push("/admin/contests");
        }}
      >
        {t("manage")}
      </Menu.Item>
      <Menu.Item key={"sub.item4"} onClick={() => history.push("/users")}>
        {t("users")}
      </Menu.Item>
      <Menu.Item
        key={"archive"}
        onClick={() => history.push(RouteName.ARCHIVE)}
      >
        {t("archive")}
      </Menu.Item>
      <Menu.Item
        key={"sub.item6"}
        onClick={() => {
          themeStore.theme = themeStore.theme === "light" ? "dark" : "light";
        }}
      >
        {t("theme")}{" "}
        <div className={"tab"}>
          <div
            className={`${themeStore.theme === "dark" ? "active" : ""} moon`}
          ></div>
          <div
            className={`${themeStore.theme === "light" ? "active" : ""} sun`}
          ></div>
        </div>
      </Menu.Item>
      <Menu.Item
        key={"sub.item8"}
        onClick={() => history.push(RouteName.LANGUAGE_CONFIG)}
      >
        {t("language_config")}
      </Menu.Item>
      <Menu.Item key={"sub.item7"}>
        <Dropdown overlay={languageOverlay} placement="bottomCenter">
          <div>{t("language")}</div>
        </Dropdown>
      </Menu.Item>
      <Menu.Item onClick={handlerLogout} key={"sub.item5"}>
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Header>
      <div className="fluid-container">
        <Row justify={"space-between"} align={"middle"}>
          <div className={"d-flex justify-center align-items-center logo"}>
            {themeStore.theme === "dark" ? (
              // <img src={imgDark} onClick={() => router.push("/")} alt="" />
              <h1 onClick={() => router.push("/")}>下围棋</h1>
            ) : (
              // <img src={imgLight} onClick={() => router.push("/")} alt="" />
              <h1 onClick={() => router.push("/")}>下围棋</h1>
            )}

            {/*<Logo onClick={() => router.push("/")} />*/}
          </div>
          {/*<div onClick={() => {}} className="navbar-burger">*/}
          {/*  <span></span>*/}
          {/*</div>*/}
          
          <div className={"d-flex align-items-center md-d-none"}>
            <FilledButton
              style={{ marginRight: "1.5rem" }}
              title={t("start")}
              handleOnClick={
                authStore.isAuth
                  ? redirect("/create-game")
                  : openRegistrationModal
              }
            />
            {authStore.isAuth ? (
              <Skeleton
                className="d-flex align-items-center navbar-skeleton"
                loading={userStore.isLoading}
                active={true}
                avatar={{ shape: "circle" }}
                paragraph={false}
              >
                <Dropdown
                  overlay={
                    authStore.user.role && authStore.user.role.name === "ADMIN"
                      ? adminOverlay
                      : overlayProfile
                  }
                  placement="bottomRight"
                >
                  <div
                    className={
                      "d-flex justify-center align-items-center pointer"
                    }
                  >
                    {userStore.userData?.photo ? (
                      <Avatar
                        src={userStore.userData.photo}
                        size={32}
                        style={{ marginRight: ".55rem" }}
                      />
                    ) : (
                      <Avatar size={32} style={{ marginRight: ".55rem" }}>
                        <UserOutlined />
                      </Avatar>
                    )}
                    <span className={"navbar-name"}>
                      {authStore.user.email}
                      <DownOutlined
                        size={2}
                        style={{ marginLeft: ".8rem", fontSize: "7px" }}
                      />
                    </span>
                  </div>
                </Dropdown>
              </Skeleton>
            ) : (
              <>
                <OutlineButton
                  title={t("login")}
                  handleOnClick={openLoginModal}
                />
                <LanguageMenu />
              </>
            )}
          </div>
        </Row>
      </div>
      <Registration
        visible={
          !authStore.isAuth &&
          !visibleRemindPassword &&
          !visibleLogin &&
          visibleReg
        }
        handleCancel={handleCancelReg}
        openModal={openLoginModal}
      />
      <Login
        visible={
          !authStore.isAuth &&
          !visibleRemindPassword &&
          !visibleReg &&
          visibleLogin
        }
        openModal={openRegistrationModal}
        remindPassword={openRemindPasswordModal}
        handleCancel={handleCancelLogin}
      />
      <RemindPassword
        visible={
          !authStore.isAuth &&
          !visibleReg &&
          !visibleLogin &&
          visibleRemindPassword
        }
        handleCancel={handleCancelRemindPassword}
      />
      {visibleMailConfirm.visible && <MailConfirm {...visibleMailConfirm} />}
    </Header>
  );
};

export default observer(Navbar);
