import {action, makeAutoObservable} from 'mobx';
import UserService from '../services/User.service';
import {IPrivateUser, IUser} from '../types/models/IUser';
import {IRating} from '../types/models/IRating';
import {IDataSourceTableHistoryProfile, IPropsHistoryProfile} from '../views/profile/components/historyProfile/types';

class UserStore {
  userData: IPrivateUser|IUser|null = null;
  ratingData: IRating[]|null = null;
  usersCount: number = 0;
  isLoading: boolean = false;
  users: IUser[] = [];
  profileData: IPrivateUser|IUser|null = null;
  gameList: IDataSourceTableHistoryProfile[]|null = null;
  adminGameList: any = [];
  adminGameCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  @action
  setUserData(data: IPrivateUser|IUser|null) {
    this.userData = data;
  }

  @action
  setRatingData(data: IRating[]|null) {
    this.ratingData = data;
  }

  setProfileData(data: IPrivateUser|IUser|null) {
    this.profileData = data;
  }

  async getUser(id: string) {
    this.setLoading(true);
    try {
      this.setProfileData((await UserService.fetchUser(id)).data);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getUsers() {
    this.setLoading(true);
    try {
      this.users = (await UserService.fetchUsers()).data;
    } catch (e) {
      console.log(e);
      return e;
    } finally {
      this.setLoading(false);
    }
  }

  async getMe() {
    this.setLoading(true);
    try {
      this.setUserData((await UserService.fetchMe()).data);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  }

  async updateMe(body: any) {
    this.setLoading(true);
    try {
      return await UserService.updateMe(body);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getRating(type: 'ratingElo'|'ratingPlaymaker') {
    this.isLoading = true;
    try {
      this.setRatingData((await UserService.fetchRating(type)).data)
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async getUsersCount() {
    this.isLoading = true;
    try {
      this.usersCount = (await UserService.fetchUsersCount()).data as number;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async getGameList(userId: string) {
    this.isLoading = true;
    try {
      this.gameList = (await UserService.getGameList(userId)).data as any;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async getAdminGameList(skip = 0) {
    this.isLoading = true;
    try {
      const result = (await UserService.getAdminGameList(skip)).data as any
      this.adminGameList = [...this.adminGameList, ...result.result];
      this.adminGameCount = result.count;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async deleteUser(id: string) {
    this.isLoading = true;
    try {
      return  UserService.deleteUser(id);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async updateUser(id: string, body: any) {
    this.isLoading = true;
    try {
      return  UserService.updateUser(id, body);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async updatePhoto(file: string | Blob ) {
    this.isLoading = true;
    try {
      const formData = new FormData();
      formData.append('photo', file);
      return  UserService.updatePhoto(formData);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async updatePassport(file: string | Blob ) {
    this.isLoading = true;
    try {
      const formData = new FormData();
      formData.append('passport', file);
      return  UserService.updatePassport(formData);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async reActivate(email: string) {
    this.isLoading = true;
    try {
      await UserService.reActivate(email);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  clearUserData() {
    this.userData = null;
  }

  clearProfileData() {
    this.profileData = null;
  }

  clearGameList() {
    this.gameList = [];
  }
}

export default new UserStore();