import "./searchComponent.scss";

import React, { FC, useContext, useState } from "react";
import { slide as MenuSide } from "react-burger-menu";
import { ThemeContext } from "../../../../../providers/ThemeProvider";
import DictionarySearch from "./components/dicitonarySearch/DictionarySearch";
import DictionaryList from "./components/dictionaryList/DictionaryList";
import SelectUI from "../../../../../components/ui/SelectUI";
import i18next from "i18next";
import languageStore from '../../../../../store/language.store';

interface ISearchComponentProps {
  setKey: React.Dispatch<React.SetStateAction<[string, string]>>;
}

const SearchComponent: FC<ISearchComponentProps> = ({ setKey }) => {
  const { themeStore } = useContext(ThemeContext);
  const [search, setSearch] = useState<string>("");

  /***
   * TODO: переписать тип языка
   */
  const [langDictionary, setLangDictionary] = useState<string>("ru");

  const handlerOnChangeLang = (value: string) => setLangDictionary(value);

  return (
    <div className={`search-component ${themeStore.theme}`}>
      <div className="sidebar-lg d-flex flex-column gap-1">
        <SelectUI
          list={languageStore.languages}
          defaultValue={i18next.language}
          onChange={handlerOnChangeLang}
        />
        <DictionarySearch onChange={setSearch} />
        <DictionaryList
          langDictionary={langDictionary}
          search={search}
          setKey={setKey}
        />
      </div>
    </div>
  );
};

export default SearchComponent;
