import React, { createRef, useState } from "react";
import "./gameSettings.scss";
import { Form, InputNumber, Radio, RadioChangeEvent, Slider } from "antd";
import FilledButton from "../../../../components/buttons/filledButton/FilledButton";
import emitter from "../../../../emitter/Emitter";
import { GameType } from "../../../../types/props/miniTvProps";
import history from "../../../../history";
import { useTranslation } from "react-i18next";
import { COLOR } from "../../../../types/models/game/IGo";
import GameSettingParam from "../../../../components/go/components/gameSetting/gameSettingParam/GameSettingParam";
import themeStore from "../../../../store/theme.store";
import { FormInstance } from "antd/es/form/Form";
import Emitter from "../../../../emitter/Emitter";

interface IPropsSliderWrapper {
  inputValue: number;
  handlerOnChange: any;
  title: string;
  min: number;
  max: number;
}

export interface ICreateGame {
  rating?: string;
  rival?: string;
  color?: string;
  type?: "blitz" | "normal" | "slow";
  speed: string;
  additionalTime: string;
  startingTime: string;
  ratingType?: string;
  size?: string;
  matchName?: string;
  isPrivate: boolean;
  isRengo: boolean;
  isRating: boolean;
  rules: string;
  timeControl: string;
  maxTime: string;
  stopOnWeekends: boolean;
  fora: boolean;
  komi: string;
  stopAnalyze: boolean;
  limitedLevel: boolean;
}

export enum RatingType {
  "friendly" = "Товарищеская",
  "rate" = "Рейтинговая",
}

// const SliderWrapper = (props: IPropsSliderWrapper) => {
//   return (
//     <div className="slider">
//       <div className="d-flex slider--info justify-between align-items-center">
//         <div className="info__title">{props.title}</div>
//         <div className="info">
//           <InputNumber
//             min={props.min}
//             max={props.max}
//             value={props.inputValue}
//             controls={false}
//             onChange={props.handlerOnChange}
//           />
//         </div>
//       </div>
//       <Slider
//         min={props.min}
//         max={props.max}
//         onChange={props.handlerOnChange}
//         value={props.inputValue}
//         step={1}
//         trackStyle={{ background: "#4B2DFC" }}
//         handleStyle={{ background: "#8797F5", borderColor: "#8797F5" }}
//       />
//     </div>
//   );
// };

const GameSettings = () => {
  // const [inputValue, setInputValue] = useState<number>(10);
  // const [inputSecondsValue, setInputSecondsValue] = useState<number>(3);
  // const [rating, setRating] = useState<string>("my");
  // const [rival, setRival] = useState<string>("user");
  // const [color, setColor] = useState<COLOR>(COLOR.white);
  // const [ratingType, setRatingType] = useState<string>("friendly");
  // const [computerDifficulty, setComputerDifficulty] = useState<number>(4);
  const formRef = createRef<FormInstance>();
  const [speed, setSpeed] = useState<string>("");
  const { t } = useTranslation();
  const submit = (e : any) => {
    formRef.current?.validateFields().then(() => {
      Emitter.emit("createGame",e);
    });
  }
  const handleOnChangeSpeed = (speed : string)=>{
    setSpeed(speed);

  }
  
  
  const basic_setting = (
    <div className={`gameSettings ${themeStore.theme}`}>
        {/* <GameSettingParam
          title={t("nameOfMatch")}
          type="input"
          formName="matchName"
          formRef={formRef}
        /> */}
        <GameSettingParam
          title={t("private")}
          type="checkbox"
          formName="isPrivate"
          defaultChecked={false}
          formRef={formRef}
        />
        <GameSettingParam
          title={t("rengo")}
          type="checkbox"
          formName="isRengo"
          defaultChecked={false}
          formRef={formRef}
        />
        <GameSettingParam
          title={t("rating")}
          formName="isRating"
          type="checkbox"
          defaultChecked={true}
          formRef={formRef}
        />
        <GameSettingParam
          title={t("sizeOfBoard")}
          formName="size"
          type="select"
          list={["9x9", "13x13", "19x19"]}
          defaultValue="19x19"
          formRef={formRef}
        />

    </div>
  );
  const advanced_setting = (
    <div className={`gameSettings ${themeStore.theme}`}>
        <GameSettingParam
          title={t("ruleOfGame")}
          formName="rules"
          type="select"
          list={["japanese", "chinese"]}
          defaultValue={"japanese"}
          formRef={formRef}
          withTranslation={true}
        />
        <GameSettingParam
          title={t("speedOfGame")}
          formName="speed"
          type="select"
          list={["blitz", "normal", "slow"]}
          defaultValue={"normal"}
          formRef={formRef}
          withTranslation={true}
          onChange={handleOnChangeSpeed}
        />
        {/* <GameSettingParam
          title={t("controlOfTime")}
          formName="timeControl"
          type="select"
          list={["simple","fisherWatch"]}
          defaultValue="simple"
          formRef={formRef}
          withTranslation={true}
        /> */}

        {
        speed == "blitz" ? (
          <GameSettingParam
          title={t("initTime")}
          formName="startingTime"
          type="select"
          list={["5s", "10s", "30s", "1min"]}
          valueList={["5","10","30","60"]}
          defaultValue={"5"}
          formRef={formRef}
          withTranslation={true}
        />
        ) : speed == "slow" ? (
          <GameSettingParam
          title={t("initTime")}
          formName="startingTime"
          type="select"
          list={["1day", "2days", "3days"]}
          formRef={formRef}
          withTranslation={true}
          valueList={[`${1*86400}`,`${2*86400}`,`${3*86400}`] }
          defaultValue={`${3*86400}`}
        />
        ) : (
          <GameSettingParam
          title={t("initTime")}
          formName="startingTime"
          type="select"
          list={["1min", "5mins", "10mins"]}
          valueList={[`${1*60}`,`${5*60}`,`${10*60}`]}
          defaultValue={`${5*60}`}
          formRef={formRef}
          withTranslation={true}
        />
        )
      }
        {/* <GameSettingParam
          title={t("addAdditionalTime")}
          formName="additionalTime"
          type="select"
          list={["1day", "3days"]}
          valueList={[`${1*86400}`,`${3*86400}`]}
          defaultValue={"1day"}
          formRef={formRef}
          withTranslation={true}
        /> */}
        {/* <GameSettingParam
          title={t("maxTime")}
          formName="maxTime"
          type="select"
          list={["3days", "7days"]}
          valueList={[`${3*86400}`,`${7*86400}`]}
          formRef={formRef}
          withTranslation={true}
        /> */}
        {/* <GameSettingParam
          title={t("breakOnWeekends")}
          formName="stopOnWeekends"
          type="checkbox"
          defaultChecked={true}
          formRef={formRef}
        /> */}
        {/* <GameSettingParam
          title={t("fora")}
          formName="fora"
          type="checkbox"
          defaultChecked={false}
          formRef={formRef}
        /> */}
        <GameSettingParam
          title={t("komi")}
          formName="komi"
          type="input"
          defaultValue={"6.5"}
          formRef={formRef}
          onlyNumber={true}
        />
        <GameSettingParam
          title={t("yourColor")}
          formName="color"
          type="select"
          list={["white", "black"]}
          defaultValue={"black"}
          formRef={formRef}
          withTranslation={true}
        />
        {/* <GameSettingParam
          title={t("disableAnalyze")}
          formName="stopAnalyze"
          type="checkbox"
          defaultChecked={false}
          withStar={true}
          formRef={formRef}
        /> */}
        {/* <GameSettingParam
          title={t("limitLevel")}
          formName="limitedLevel"
          type="checkbox"
          defaultChecked={false}
          formRef={formRef}
        /> */}
        {/* <p style={{ textAlign: "right" }}>
          * {t("alsoLimitMoves")} &nbsp;&nbsp;&nbsp;
        </p> */}
    </div>
  );
  return (
    <div className="game-settings">
      <h3 className="settings--title">{t("createGame")}</h3>
      <Form name="gameSetting" onFinish={submit} ref={formRef}>
        {basic_setting}
        {advanced_setting}

        <FilledButton
          className={"create-game__button"}
          title={t("createGame")}
          handleOnClick={submit}
        />
      </Form>
    </div>
  );
};

export default GameSettings;
