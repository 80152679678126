import React from 'react';
import FilledButton from '../buttons/filledButton/FilledButton';
import './messages.scss';
import { TransitionGroup  } from 'react-transition-group';

type messageProps = {
  message: string;
  onClick: any;
  color: string;
};

const Messages = (props: messageProps) => {
  return (
    <TransitionGroup
      transitionName="example"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={300}>
      <div style={{background: props.color}} className='d-flex message justify-between align-items-center'>
        <p>
          {props.message}
        </p>
        <FilledButton title={'Подтвердить'} handleOnClick={props.onClick} />
      </div>
    </TransitionGroup>
  );
};

export default Messages;