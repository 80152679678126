import "./shortCardProfile.scss";
import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import NameProfile from "../name/NameProfile";
import RatingCard from "../ratingCard";
import { IPropsRatingCardProfile, RatingType } from "../ratingCard/types";
import { IPropsShortCardProfile } from "./types";
import {observer} from 'mobx-react-lite';

const ShortCardProfile = ({
  name,
  email,
  ratingPlaymaker,
  ratingElo,
  avatar,
}: IPropsShortCardProfile) => {
  const ratings: IPropsRatingCardProfile[] = [
    { ratingType: RatingType.elo, value: ratingElo },
    { ratingType: RatingType.playmaker, value: ratingPlaymaker },
  ];

  const nameData = name
    ? { ...name }
    : { name: email, country: null, rank: null };

  return (
    <div className="short-card-profile card">
      <div className="d-flex flex-column align-items-center avatar--card">
        {avatar ? <Avatar src={avatar} /> : <Avatar icon={<UserOutlined />} />}
        <NameProfile {...nameData} />

        <div className="d-flex justify-between" style={{ columnGap: ".5rem" }}>
          {ratings.map((rating) => (
            <RatingCard {...rating} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(ShortCardProfile);
