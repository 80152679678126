import React from 'react';
import './createGame.scss';
import FastStart from '../../components/fastStart/FastStart';
import GameSettings from './components/gameSettings/GameSettings';
import { useTranslation } from 'react-i18next';

const CreateGame = () => {
  const{ t } = useTranslation();
  return (
    <div className='create-game'>
      <div className="fluid-container">
        <h1 className='title'>{ t('start') }</h1>
        <div className="d-flex justify-between md-column">
          <GameSettings />
          <div className='right-block'>
            <FastStart />
            <h2 className="instruction">
              { t('forStarting') }
            </h2>
            <pre>
            <p className='main__paragraph'>
                {t('forStartingToPlay')}
            </p>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGame;