import React from 'react';
import FilterRowProps from '../../types/props/filterRowProps';
import './filterRow.scss';

const FilterRow = (props: FilterRowProps) => {
  return (
    <div className='filter-row' style={props.style || {}}>
      {props.children}
    </div>
  );
};

export default FilterRow;