import React, {useContext} from 'react';
import {Modal, Spin} from 'antd';
import CloseModalSvg from '../../../svg/CloseModalSvg';
import {ModalProps} from '../types';
import {Context} from '../../../index';
import {observer} from 'mobx-react-lite';
import {ThemeContext} from '../../../providers/ThemeProvider';

const AuthModal = (props: ModalProps) => {
  const {authStore} = useContext(Context);
  const {themeStore} = useContext(ThemeContext);

  const style = {
    background: themeStore.theme === 'dark' ? '#303249' : '#FFFFFF',
    borderRadius: '12px',
    border: 0,
  };
  
  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={<CloseModalSvg />}
      visible={props.visible}
      bodyStyle={style}
      wrapClassName={'modal'}
      className={props.className}
      onOk={props.handleCancel}
      onCancel={props.handleCancel}
      centered={true}
    >
      <h2>{props.title}</h2>
      <Spin spinning={authStore.isMiniLoading}>
        {props.children}
      </Spin>
    </Modal>
  );
};

export default observer(AuthModal);