import React from 'react';

const InterfaceLayoutSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.92906 2.47033C5.65784 2.32825 4.34193 2.32825 3.07071 2.47033C2.76182 2.50485 2.51823 2.74944 2.48348 3.0465C2.33168 4.34444 2.33168 5.65565 2.48348 6.95359C2.51823 7.25065 2.76182 7.49524 3.07071 7.52976C4.34193 7.67184 5.65784 7.67184 6.92906 7.52976C7.23795 7.49524 7.48154 7.25065 7.51629 6.95359C7.66809 5.65565 7.66809 4.34444 7.51629 3.0465C7.48154 2.74944 7.23795 2.50485 6.92906 2.47033ZM2.9041 0.979609C4.28605 0.825156 5.71372 0.825156 7.09567 0.979609C8.08651 1.09035 8.88895 1.87034 9.00613 2.87225C9.17148 4.28596 9.17148 5.71413 9.00613 7.12784C8.88895 8.12975 8.08651 8.90974 7.09567 9.02048C5.71372 9.17493 4.28605 9.17493 2.90411 9.02048C1.91327 8.90974 1.11082 8.12975 0.993639 7.12784C0.828292 5.71413 0.828292 4.28596 0.993639 2.87225C1.11082 1.87034 1.91326 1.09035 2.9041 0.979609Z" fill="#8F90A6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.92906 12.4703C5.65784 12.3282 4.34193 12.3282 3.07071 12.4703C2.76182 12.5049 2.51823 12.7494 2.48348 13.0465C2.33168 14.3444 2.33168 15.6557 2.48348 16.9536C2.51823 17.2506 2.76182 17.4952 3.07071 17.5298C4.34193 17.6718 5.65784 17.6718 6.92906 17.5298C7.23795 17.4952 7.48154 17.2506 7.51629 16.9536C7.66809 15.6556 7.66809 14.3444 7.51629 13.0465C7.48154 12.7494 7.23795 12.5049 6.92906 12.4703ZM2.9041 10.9796C4.28605 10.8252 5.71372 10.8252 7.09567 10.9796C8.08651 11.0904 8.88895 11.8703 9.00613 12.8722C9.17148 14.286 9.17148 15.7141 9.00613 17.1278C8.88895 18.1298 8.08651 18.9097 7.09567 19.0205C5.71372 19.1749 4.28605 19.1749 2.90411 19.0205C1.91327 18.9097 1.11082 18.1298 0.993639 17.1278C0.828292 15.7141 0.828292 14.286 0.993639 12.8722C1.11082 11.8703 1.91326 11.0904 2.9041 10.9796Z" fill="#8F90A6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9291 2.47033C15.6578 2.32825 14.3419 2.32825 13.0707 2.47033C12.7618 2.50485 12.5182 2.74944 12.4835 3.0465C12.3317 4.34444 12.3317 5.65565 12.4835 6.95359C12.5182 7.25065 12.7618 7.49524 13.0707 7.52976C14.3419 7.67184 15.6578 7.67184 16.9291 7.52976C17.2379 7.49524 17.4815 7.25065 17.5163 6.95359C17.6681 5.65565 17.6681 4.34444 17.5163 3.0465C17.4815 2.74944 17.2379 2.50485 16.9291 2.47033ZM12.9041 0.979609C14.2861 0.825156 15.7137 0.825156 17.0957 0.979609C18.0865 1.09035 18.889 1.87034 19.0061 2.87225C19.1715 4.28596 19.1715 5.71413 19.0061 7.12784C18.889 8.12975 18.0865 8.90974 17.0957 9.02048C15.7137 9.17493 14.2861 9.17493 12.9041 9.02048C11.9133 8.90974 11.1108 8.12975 10.9936 7.12784C10.8283 5.71413 10.8283 4.28596 10.9936 2.87225C11.1108 1.87034 11.9133 1.09035 12.9041 0.979609Z" fill="#8F90A6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9291 12.4703C15.6578 12.3282 14.3419 12.3282 13.0707 12.4703C12.7618 12.5049 12.5182 12.7494 12.4835 13.0465C12.3317 14.3444 12.3317 15.6557 12.4835 16.9536C12.5182 17.2506 12.7618 17.4952 13.0707 17.5298C14.3419 17.6718 15.6578 17.6718 16.9291 17.5298C17.2379 17.4952 17.4815 17.2506 17.5163 16.9536C17.6681 15.6556 17.6681 14.3444 17.5163 13.0465C17.4815 12.7494 17.2379 12.5049 16.9291 12.4703ZM12.9041 10.9796C14.2861 10.8252 15.7137 10.8252 17.0957 10.9796C18.0865 11.0904 18.889 11.8703 19.0061 12.8722C19.1715 14.286 19.1715 15.7141 19.0061 17.1278C18.889 18.1298 18.0865 18.9097 17.0957 19.0205C15.7137 19.1749 14.2861 19.1749 12.9041 19.0205C11.9133 18.9097 11.1108 18.1298 10.9936 17.1278C10.8283 15.7141 10.8283 14.286 10.9936 12.8722C11.1108 11.8703 11.9133 11.0904 12.9041 10.9796Z" fill="#8F90A6"/>
    </svg>
  );
};

export default InterfaceLayoutSvg;