import { Console, timeStamp } from 'console';
import {makeAutoObservable} from 'mobx';
import {IContestWinner} from '../models/IContestWinner';
import ContestWinnerService from '../services/ContestWinner.service';
class WinnerStore {
  isLoading: boolean = false;
  winners: IContestWinner[]|null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(bool: boolean) {
    this.isLoading = bool;
  }
  setWinners(winners: IContestWinner[] | null){
    this.winners = winners;
  }
  async getWinners(id:string) {
    this.setIsLoading(true);

    try {
      const data = await ContestWinnerService.getWinners(id);

      this.setWinners(data.data);

      return {message: 'success'};
    } catch (e) {
      throw e;
    } finally {
      this.setIsLoading(false);
    }
  }

  async setPayed(e: {_id: string, payed: boolean}) {
    this.setIsLoading(true);
    try {
      const data = await ContestWinnerService.setPayed(e);
      return data;
    } catch (e) {
      throw e;
    } finally {
      this.setIsLoading(false);
    }
  }

  clearWinners(){
    this.setWinners([]);
  }
}

export default new WinnerStore();