import React, {CSSProperties, MouseEventHandler} from 'react';
import './filledButton.scss';
import IButtonProps from '../../../types/props/buttonProps';

const FilledButton = (props: IButtonProps) => {
  let classes = 'filled-button';
  if (props.className) {
    classes += ` ${props.className}`;
  }
  return (
    <button
      style={props.style}
      onClick={props.handleOnClick}
      className={classes}
      disabled={props.disabled}>
      {props.title}
    </button>
  );
};

export default FilledButton;