import "./LanguageMenu.scss"

import React, { useContext } from "react";

import { Button, Dropdown, Menu } from "antd";
import i18next from "i18next";
import { ThemeContext } from "../../../providers/ThemeProvider";
import moment from "moment";
const LanguageMenu = () => {
  const { themeStore } = useContext(ThemeContext);

  const menu = (
    <Menu theme="dark">
      {i18next.languages
        .filter((lang) => lang !== i18next.language)
        .map((lang) => (
          <Menu.Item
            key={lang}
            onClick={() => {
              i18next.changeLanguage(lang);
              localStorage.setItem('lang', lang);
              moment.locale(lang);
            }}
          >
            {lang.toUpperCase()}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button 
      className={`lm-btn  ${themeStore.theme === "dark" ? "dark" : "light"}`}
      >
        {i18next.language.toUpperCase()}
      </Button>
    </Dropdown>
  );
};

export default LanguageMenu;
