import { COLOR, IPosition } from "../../../../types/models/game/IGo";
import _ from "lodash";
import Estimator from "../../../../utils/estimator";
import Emitter from "../../../../emitter/Emitter";

interface IGoBoard {
  currentColor: COLOR;
  size: number;
  lastMovePassed: boolean;
  inAtari: boolean;
  attemptedSuicide: boolean;
  board: COLOR[][];

  createBoard: (size: number) => COLOR[][];
  switchPlayer: () => void;
  turn:() => COLOR;
  pass: () => void;
  endGame: (estimator:Estimator) => void;
  play: (i: number, j: number) => boolean;
  getAdjacentIntersections: (i: number, j: number) => number[][];
  getGroup: (
    i: number,
    j: number
  ) => { liberties: number; stones: number[][] } | null;
}

class GoBoard implements IGoBoard {
  attemptedSuicide: boolean;
  currentColor: COLOR;
  inAtari: boolean;
  lastMovePassed: boolean;
  size: number;
  board: COLOR[][];
  playerColor: COLOR;
  isOver: boolean;

  dead_white: number;
  dead_black: number;
  stone_white: number;
  stone_black: number;
  dead_collections: IPosition[] = [];

  constructor(size: number) {
    this.attemptedSuicide = false;
    this.currentColor = COLOR.black;
    this.inAtari = false;
    this.lastMovePassed = false;
    this.size = size;
    this.board = this.createBoard(this.size);
    this.playerColor = COLOR.empty;
    this.isOver = false;
    this.dead_black = 0;
    this.dead_white = 0;
    this.stone_white = 0;
    this.stone_black = 0;
  }

  setPlayerColor(playColor: COLOR){
    this.playerColor = playColor;
  }

  createBoard(size: number): COLOR[][] {
    const m: COLOR[][] = [];
    for (let i = 0; i < size; i++) {
      m[i] = [];
      for (let j = 0; j < size; j++) m[i][j] = COLOR.empty;
    }
    return m;
  }

  endGame(): void {
    this.isOver = true;
    console.log("GAME OVER");
    // Emitter.emit("estimate",null);
  }

  getAdjacentIntersections(i: number, j: number): number[][] {
    const neighbors: number[][] = [];
    if (i > 0) neighbors.push([i - 1, j]);
    if (j < this.size - 1) neighbors.push([i, j + 1]);
    if (i < this.size - 1) neighbors.push([i + 1, j]);
    if (j > 0) neighbors.push([i, j - 1]);
    return neighbors;
  }

  getGroup(
    i: number,
    j: number
  ): { liberties: number; stones: number[][] } | null {
    const color = this.board[i][j];
    if (color == COLOR.empty) return null;

    const visited = {};
    const visited_list = [];
    const queue = [[i, j]];
    let count = 0;

    while (queue.length > 0) {
      const stone = queue.pop();
      if (stone) {
        // @ts-ignore
        if (stone && visited[stone]) continue;

        const neighbors = this.getAdjacentIntersections(stone[0], stone[1]);
        const self = this;
        _.each(neighbors, function (n) {
          const state = self.board[n[0]][n[1]];
          if (state == COLOR.empty) count++;
          if (state == color) queue.push([n[0], n[1]]);
        });

        // @ts-ignore
        visited[stone] = true;
        visited_list.push(stone);
      }
    }

    return {
      liberties: count,
      stones: visited_list,
    };
  }

  pass(): void {
    if (this.lastMovePassed) this.endGame();
    this.lastMovePassed = true;
    this.switchPlayer();
  }

  play(i: number, j: number): boolean {
    this.attemptedSuicide = this.inAtari = false;
    if (this.board[i][j] != COLOR.empty) return false;
    // if (this.playerColor.toString() != this.currentColor.toString()) return false;
    console.log("Played at " + i + ", " + j);
    const color = this.board[i][j] = this.currentColor ;
    const captured: { liberties: number; stones: number[][] }[] | null = [];
    const neighbors = this.getAdjacentIntersections(i, j);
    let atari = false;

    const self = this;

    _.each(neighbors, function (n) {
      const state = self.board[n[0]][n[1]];

      if (state != COLOR.empty && state != color) {

        const group = self.getGroup(n[0], n[1]);

        if (group && group.liberties == 0) {
          captured.push(group);
        } else if (group?.liberties == 1) {
          atari = true;
        }

      }
    });

    if (_.isEmpty(captured) && this.getGroup(i, j)?.liberties == 0) {
      this.board[i][j] = COLOR.empty;
      this.currentColor === COLOR.black ? this.dead_white++ : this.dead_black++;
      this.currentColor === COLOR.black ? this.stone_white-- : this.stone_black--;
      this.dead_collections.push({i:i,j:j});
      this.attemptedSuicide = true;
      return false;
    }

    _.each(captured, function (group) {
      _.each(group["stones"], function (stone) {
        self.currentColor === COLOR.black ? self.dead_white++ : self.dead_black++;
        self.currentColor === COLOR.black ? self.stone_white-- : self.stone_black--;
        self.dead_collections.push({i:stone[0],j:stone[1]});
        self.board[stone[0]][stone[1]] = COLOR.empty;
      });
    });

    if (atari) this.inAtari = true;
    this.currentColor === COLOR.black ? this.stone_black ++ : this.stone_white ++;

    this.lastMovePassed = false;
    this.switchPlayer();

    
    return true;
  }

  switchPlayer(): void {
    this.currentColor =
      this.currentColor == COLOR.black ? COLOR.white : COLOR.black;
  }

  turn() : COLOR {
    const nextColor = this.currentColor == COLOR.black ? COLOR.white : COLOR.black;
    return nextColor;
  }

}

export default GoBoard;
