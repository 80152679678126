import "./competitionRegulation.scss";
import React, { useEffect, useState } from "react";
import Title from "../../components/title/Title";
import { useParams } from "react-router";
import contestStore from "../../store/contest.store";
import { Spin } from "antd";
import RegulationCard from "../competitionsRegulations/components/regulationCard/RegulationCard";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { augostRegulation } from "./regulations/augost";
import i18next from "i18next";
import { IContestDetails } from "../../models/IContest";
const CompetitionRegulation = () => {
  const { id } = useParams<any>();
  const { t } = useTranslation();
  const [details, setDetails] = useState<IContestDetails | undefined>(
    undefined
  );
  useEffect(
    () =>
      setDetails(
        contestStore.contest?.details?.filter(
          (item) => item.LANGUAGE === i18next.language
        )[0]
      ),
    [i18next.language]
  );

  useEffect(() => {
    contestStore
      .getContestById(id)
      .then(() =>
        setDetails(
          contestStore.contest?.details?.filter(
            (item) => item.LANGUAGE === i18next.language
          )[0]
        )
      );

    return contestStore.clearContest();
  }, []);
  
  

  return (
    <div className="competition-regulation">
      <div className="container">
        <Title title={t("regulation")} />
        <div className="card">
          <Spin spinning={contestStore.isLoading}>
            {contestStore.contest && (
              <>
                <RegulationCard
                  name={details?.name || contestStore.contest.name}
                  description={
                    details?.description || contestStore.contest.description
                  }
                  prize={contestStore.contest.prize}
                  distribution={contestStore.contest.distribution}
                  dateStart={contestStore.contest.dateStart}
                  dateEnd={contestStore.contest.dateEnd}
                  labeled={false}
                  placesDistribution={contestStore.contest.placesDistribution}
                />
                <div className="bordered-top">
                  <iframe
                    width={"100%"}
                    style={{ height: "100vh" }}
                    srcDoc={details?.regulation}
                  ></iframe>
                </div>
              </>
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default observer(CompetitionRegulation);
