// @ts-nocheck
import "./editorComponent.scss";

import TransSetting from "../transSetting/TransSetting";
import i18next, { t } from "i18next";
import themeStore from "../../../../../store/theme.store";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import FilledButton from "../../../../../components/buttons/filledButton/FilledButton";
import languageStore from "../../../../../store/language.store";
import openNotification from "../../../../../utils/openNotification";

interface IEditorComponentProps {
  currentKey: [string, string];
}

const EditorComponent: FC<IEditorComponentProps> = ({ currentKey }) => {
  const [lang, setLang] = useState(i18next.language);
  const [translate, setTranslate] = useState<string>("");

  useEffect(() => {
    setTranslate(languageStore.translation[lang][currentKey[0]]);
  }, [currentKey, lang]);

  const handlerOnChangeLang = (value: string) => setLang(value);
  const handlerOnChangeTranslate = (e: SyntheticEvent<HTMLTextAreaElement>) =>
    setTranslate(e.currentTarget.value);

  const handleOnSaveChanges = () =>
    languageStore
      .updateLang({ lang, translate, key: currentKey[0] })
      .then(() => openNotification("success", t("success"), t("updatedLang")))
      .catch(() => openNotification("error", t("fail"), t("errUpdateLang")));
  return (
    <div className={`editor-component ${themeStore.theme}`}>
      <div className="details">
        <TransSetting
          translate={translate}
          currentKey={currentKey || null}
          handlerOnChangeLanguage={handlerOnChangeLang}
          handlerOnChangeTranslate={handlerOnChangeTranslate}
        />
      </div>
      <FilledButton
        handleOnClick={handleOnSaveChanges}
        title={t("saveChanges")}
      />
    </div>
  );
};

export default observer(EditorComponent);
