import React from 'react';

const FireSvg = () => {
  return (
    <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5109 0.513556C10.8138 0.165071 11.2841 0.011916 11.7341 0.115226C17.7488 1.49609 20.7891 8.08711 20.0741 13.984C19.7961 16.2763 19.1095 18.0734 18.0984 19.5032C18.0923 19.5119 18.0861 19.5206 18.0799 19.5293C18.3095 19.4165 18.5214 19.2947 18.7197 19.166C19.7062 18.5257 20.4303 17.6806 21.3731 16.5803C21.487 16.4474 21.6042 16.3106 21.7253 16.1701C21.9977 15.8543 22.4096 15.6951 22.8236 15.7456C23.2375 15.7962 23.599 16.0499 23.7873 16.422C24.6165 18.0607 25.0832 19.9133 25.0832 21.8712C25.0832 28.5447 19.6733 33.9546 12.9998 33.9546C6.3264 33.9546 0.916504 28.5447 0.916504 21.8712C0.916504 17.6428 3.08935 13.9227 6.37369 11.7653L6.48629 11.6518C6.54413 11.5935 6.60758 11.5411 6.67571 11.4952C8.11881 10.5242 9.15503 9.72992 9.85268 8.79588C10.5117 7.91352 10.9165 6.83716 10.9165 5.20456C10.9165 3.99577 10.6933 2.84203 10.287 1.7803C10.122 1.3491 10.2081 0.86204 10.5109 0.513556ZM13.2852 3.41818C13.3717 4.00148 13.4165 4.59803 13.4165 5.20456C13.4165 7.31859 12.8703 8.9334 11.8556 10.2919C10.9027 11.5677 9.58363 12.5476 8.17427 13.5L8.05377 13.6214C7.98921 13.6865 7.91768 13.7442 7.84049 13.7937C5.1773 15.4987 3.4165 18.4797 3.4165 21.8712C3.4165 27.164 7.70711 31.4546 12.9998 31.4546C18.2926 31.4546 22.5832 27.164 22.5832 21.8712C22.5832 21.0049 22.4685 20.1664 22.2537 19.3696C21.62 20.0558 20.9206 20.7179 20.0807 21.263C18.5419 22.2617 16.6164 22.8318 13.8332 22.8318C13.2627 22.8318 12.7647 22.4457 12.6226 21.8932C12.4805 21.3408 12.7304 20.7622 13.23 20.487C14.3614 19.8637 15.3204 19.1018 16.0573 18.0597C16.7926 17.0198 17.356 15.6314 17.5923 13.6831C18.1037 9.46517 16.3581 5.30194 13.2852 3.41818Z" fill="#555770"/>
    </svg>
  );
};

export default FireSvg;