import "./dashedInfo.scss";
import React from "react";

interface IProps {
  description: string;
  value: string;
}

const DashedInfo = ({ description, value }: IProps): JSX.Element => {
  return (
    <div className="dashed-info d-flex">
      <div className="description">{description}</div>
      <div className="underline"></div>
      <div className="value">{value}</div>
    </div>
  );
};

export default DashedInfo;
