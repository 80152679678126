import "./avatarUploader.scss";
import React, { useState } from "react";
import { IPropsAvatarUploader } from "./types";
import { Form, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import AvatarUploaderSvg from "../../../../svg/AvatarUploaderSvg";
import ImgCrop from "antd-img-crop";
import userStore from "../../../../store/user.store";
import openNotification from "../../../../utils/openNotification";
import {observer} from 'mobx-react-lite';
import { useTranslation } from "react-i18next";

const AvatarUploader = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const uploadButton = loading ? <LoadingOutlined /> : <AvatarUploaderSvg />;
  const { t } = useTranslation();
  return (
    <div className="avatar-uploader__wrapper">
      <Form layout={"vertical"}>
        <Form.Item label={t('upload')} name="upload" colon={false}>
          <ImgCrop rotate modalTitle="Сохранить фото?" modalOk={"Сохранить"}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={({ file, onSuccess }) => {
                userStore
                  .updatePhoto(file)
                  .then(() => userStore.getMe())
                  .then(onSuccess)
                  .then(() =>
                    openNotification(
                      "success",
                      t('success'),
                      t('successSaveAvatar')
                    )
                  )
                  .catch((e: any) =>
                    openNotification(
                      "error",
                      t('error'),
                      t('failSaveAvatar')
                    )
                  );
              }}
            >
              {userStore.userData?.photo ? (
                <img src={userStore.userData?.photo} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(AvatarUploader);
