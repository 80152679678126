import {createContext, FC} from 'react';
import LanguageStore from '../store/language.store';
import languageStore from '../store/language.store';

interface IStore {
  languageStore: typeof LanguageStore;
}

export const LanguageContext = createContext<IStore>({
  languageStore,
});

const LanguageProvider:FC = ({children}) => {
  return (
    <LanguageContext.Provider value={{ languageStore }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider;