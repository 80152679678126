import React from 'react';

const HalfSvg = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5835 8.00033H4.77548V3.73293L3.5835 4.17829V3.42994L5.47999 2.66699H5.5835V8.00033Z" fill="#8797F5"/>
      <path d="M12.5552 13.3333H9.01768V12.7299L10.7702 10.8184C11.0231 10.5366 11.2026 10.3017 11.3086 10.1138C11.417 9.92352 11.4712 9.73321 11.4712 9.54291C11.4712 9.29238 11.4001 9.09003 11.258 8.93586C11.1183 8.78169 10.9292 8.70461 10.6907 8.70461C10.4064 8.70461 10.186 8.79133 10.0294 8.96477C9.87284 9.13821 9.79455 9.37549 9.79455 9.6766H8.9165C8.9165 9.35622 8.98877 9.06835 9.13331 8.81301C9.28025 8.55525 9.48862 8.35531 9.75842 8.21319C10.0306 8.07106 10.3438 8 10.6979 8C11.2086 8 11.6109 8.12888 11.9048 8.38663C12.2011 8.64198 12.3492 8.99609 12.3492 9.44896C12.3492 9.71153 12.2745 9.98735 12.1252 10.2764C11.9782 10.5631 11.7361 10.8895 11.3989 11.2556L10.1125 12.6323H12.5552V13.3333Z" fill="#8797F5"/>
      <path d="M3.5835 14.6663L12.2502 1.33301" stroke="#8797F5" strokeLinecap="round"/>
    </svg>

  );
};

export default HalfSvg;