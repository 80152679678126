import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import img from "../../../assets/images/bannerContest.png";
import imgLight from "../../../assets/images/bannerContestLight.png";
import FilterRow from "../../../components/filterRow/FilterRow";
import contestStore from "../../../store/contest.store";
import { Spin } from "antd";

import "./contestListPage.scss";
import ContestCard from "../components/card/ContestCard";
import { labelStatus } from "../components/label/ContestCardStatusLabel";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ContestsListPage = () => {
  const { themeStore } = useContext(ThemeContext);
  const [filter, setFilter] = useState<labelStatus | "">("");
  const { t } = useTranslation();

  const closePageHandler = () => {
    contestStore.clearContestList();
  };

  const initPageHandler = () => {
    contestStore.getContestList(filter).then();

    return closePageHandler;
  };

  useEffect(initPageHandler, [filter]);

  const onClick = (e: any) => {
    setFilter(e.currentTarget.dataset.value);
  };

  return (
      <div className="contest-page container">
        <div className="d-flex">
          <h1 className="title">{ t('competition') }</h1>
        </div>
        <div className="banner">
          {themeStore.theme === "dark" ? (
              // <img src={img} alt="" />
              <></>
          ) : (
              // <img src={imgLight} alt="" />
              <></>
          )}
          <pre>
          <div>
            <h1 className="title">
              {/* { t('playmakerRewards') } */}
              Title
            </h1>
          </div>
          <div className="description">
            <p>
              { t('playOnPlatform') }
            </p>
          </div>
        </pre>
        </div>
        <FilterRow>
          <div
              onClick={onClick}
              className={`${filter === "" ? "active" : ""}`}
              data-value={""}
          >
            { t('all') }
          </div>
          <div
              onClick={onClick}
              className={`${filter === labelStatus.active ? "active" : ""}`}
              data-value={labelStatus.active}
          >
            { t('statusActive') }
          </div>
          <div
              onClick={onClick}
              className={`${filter === labelStatus.await ? "active" : ""}`}
              data-value={labelStatus.await}
          >
            { t('statusWaiting') }
          </div>
          <div
              onClick={onClick}
              className={`${filter === labelStatus.check ? "active" : ""}`}
              data-value={labelStatus.check}
          >
            { t('statusReviewing') }
          </div>
          <div
              onClick={onClick}
              className={`${filter === labelStatus.closed ? "active" : ""}`}
              data-value={labelStatus.closed}
          >
            { t('statusComplete') }
          </div>
        </FilterRow>
        {contestStore.isLoading ? (
            <Spin spinning={true} />
        ) : (
            <>
              {contestStore.contestsList?.map((contest) => {
                const detail = contest.details!.filter(item=>item.LANGUAGE === i18next.language);
                return (
                    <ContestCard {...{ ...contest, detail }} key={contest._id} />
                );
              })}
            </>
        )}
      </div>
  );
};

export default observer(ContestsListPage);

