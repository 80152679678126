import React from "react";
import { useTranslation } from "react-i18next";
import OutlineButton from "../../../../components/buttons/outlineButton/OutlineButton";
import PlayerInfoCard from "../../../../components/playerInfoCard/PlayerInfoCard";
import history from "../../../../history";
import BlitzSvg from "../../../../svg/BlitzSvg";
import ClassicSvg from "../../../../svg/ClassicSvg";
import RapidSvg from "../../../../svg/RapidSvg";
import MiniTvProps, { GameType } from "../../../../types/props/miniTvProps";

import "./layoutCard.scss";

const LayoutCard = (props: MiniTvProps) => {
  const { t } = useTranslation();
  return (
    <div className="tv-layout-card d-flex align-items-center">
      <div className="game-info d-flex justify-between align-items-center">
        <div className="type d-flex align-items-center">
          <div className="type-icon">
            {props.type === GameType.blitz ? (
              <BlitzSvg />
            ) : props.type === GameType.classic ? (
              <ClassicSvg />
            ) : (
              <RapidSvg />
            )}
          </div>
          <div className="type-text">{t(props.type.toString())}</div>
        </div>
        <div className="time">{t(props.time)}</div>
      </div>
      <div className="users-info d-flex justify-between">
        <PlayerInfoCard
          className={"player-info-card-mini"}
          {...props.userWhite}
        />
        <span className="vs">vs</span>
        <PlayerInfoCard
          className={"player-info-card-mini"}
          {...props.userBlack}
        />
      </div>
      <OutlineButton
        className="button"
        title={t("watch")}
        handleOnClick={() => {
          history.push(`/game/${props.gameId}`);
        }}
      />
    </div>
  );
};

export default LayoutCard;
