import React from "react";
import {Modal, Spin} from 'antd';
import CloseModalSvg from "../../../svg/CloseModalSvg";
import { ModalProps } from "../types";
import InfoTriangleSvg from "../../../svg/InfoTriangleSvg";
import FilledButton from "../../buttons/filledButton/FilledButton";

import "./mailConfirm.scss";
import userStore from "../../../store/user.store";
import openNotification from "../../../utils/openNotification";
import {observer} from 'mobx-react-lite';
import { useTranslation } from "react-i18next";

export interface MailConfirmProps extends ModalProps {
  email: string;
}

const MailConfirm = (props: MailConfirmProps) => {
  const { t } = useTranslation();
  const style = {
    background: "#303249",
    borderRadius: "12px",
    border: 0,
  };

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={<CloseModalSvg />}
      visible={props.visible}
      bodyStyle={style}
      wrapClassName={"modal"}
      className={props.className}
      onOk={props.handleCancel}
      onCancel={props.handleCancel}
      centered={true}
    >
      <Spin spinning={userStore.isLoading}>
        <InfoTriangleSvg />
        <h2>{t('confirmEmail')}</h2>
        <p>
          {t('sentLinkOfConfirmation')}<span className="email">{props.email}</span> 
        </p>
        <FilledButton

          handleOnClick={() =>
            userStore
              .reActivate(props.email)
              .then(() =>
                openNotification(
                  "success",
                  t('success'),
                  `${t('successSend')} ${props.email} `
                )
              )
              .catch(() =>
                openNotification("error", t('error'), t('failToSend'))
              )
          }
          title={t('sendEmailAgain')}
        />
      </Spin>
    </Modal>
  );
};

export default observer(MailConfirm);
