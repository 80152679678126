import { ITableRowForRatingTable } from "../../components/ratingTable/RatingTable";
import { IContestRating } from "../../store/rating.store";

export const toDataContestRating = (
  data: IContestRating[]
): ITableRowForRatingTable[] => {
  return data.map((contest) => {
    return {
      name: contest.user.lastName
        ? contest.user.lastName + " " + contest.user.firstName
        : "unknown",
      country: contest.user.country || 'ru',
      photo: contest.user.photo,
      rank: contest.user.rank || '',
      ratingElo: contest.ratingElo,
      ratingPlaymaker: contest.ratingPlaymaker,
      wins: contest.wins,
      lose: contest.lose,
      draw: contest.draw,
      gamesCount: contest.gamesCount,
      _id: contest.user._id
    };
  });
};
