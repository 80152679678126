import $api from "../../http";
import { AxiosResponse } from "axios";
import { GoResponse } from "../../types/Response/GoResponse";
import { IGame } from "../../types/models/game/IGo";
import { IPublicGame } from "../../types/models/game/IPublicGame";
import { ICreateGame } from "../../views/createGame/components/gameSettings/GameSettings";

export default class GoService {
  static async createGame( data?: ICreateGame ): Promise<AxiosResponse<GoResponse>> {
    console.log("data",data);
    return $api.post<GoResponse>("/go/create", data);
  }
  static async getGame(gameId: string): Promise<AxiosResponse<IGame>> {
    return $api.get<IGame>(`/go/get/${gameId}`);
  }
  static async getGameHistory(id: string) {
    return $api.get(`/go/game/moves/${id}`);
  }

  static async getDeadPiece(id: string) {
    return $api.get(`/go/game/deadpiece/${id}`);
  }

  static async finishGame(
    gameId: string,
    color?: string
  ): Promise<AxiosResponse<IGame>> {
    return $api.get<IGame>(`/go/finish/${gameId}?winner=${color}`);
  }

  static async getOnlineGames(): Promise<AxiosResponse<IPublicGame[]>> {
    return $api.get<IPublicGame[]>("/go/games/online");
  }

  static async closeGame(): Promise<AxiosResponse<IPublicGame[]>> {
    return $api.get<IPublicGame[]>("/go/game/close");
  }
}

