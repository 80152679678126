import React, { ChangeEvent, FC } from "react";
import { Select } from "antd";
import { useTranslation, withTranslation } from "react-i18next";

const { Option } = Select;

interface ISelectUI {
  list: string[];
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  valueList?: string[];
  withTranslation?: boolean;

  onChange?: (value: string) => void;
}

const SelectUi: FC<ISelectUI> = ({
  list,
  defaultValue,
  placeholder,
  value,
  valueList,
  withTranslation,
  onChange
}) => {
  const { t } = useTranslation();
  return (
    <Select
      className="select-ui"
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
    >
      {list.map((el,idx) => {
        return(
        <Option key={el} value={valueList? valueList[idx] : el}>
          {withTranslation? (t(el)) : el}
        </Option>
)})}
    </Select>
  );
};

export default SelectUi;
