import './formCard.scss';
import React from "react";
import { IPropsFormCard } from "./types";

const FormCard = ({ title, children }: IPropsFormCard) => {
  return (
    <div className="form-card">
      <h3 className="title">{title}</h3>
      {children}
    </div>
  );
};

export default FormCard;
