import React from 'react';

const CloseModalSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.3765 16 16 12.3765 16 8C16 3.63137 12.3686 0 7.99216 0C3.62353 0 0 3.63137 0 8C0 12.3765 3.63137 16 8 16Z" fill="#555770"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8077 11.4822C10.6195 11.4822 10.4548 11.4038 10.3372 11.2861L7.99206 8.9332L5.6548 11.2861C5.53716 11.4116 5.37245 11.4822 5.18421 11.4822C4.82343 11.4822 4.52539 11.1842 4.52539 10.8156C4.52539 10.6352 4.59598 10.4783 4.72147 10.3607L7.06657 8.00771L4.72147 5.65477C4.59598 5.54497 4.52539 5.38026 4.52539 5.19987C4.52539 4.83909 4.82343 4.54889 5.18421 4.54889C5.37245 4.54889 5.52147 4.61948 5.63912 4.74497L7.99206 7.09007L10.3607 4.73712C10.494 4.59595 10.643 4.5332 10.8156 4.5332C11.1842 4.5332 11.4744 4.83124 11.4744 5.19203C11.4744 5.37242 11.4195 5.52144 11.2783 5.64693L8.92539 8.00771L11.2705 10.345C11.396 10.4705 11.4666 10.6273 11.4666 10.8156C11.4666 11.1842 11.1764 11.4822 10.8077 11.4822Z" fill="white"/>
    </svg>
  );
};

export default CloseModalSvg;