import React from 'react';

const ClockSvg = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.875 9.5C23.875 8.46447 23.0355 7.625 22 7.625C20.9645 7.625 20.125 8.46447 20.125 9.5V22C20.125 22.6465 20.458 23.2474 21.0063 23.59L28.5063 28.2775C29.3844 28.8263 30.5412 28.5594 31.09 27.6812C31.6388 26.8031 31.3719 25.6463 30.4937 25.0975L23.875 20.9608V9.5Z" fill="#8F90A6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22 0.125C9.91877 0.125 0.125 9.91877 0.125 22C0.125 34.0812 9.91877 43.875 22 43.875C34.0812 43.875 43.875 34.0812 43.875 22C43.875 9.91877 34.0812 0.125 22 0.125ZM3.875 22C3.875 11.9898 11.9898 3.875 22 3.875C32.0102 3.875 40.125 11.9898 40.125 22C40.125 32.0102 32.0102 40.125 22 40.125C11.9898 40.125 3.875 32.0102 3.875 22Z" fill="#8F90A6"/>
    </svg>
  );
};

export default ClockSvg;