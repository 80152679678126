import React from 'react';
import './outlineButton.scss';
import IButtonProps from '../../../types/props/buttonProps';

const OutlineButton = (props: IButtonProps) => {
  let classes = 'outline-button';
  if (props.className) {
    classes += ` ${props.className}`;
  }

  return (
    <button type={props.type || 'submit'}
            onClick={props.handleOnClick}
            style={props.style}
            className={classes}
            id={props.id}>
      {typeof props.title === 'string' ?
        props.title :
        <props.title />
      }
      <div>
        {props.children}
      </div>
    </button>
  );
};

export default OutlineButton;