import {makeAutoObservable} from 'mobx';
import {Socket} from 'socket.io-client';
import SocketService from '../services/Socket.service';

class SocketStore {
  currentSocket: Socket|null = null;
  gameSocket: Socket|null = null;
  gameId: string = '';
  socketLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  roomListener(gameId: string) {
    SocketService.roomListener(gameId);
  }

  setLoading(bool: boolean) {
    this.socketLoading = bool;
  }
}

export default new SocketStore();