import "./winnerTable.scss"
import { Modal, Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import emitter from "../../emitter/Emitter";
import themeStore from '../../store/theme.store';
import CloseModalSvg from '../../svg/CloseModalSvg';
import EditSvg from '../../svg/EditSvg';
import FilledButton from '../buttons/filledButton/FilledButton';
import PlayerInfoCard from '../playerInfoCard/PlayerInfoCard';
import { observer } from "mobx-react-lite";
import winnerStore from "../../store/winner.store";


export interface ITableRowForWinnerTable {
    _id: string;
    name: string;
    country: string;
    photo: string | undefined;
    rank: string | undefined;

    amount: number;
    hasPassport: boolean;
    hasSentMoney: boolean;
}

interface IProps {
    data: ITableRowForWinnerTable[];
    update: Function;
}


const MobileWinnerTable = ({data} : IProps) => {
    const { t } = useTranslation();
    return(
        <div className={`mobile-winner-table ${themeStore.theme}`}>
            {data.map((user) => (
                <div key={user._id} className="card" onClick={()=>{emitter.emit("openEditWinner",user)}}>
                    <PlayerInfoCard
                        className="player-info-card"
                        photo={user.photo}
                        country={user.country}
                        rank={user.rank}
                        name={user.name}
                    />
                    <div className="amount d-flex space-between margin-top-30px">
                        <p>{t('sumOfWin')}</p>
                        <div>{user.amount}</div>
                    </div>
                    <div className="passport d-flex space-between margin-top-30px">
                        <p>{t('passportData')}</p>
                        <div>{t(user.hasPassport ? "yes" : "no")}</div>
                    </div>
                    <div className="sent-money d-flex space-between margin-top-30px">
                        <p>{t('sendMoney')}</p>
                        <div>{t(user.hasSentMoney ? "yes" : "no")}</div>
                    </div>
                </div>
            
            ))}
        </div>
    )
}

const WinnerTable = ({data, update} : IProps) => {
    const { t } = useTranslation();
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [isSentMoney, setIsSentMoney] = useState(true);
    const [innerWidth, setInnerWidth] = useState<number>();
    const [currentId, setCurrentId] = useState<string>('');

    const style = {
        background: themeStore.theme === 'dark' ? '#303249' : '#FFFFFF',
        borderRadius: '12px',
        border: 0,
      };

    useEffect(() => {
        emitter.on("openEditWinner", openEditWinner);
    })
    useEffect(() => {
       setInnerWidth(window.innerWidth);
    },[innerWidth]);

    const openEditWinner = (data :ITableRowForWinnerTable)=>{
        setCurrentId(data._id);
        setIsSentMoney(data.hasSentMoney);
        setVisibleEdit(true);
    }
    const closeEditWinner = () => {
        setVisibleEdit(false);
    }
    const btnHandler = (bool : boolean)=>{ 
        return ()=> {
            winnerStore.setPayed({_id: currentId, payed: bool});
            update();
            closeEditWinner();
        }
    }

    const columnsData : TableColumnsType<ITableRowForWinnerTable> = [
        {
            title: t('players'),
            dataIndex: "name",
            render: (value, record) => (
                <PlayerInfoCard
                    photo={record.photo}
                    country={record.country}
                    name={record.name}
                    rank={record.rank}
              />
            )
        },
        {
            title: t('sumOfWin'),
            dataIndex: "amount",
            render: (value) => (
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "20px",
                    verticalAlign: "top",
                    marginLeft: "4px",
                    color: "#8797F5"
                  }}
                >
                    {value}
                </span>
            )
        },
        {
            title: t('passportData'),
            dataIndex: "hasPassport",
            render: (value) => {
                let color;
                color = value === true? "#2FB590" : "#FFA339";
                return (
                    <span
                        style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        verticalAlign: "top",
                        marginLeft: "4px",
                        color: color,
                        }}
                    >
                        {t(value ? "yes" : "no")}
                    </span>
                )
            }
        },
        {
            title: t('sendMoney'),
            dataIndex: "hasSentMoney",
            render: (value) => {
                let color;
                color = value === true? "#2FB590" : "#FFA339";
                return(
                    <span
                        style={{
                        fontWeight: 500,
                        lineHeight: "20px",
                        fontSize: "16px",
                        verticalAlign: "top",
                        marginLeft: "4px",
                        color: color,
                        }}
                    >
                        {t(value ? "yes" : "no")}
                    </span>
                )
            }
        },
        {
            title: "",
            dataIndex: "_id",
            render: (value,record) => (
              <div
                className="d-flex align-items-center justify-center"
                style={{
                  border: 0,
                  borderRadius: "50%",
                  background: "#555770",
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                  fontSize: "9px",
                  backgroundColor: "inherit"
                }}
                onClick={()=>{emitter.emit("openEditWinner", record)}}
              >
                <EditSvg height={20} width={20}/>
              </div>
            )
        }
    ]
  return (
    <div className={`winner-table ${themeStore.theme}`}>
    {data && innerWidth ? (
        innerWidth > 840 ? (
            <Table pagination={false} columns={columnsData} dataSource={data} />
        ) : (<MobileWinnerTable data={data} update={update} />)
    ):(<></>)}
      <Modal
        title={null}
        footer={null}
        closeIcon={<CloseModalSvg />}
        visible={visibleEdit}
        bodyStyle={style}
        wrapClassName={'modal'}
        centered={true}
        onCancel={closeEditWinner}
      >
        <div className={`winner-table-popup ${themeStore.theme}`} >
            <h1>{t('edit')}</h1>
            <h2>{t('sendMoney')}</h2>
            <div className={`d-flex buttons`}>
                <FilledButton 
                    style={{ marginRight: "1.5rem" , width:"85px", height:"32px" }} 
                    title={t('yes')}
                    className={`btn__${isSentMoney ? "selected" : ""}`}
                    handleOnClick={btnHandler(true)}
                    />
                <FilledButton 
                    style={{ marginRight: "1.5rem" , width:"85px", height:"32px"}} 
                    title={t('no')}
                    className={`btn__${isSentMoney ? "" : "selected"}`}
                    handleOnClick={btnHandler(false)}
                    />       
            </div>
            <h2>
                {t('missingPassport')}
            </h2>
            <FilledButton
                style={{ marginRight: "1.5rem" }} 
                title={t('contactAboutPassport')}
            />
        </div>
    </Modal>
    </div>
  )
}
export default observer(WinnerTable)