import React, { FC } from "react";
import RapidSvg from "../../../../svg/RapidSvg";

interface IGameTitle {
  ratingType: string;
  gameType: string;
}

const GameTitle: FC<IGameTitle> = ({ gameType, ratingType }) => {
  return (
    <div className="game-title">
      <div className="game-type">
        <h3>{gameType}</h3>
        <h4>{ratingType}</h4>
      </div>
    </div>
  );
};

export default GameTitle;
