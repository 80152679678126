import "./createContest.scss";

import { DatePicker, Form, Input, Modal, Spin } from "antd";
import history from "../../../history";
import React, { useEffect, useState } from "react";

import FilledButton from "../../../components/buttons/filledButton/FilledButton";
import contestStore from "../../../store/contest.store";
import openNotification from "../../../utils/openNotification";
import { IContest, IContestDetails } from "../../../models/IContest";
import { useTranslation } from "react-i18next";
import languageStore from "../../../store/language.store";
import i18next from "i18next";
import SelectUI from "../../../components/ui/SelectUI";
import { observer } from "mobx-react-lite";
import CloseModalSvg from "../../../svg/CloseModalSvg";
import themeStore from "../../../store/theme.store";
import { useForm } from "antd/es/form/Form";

const CreateContestsPage = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isVisible, setVisible] = useState<boolean>(false);
  const [savedLangs, setSavedLangs] = useState<string[]>([]);
  const [selectedLang, setSelectedLang] = useState<string>(i18next.language);
  const [details, setDetails] = useState<IContestDetails[]>([]);

  useEffect(() => {
    languageStore.getAccessLang().then();
  }, []);

  const config = {
    rules: [
      {
        type: "object" as const,
        required: true,
        message: t("selectTime"),
      },
    ],
  };

  const saveDetails = (res: any) => {
    console.log(res);
    if (!savedLangs.includes(selectedLang)) {
      setSavedLangs((prev) => {
        prev?.push(selectedLang);
        return prev;
      });
      setDetails((prev) => {
        prev?.push({
          LANGUAGE: selectedLang,
          name: res.name,
          description: res.description,
          regulation: res.regulation,
        });
        return prev;
      });
    }
  };

  const handlerOnFinish = (res: any) => {
    if (details.length === i18next.languages.length) {
      const data: IContest = {
        prize: res.prize,
        distribution: res.distribution || "percent",
        dateStart: res.dateStart.utc(true).startOf("day").toDate(),
        dateEnd: res.dateEnd.utc(true).endOf("day").toDate(),
        placesDistribution: [res.firstPlace, res.secondPlace, res.thirdPlace],
        details,
      };

      contestStore
        .createContest(data)
        .then((res) => {
          openNotification(
            "success",
            t("success"),
            ` ${t("competition")} ${t("successCreate")}`
          );
        })
        .catch((err) => {
          openNotification(
            "error",
            t("error"),
            `${t("errorCreateCompetition")}`
          );
        });
      setDetails([]);
      history.push("/admin/contests");
    } else {
      setVisible(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const style = {
    background: themeStore.theme === "dark" ? "#303249" : "#FFFFFF",
    borderRadius: "12px",
    border: 0,
  };

  return (
    <div className="create-contests container">
      <div onClick={() => history.push("/admin/contests")}>
        <span className="back">{t("back")}</span>
      </div>
      <div>
        <div className="title">{t("newCompetition")}</div>
      </div>
      <Spin spinning={contestStore.isLoading}>
        <div className="form">
          {/*<Form name="contest" autoComplete="off" onFinish={handlerOnFinish}>*/}
          {/*  <div className="d-flex align-items-center justify-between mb-1">*/}
          {/*    <h3 style={{ margin: 0 }}>{t("name")}</h3>*/}
          {/*    <SelectUI*/}
          {/*      list={languageStore.languages}*/}
          {/*      defaultValue={i18next.language}*/}
          {/*      placeholder={t("select_lang")}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <Form.Item*/}
          {/*    className="mb-24"*/}
          {/*    name="name"*/}
          {/*    rules={[{ required: true, message: t("nameCompetition") }]}*/}
          {/*  >*/}
          {/*    <Input placeholder={t("nameCompetition")} />*/}
          {/*  </Form.Item>*/}
          {/*  <h3>{t("sponsor")}</h3>*/}
          {/*  <div className="d-flex justify-between temp">*/}
          {/*    <Form.Item*/}
          {/*      name="prize"*/}
          {/*      rules={[{ required: true, message: t("amount") }]}*/}
          {/*    >*/}
          {/*      <Input placeholder={t("amount")} />*/}
          {/*    </Form.Item>*/}
          {/*    <Form.Item name="distribution">*/}
          {/*      <Input*/}
          {/*        disabled={true}*/}
          {/*        value={"percent"}*/}
          {/*        defaultValue="Распределение приза в процентах"*/}
          {/*        placeholder="Распределение приза"*/}
          {/*      />*/}
          {/*    </Form.Item>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex align-items-center place">*/}
          {/*    <div className="place__value">{t("firstPlace")}</div>*/}
          {/*    <Form.Item name="firstPlace">*/}
          {/*      <Input placeholder={t("distribution")} />*/}
          {/*    </Form.Item>*/}
          {/*    <div className="place__span">*/}
          {/*      <span>руб / %</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex align-items-center place">*/}
          {/*    <div className="place__value">{t("secondPlace")}</div>*/}
          {/*    <Form.Item name="secondPlace">*/}
          {/*      <Input placeholder={t("distribution")} />*/}
          {/*    </Form.Item>*/}
          {/*    <div className="place__span">*/}
          {/*      <span>руб / %</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex align-items-center place underline">*/}
          {/*    <div className="place__value">{t("thirdPlace")}</div>*/}
          {/*    <Form.Item name="thirdPlace">*/}
          {/*      <Input placeholder={t("distribution")} />*/}
          {/*    </Form.Item>*/}
          {/*    <div className="place__span">*/}
          {/*      <span>руб / %</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <h3>{t("validDate")}</h3>*/}
          {/*  <div className="d-flex temp justify-between align-items-center">*/}
          {/*    <Form.Item name="dateStart" {...config}>*/}
          {/*      <DatePicker*/}
          {/*        format={"DD.MM.YYYY"}*/}
          {/*        showToday={false}*/}
          {/*        bordered={false}*/}
          {/*        suffixIcon={null}*/}
          {/*        placeholder={t("dateOfStarting")}*/}
          {/*      />*/}
          {/*    </Form.Item>*/}
          {/*    <Form.Item name="dateEnd" {...config}>*/}
          {/*      <DatePicker*/}
          {/*        format={"DD.MM.YYYY"}*/}
          {/*        showToday={false}*/}
          {/*        bordered={false}*/}
          {/*        suffixIcon={null}*/}
          {/*        placeholder={t("dateOfFinishing")}*/}
          {/*      />*/}
          {/*    </Form.Item>*/}
          {/*  </div>*/}
          {/*  <h3>{t("description")}</h3>*/}
          {/*  <Form.Item name={"description"}>*/}
          {/*    <Input.TextArea*/}
          {/*      autoSize={{ minRows: 3, maxRows: 5 }}*/}
          {/*      placeholder={t("description") + "..."}*/}
          {/*    />*/}
          {/*  </Form.Item>*/}
          {/*  <h3 style={{ marginTop: "1rem" }}>{t("regulation")}</h3>*/}
          {/*  <Form.Item name={"regulation"}>*/}
          {/*    <Input.TextArea*/}
          {/*      autoSize={{ minRows: 3, maxRows: 5 }}*/}
          {/*      placeholder={t("regulation") + "..."}*/}
          {/*    />*/}
          {/*  </Form.Item>*/}
          {/*  <FilledButton*/}
          {/*    style={{*/}
          {/*      padding: "14px 24px",*/}
          {/*      marginTop: "24px",*/}
          {/*      display: "block",*/}
          {/*      marginLeft: "auto",*/}
          {/*    }}*/}
          {/*    title={t("createCompetition")}*/}
          {/*    type="submit"*/}
          {/*  />*/}
          {/*</Form>*/}
          <Form
            name="contest"
            autoComplete="off"
            onFinish={handlerOnFinish}
            form={form}
          >
            <div className="d-flex align-items-center justify-between mb-1">
              <h3 style={{ margin: 0 }}>{t("name")}</h3>
              <SelectUI
                list={languageStore.languages}
                defaultValue={i18next.language}
                placeholder={t("select_lang")}
                value={selectedLang}
                onChange={async (e) => {
                  form.validateFields().then(() => {
                    saveDetails(form.getFieldsValue(["name", "description", "regulation"]));
                    setSelectedLang(e);
                    form.resetFields(["name", "description", "regulation"]);
                  });
                }}
              />
            </div>
            <Form.Item
              className="mb-24"
              name="name"
              rules={[{ required: true, message: t("nameCompetition") }]}
            >
              <Input placeholder={t("nameCompetition")} />
            </Form.Item>
            <h3>{t("sponsor")}</h3>
            <div className="d-flex justify-between temp">
              <Form.Item
                name="prize"
                rules={[{ required: true, message: t("amount") }]}
              >
                <Input placeholder={t("amount")} />
              </Form.Item>
              <Form.Item name="distribution">
                <SelectUI
                  list={[t("prizeDist"), t("prizeMoney")]}
                  value={"percent"}
                  defaultValue={t("prizeDist")}
                  placeholder={t("distribution")}
                />
              </Form.Item>
            </div>
            <div className="d-flex align-items-center place">
              <div className="place__value">{t("firstPlace")}</div>
              <Form.Item name="firstPlace">
                <Input placeholder={t("distribution")} />
              </Form.Item>
              <div className="place__span">
                <span>руб / %</span>
              </div>
            </div>
            <div className="d-flex align-items-center place">
              <div className="place__value">{t("secondPlace")}</div>
              <Form.Item name="secondPlace">
                <Input placeholder={t("distribution")} />
              </Form.Item>
              <div className="place__span">
                <span>руб / %</span>
              </div>
            </div>
            <div className="d-flex align-items-center place underline">
              <div className="place__value">{t("thirdPlace")}</div>
              <Form.Item name="thirdPlace">
                <Input placeholder={t("distribution")} />
              </Form.Item>
              <div className="place__span">
                <span>руб / %</span>
              </div>
            </div>
            <h3>{t("validDate")}</h3>
            <div className="d-flex temp justify-between align-items-center">
              <Form.Item name="dateStart" {...config}>
                <DatePicker
                  format={"DD.MM.YYYY"}
                  showToday={false}
                  bordered={false}
                  suffixIcon={null}
                  placeholder={t("dateOfStarting")}
                />
              </Form.Item>
              <Form.Item name="dateEnd" {...config}>
                <DatePicker
                  format={"DD.MM.YYYY"}
                  showToday={false}
                  bordered={false}
                  suffixIcon={null}
                  placeholder={t("dateOfFinishing")}
                />
              </Form.Item>
            </div>
            <h3>{t("description")}</h3>
            <Form.Item
              name={"description"}
              rules={[{ required: true, message: t("nameCompetition") }]}
            >
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={t("description_blank")}
              />
            </Form.Item>
            <h3 style={{ marginTop: "1rem" }}>{t("regulation")}</h3>
            <Form.Item name={"regulation"}>
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={t("regulation") + "..."}
              />
            </Form.Item>
            <FilledButton
              style={{
                padding: "14px 24px",
                marginTop: "24px",
                display: "block",
                marginLeft: "auto",
              }}
              title={t("createCompetition")}
              type="submit"
              handleOnClick={() =>
                form.validateFields().then(() => {
                  saveDetails(form.getFieldsValue(["name", "description", "regulation"]));
                })
              }
            />
          </Form>
        </div>
      </Spin>
      <Modal
        title={null}
        footer={null}
        closeIcon={<CloseModalSvg />}
        visible={isVisible}
        bodyStyle={style}
        wrapClassName={"modal"}
        onOk={handleCancel}
        onCancel={handleCancel}
        centered={true}
      >
        <h2>{t("completeAllLangs")}</h2>
      </Modal>
    </div>
  );
};

export default observer(CreateContestsPage);
