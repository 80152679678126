import React, {FC, useEffect, useState} from 'react';
import './statistic.scss';
import UserSvg from '../../svg/UserSvg';
import FireSvg from '../../svg/FireSvg';
import HandshakeSvg from '../../svg/HandshakeSvg';
import Emitter from '../../emitter/Emitter';
import SocketService from '../../services/Socket.service';
import userStore from '../../store/user.store';
import {observer} from 'mobx-react-lite';
import gamesStore from '../../store/games.store';
import { useTranslation } from 'react-i18next';

type GroupProps = {
  title: string;
  svg: FC;
  value: string;
}

const Group = observer((props: GroupProps) => {
  return (
    <div className='group d-flex align-items-center'>
      <div className='svg-wrapper d-flex justify-center align-items-center'>
        <props.svg />
      </div>
      <div>
        <div className={'group-title'}>{props.title}</div>
        <div className={'stat'}>{props.value}</div>
      </div>
    </div>
  );
});

const Statistic = () => {
  const { t } = useTranslation();
  const [playersCount, setPlayersCount] = useState<number>(0);
  const [onlineGamesCount, setOnlineGamesCount] = useState<number>(0);
  
  const useEffectSetPlayersCountCallback = () => {

    userStore.getUsersCount();

    const timeoutCallback = () => {
      SocketService.getClientCount();
    };

    const clientCount = (clientCount: number) => {
      setPlayersCount(clientCount);
    };

    const useEffectDestroyCallback = () => {
      clearInterval(timerId);
      setPlayersCount(0);

      Emitter.removeListener('clientCount', clientCount);
    };

    const timerId = setInterval(timeoutCallback, 1000);

    Emitter.on('clientCount', clientCount);

    return useEffectDestroyCallback;
  };

  useEffect(useEffectSetPlayersCountCallback, []);

  return (
    <div className={'statistic main-card-wrapper'}>
      <h4 className={'green-title'}>{t('statistics')}</h4>
      <div className='statistic-wrapper'>
        {/* @ts-ignore*/}
        <Group title={ t('players') } svg={UserSvg} value={userStore.usersCount} />
        <Group title={ t('active') } svg={FireSvg} value={String(playersCount)} />
        <Group title={ t('rounds') } svg={HandshakeSvg} value={String(gamesStore.games.length)} />
      </div>
    </div>
  );
};

export default observer(Statistic);