import {makeAutoObservable} from 'mobx';

export type TTheme = 'light'|'dark';

interface IThemeStore {
  theme: TTheme;
}

class ThemeStore implements IThemeStore {
  get theme(): TTheme {
    return this._theme;
  }

  set theme(value: TTheme) {
    localStorage.setItem('theme', value);
    this._theme = value;
  }

  private _theme: TTheme = 'dark';

  constructor() {
    makeAutoObservable(this);
  }

}

export default new ThemeStore();