import { makeAutoObservable } from "mobx";
import CountryCitiesService from "../services/CountryCities.service";

interface ICountryCitiesStore {
  isLoading: boolean;
  countriesList: TCountry[];
  citiesList: TCity[];

  getCountryCityList: () => void;
}

type TCountry =  {name: string, _id: string};
type TCity =  {name: string, id: string};

class CountryCitiesStore implements ICountryCitiesStore {
  private _countryCities: any;
  get countryCities(): any {
    return this._countryCities;
  }

  private _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  private _countriesList: TCountry[] = [];
  get countriesList(): TCountry[] {
    return this._countriesList;
  }

  set countriesList(value: TCountry[]) {
    this._countriesList = value;
  }

  private _citiesList: TCity[] = [];
  get citiesList(): TCity[] {
    return this._citiesList;
  }

  set citiesList(value: TCity[]) {
    this._citiesList = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async getCountryCityList() {
    this._isLoading = true;
    try {
      this._countriesList = (await CountryCitiesService.getCountries()).data as TCountry[];
    } catch (e) {
    } finally {
      this._isLoading = false;
    }
  }

  async getCities(id: number) {
    this._isLoading = true;
    try {
      this._citiesList = (await CountryCitiesService.getCities(id)).data as TCity[];
    } catch (e) {
    } finally {
      this._isLoading = false;
    }
  }
}

export default new CountryCitiesStore();
