import './dictionarySearch.scss';
import React, {FC} from 'react';
import {Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';

interface IDictionarySearch {
  onChange: Function;
}

const DictionarySearch:FC<IDictionarySearch> = ({onChange}) => {
  const { t } = useTranslation();

  return (
    <div className="search">
      <Input
        placeholder={t("search")}
        onChange={(e) => onChange(e.currentTarget.value)}
        allowClear
      />
    </div>
  );
};

export default observer(DictionarySearch);