import GoBoard from "../components/go/components/goBoard/GoBoard";
import { COLOR } from "../types/models/game/IGo";
const {_} = require('lodash');

 interface IPosition{
    i : number;
    j : number;
}

// import _ from "lodash";

 

enum Result {
    OK = 0,
    ILLEGAL = 1,
};
class Estimator{
    static visited = Array.from(new Array(19), () => new Array(19).fill(0))
    static last_visited_counter = 1;
    height;
    width;
    goBoard: GoBoard;
    board;
    do_ko_check;
    possible_ko: IPosition;

    constructor(goBoard: GoBoard){
        this.goBoard = goBoard
        this.height = goBoard.size;
        this.width = goBoard.size;
        this.board = this.copy_board(goBoard.board,goBoard.size,goBoard.size);
        this.do_ko_check = false;
        this.possible_ko = {i: -1, j: -1};
    }

    copy_board(board: Array<COLOR[]>,width:number, height:number) : COLOR[][]{
        const other = new Array();
        for(let i = 0; i < height; ++i){
            other[i] = [];
            for(let j = 0; j < width; ++j){
                other[i][j] = board[i][j]
            }
        }
        return other;
    }

    pop_n_remove(arr:Array<IPosition> ,index: number): IPosition{
        const ret = arr[index];
        arr[index] = arr[arr.length-1];
        arr.pop();
        return ret
    }

    estimate(player_to_move: COLOR, trials: number, tolerance: number) {
        const ret = this.copy_board(this.board, 19,19);
        const track = Array.from(Array(25),()=>new Array(25).fill(0));
        this.do_ko_check = false;
        this.possible_ko = {i: -1, j: -1};
    
        for (let i=0; i < trials; ++i) {
            /* Play out a random game */
            const t = this.copy_board(this.board, 19,19);
            this.play_out_position(t,player_to_move);

            /* fill in territory */

            for (let i=0; i < this.height; ++i) {
                for (let j=0; j < this.width; ++j) {
                    const p = {i: i, j: j};

                    if (t[p.i][p.j] == COLOR.empty) {
                        if (this.is_territory(t, p, COLOR.black)) {
                            this.fill_territory(t, p, COLOR.black);
                        }
                        if (this.is_territory(t, p, COLOR.white)) {
                            this.fill_territory(t, p, COLOR.white);
                        }
                    }
                }
            }


            /* track how many times each spot was white or black */
            for (let i=0; i < this.height; ++i) {
                for (let j=0; j < this.width; ++j) {
                    track[i][j] += t[i][j] === COLOR.black ? 1 : COLOR.white ? -1 : 0;
                }
            }

        }

    
        /* For each stone group, find the maximal track counter and set
         * all stones in that group to that level */
        const visited = Array.from(Array(25),()=>new Array(25).fill(false));
        for (let i=0; i < this.height; ++i) {
            for (let j=0; j < this.width; ++j) {
                const p = {i:i, j:j};
                if (!visited[p.i][p.j]) {
                    this.synchronize_tracking_counters(track, visited, p);
                }
            }
        }
    
        /* Create a result board based off of how many times each spot
         * was which color. */
        for (let i=0; i < this.height; ++i) {
            for (let j=0; j < this.width; ++j) {
                /* If we're pretty confident we know who the spot belongs to, mark it */
                if (track[i][j] > trials*tolerance) {
                    ret[i][j] = COLOR.black;
                } else if (track[i][j] < trials*-tolerance) {
                    ret[i][j] = COLOR.white;
                /* if that fails, it's probably just dame */
                } else {
                    ret[i][j] = COLOR.empty;
                }
            }
        }

    
        /* TODO: Foreach hole, if it can only reach one color, color it that */
        for (let i=0; i < this.height; ++i) {
            for (let j=0; j < this.width; ++j) {
                const p = {i:i, j:j}
                if (ret[p.i][p.j] == COLOR.empty) {
                    if (this.is_territory(ret, p, COLOR.black)) {
                        this.fill_territory(ret, p, COLOR.black);
                    }
                    if (this.is_territory(ret, p, COLOR.white)) {
                        this.fill_territory(ret, p, COLOR.white);
                    }
                }
            }
        }

        return ret;
    }
    play_out_position(board: Array<COLOR[]>, player_to_move: COLOR) {
        const possible_moves = new Array();
        const illegal_moves = new Array();
    
        for (let i=0; i < this.height; ++i) {
            for (let j=0; j < this.width; ++j) {
                if (board[i][j] == COLOR.empty) {
                    possible_moves.push({i:i,j:j});
                }
            }
        }
        
        let sanity = 1000;
        while (possible_moves.length > 0 && --sanity > 0) {
            const move_idx = Math.floor(Math.random() * possible_moves.length);
            const mv = possible_moves[move_idx];
    
            if (this.is_eye(board, mv, player_to_move)) {
                illegal_moves.push(this.pop_n_remove(possible_moves,move_idx));
                continue;
            }

            let result = this.place_and_remove(board, mv, player_to_move, possible_moves);
            if (result == Result.OK) {
                this.pop_n_remove(possible_moves, move_idx);
                player_to_move = player_to_move == COLOR.black ? COLOR.white : COLOR.black;
                for (let i=0; i < illegal_moves.length; ++i) {
                    possible_moves.push(illegal_moves[i]);
                }
                illegal_moves.splice(0,illegal_moves.length);
                // illegal_moves.length = 0;
                continue;
            }
            else if (result == Result.ILLEGAL) {
                illegal_moves.push(this.pop_n_remove(possible_moves, move_idx));
                continue;
            }
        }
    }
    is_eye(board: Array<COLOR[]>, pt:IPosition, player: COLOR) {
        if ((pt.j == 0        || board[pt.i][pt.j-1] == player) &&
            (pt.j == this.width-1  || board[pt.i][pt.j+1] == player) &&
            (pt.i == 0        || board[pt.i-1][pt.j] == player) &&
            (pt.i == this.height-1 || board[pt.i+1][pt.j] == player))
        {
            return true;
        }
        return false;
    }

    place_and_remove(board: Array<COLOR[]>, move: IPosition, player: COLOR, possible_moves: Array<IPosition>): Result {
        if (this.do_ko_check) {
            if (move == this.possible_ko) {
                return Result.ILLEGAL;
            }
        }
    
        let reset_ko_check = true;
        let removed        = false;
        const neighbors = new Array<IPosition>();
    
        this.get_neighbors(move, neighbors);
        board[move.i][move.j] = player;
        ++Estimator.last_visited_counter;
        for (let i=0; i < neighbors.length; ++i) {
            const neighbor = neighbors[i];
            const opponent_color = player === COLOR.black ? COLOR.white : COLOR.black;
            if (board[neighbor.i][neighbor.j] == opponent_color) {
                if (
                    /* it's common that a previous has_liberties covers what we're
                     * about to test, so don't double test */
                    Estimator.visited[neighbor.i][neighbor.j] != Estimator.last_visited_counter
                    && !this.has_liberties(board,neighbor)
                ) {
                    if (this.remove_group(board,neighbors[i], possible_moves) == 1) {
                        reset_ko_check = false;
                        this.do_ko_check = true;
                        this.possible_ko = neighbor;
                    }
                    removed = true;
                }
            }
        }
        if (!removed) {
            if (!this.has_liberties(board,move)) {
                board[move.i][move.j] = COLOR.empty;
                return Result.ILLEGAL;
            }
        }
    
        if (reset_ko_check) {
            this.do_ko_check = false;
        }
        
        return Result.OK;
    }

    get_neighbors(pt: IPosition, output: Array<IPosition>) {

        if (pt.j > 0)               output.push({i: pt.i, j: pt.j-1});
        if (pt.j+1 < this.width)    output.push({i: pt.i, j: pt.j+1});
        if (pt.i > 0)               output.push({i: pt.i-1, j: pt.j});
        if (pt.i+1 < this.height)   output.push({i: pt.i+1, j: pt.j});
    }

    has_liberties(board: Array<COLOR[]>,pt: IPosition) {
        const tocheck = new Array<IPosition>();
        let w_1 = this.width-1;
        let h_1 = this.height-1;
        let my_color = board[pt.i][pt.j];
        let my_visited_counter = ++Estimator.last_visited_counter;
    
        tocheck.push(pt);
        Estimator.visited[pt.i][pt.j] = my_visited_counter;
            
        while (tocheck.length) {
            const p = this.pop_n_remove(tocheck, tocheck.length-1);
            if (p.i > 0) {
                const neighbor = {i: p.i-1,j: p.j};
                const c = board[neighbor.i][neighbor.j];
                if (c == COLOR.empty) {
                    return true;
                }
                if (c == my_color && Estimator.visited[neighbor.i][neighbor.j] != my_visited_counter) {
                    Estimator.visited[neighbor.i][neighbor.j] = my_visited_counter;
                    tocheck.push(neighbor);
                }
            }
            if (p.i < h_1) {
                const neighbor = {i: p.i+1,j: p.j};
                const c = board[neighbor.i][neighbor.j];
                if (c == COLOR.empty) {
                    return true;
                }
                if (c == my_color && Estimator.visited[neighbor.i][neighbor.j] != my_visited_counter) {
                    Estimator.visited[neighbor.i][neighbor.j] = my_visited_counter;
                    tocheck.push(neighbor);
                }
            }

            if (p.j > 0) {
                const neighbor = {i: p.i,j: p.j-1};
                const c = board[neighbor.i][neighbor.j];
                if (c == COLOR.empty) {
                    return true;
                }
                if (c == my_color && Estimator.visited[neighbor.i][neighbor.j] != my_visited_counter) {
                    Estimator.visited[neighbor.i][neighbor.j] = my_visited_counter;
                    tocheck.push(neighbor);
                }
            }
            if (p.j < w_1) {
                const neighbor = {i: p.i,j: p.j+1};
                const c = board[neighbor.i][neighbor.j];
                if (c == COLOR.empty) {
                    return true;
                }
                if (c == my_color && Estimator.visited[neighbor.i][neighbor.j] != my_visited_counter) {
                    Estimator.visited[neighbor.i][neighbor.j] = my_visited_counter;
                    tocheck.push(neighbor);
                }
            }
            
        }
        return false;
    }

    remove_group(board: Array<COLOR[]>, move: IPosition, possible_moves: Array<IPosition>) {
        const visited = Array.from(Array(25),()=>new Array(25).fill(false));
        const tocheck = new Array<IPosition>();
        const neighbors = new Array<IPosition>();
        let n_removed = 0;
        let my_color  = board[move.i][move.j];

        tocheck.push(move);
        visited[move.i][move.j] = true;

    
        while (tocheck.length) {
            const p = this.pop_n_remove(tocheck, 0);

            board[p.i][p.j] = COLOR.empty;
            possible_moves.push(p);
            n_removed++;

            this.get_neighbors(p, neighbors);

            for (let i=0; i < neighbors.length; ++i) {
                const neighbor = neighbors[i];
                if (visited[neighbor.i][neighbor.j]) continue;
                visited[neighbor.i][neighbor.j] = true;
                const c = board[neighbor.i][neighbor.j];
                if (c == my_color) {
                    tocheck.push(neighbor);
                }
            }
        }

        return n_removed;;
    }

    is_territory(board: Array<COLOR[]>, pt: IPosition, player: COLOR) {
        const tocheck = new Array<IPosition>();
        const neighbors = new Array<IPosition>();
        let visited_counter = ++Estimator.last_visited_counter;

        tocheck.push(pt);
        Estimator.visited[pt.i][pt.j] = visited_counter;
    
        while (tocheck.length) {
            const p = this.pop_n_remove(tocheck, 0);
            if (board[p.i][p.j] == COLOR.empty) {
                this.get_neighbors(p, neighbors);
                for (let i=0; i < neighbors.length; ++i) {
                    const neighbor = neighbors[i];
                    if (Estimator.visited[neighbor.i][neighbor.j] == visited_counter) continue;
                    Estimator.visited[neighbor.i][neighbor.j] = visited_counter;
                    tocheck.push(neighbor);
                }
            } else {
                if (board[p.i][p.j] != player) {
                    return false;
                }
            }
        }
    
        return true;
    }

    fill_territory(board: Array<COLOR[]>, pt: IPosition, player: COLOR) {
        const tocheck = new Array();
        const neighbors = new Array();
        let visited_counter = ++Estimator.last_visited_counter;
    
        tocheck.push(pt);
        Estimator.visited[pt.i][pt.j] = visited_counter;
    
        while (tocheck.length) {
            const p = this.pop_n_remove(tocheck, 0);
            if (board[p.i][p.j] == COLOR.empty) {
                board[p.i][p.j] = player;
                this.get_neighbors(p, neighbors);
                for (let i=0; i < neighbors.length; ++i) {
                    const neighbor = neighbors[i];
                    if (Estimator.visited[neighbor.i][neighbor.j] == visited_counter) continue;
                    Estimator.visited[neighbor.i][neighbor.j] = visited_counter;
                    tocheck.push(neighbor);
                }
            }
        }
    }

    synchronize_tracking_counters(track: Array<number[]>, visited: Array<boolean[]>, p: IPosition) {
        const tocheck = new Array();
        const neighbors = new Array();
        const my_color = this.board[p.i][p.j];
        let max_track_value = track[p.i][p.j];
        const to_synchronize = new Array();
    
        tocheck.push(p);
        visited[p.i][p.j] = true;
    
        if (my_color == COLOR.empty) {
            return;
        }
    
        while (tocheck.length) {
            const p = this.pop_n_remove(tocheck, 0);
            to_synchronize.push(p);
            max_track_value = max_track_value < 0 ?
                                Math.min(track[p.i][p.j], max_track_value) :
                                Math.max(track[p.i][p.j], max_track_value);
            this.get_neighbors(p, neighbors);
            for (let i=0; i < neighbors.length; ++i) {
                const neighbor = neighbors[i];
                if (this.board[neighbor.i][neighbor.j] == my_color) {
                    if (visited[neighbor.i][neighbor.j]) continue;
                    visited[neighbor.i][neighbor.j] = true;
                    tocheck.push(neighbor);
                }
            }
        }
    
        for (let i=0; i < to_synchronize.length; ++i) {
            const p = to_synchronize[i];
            track[p.i][p.j] = max_track_value;
        }
    }

    score(board: Array<COLOR[]>, rules_type: string, dead_black: number, dead_white: number, komi: number) {
        const resultBoard = this.copy_board(board,19,19);

        if(rules_type === "japanese"){
            this.j_rules(resultBoard);
        }
        
        let ret = 0;
        for (let i=0; i < this.height; ++i) {
            for (let j=0; j < this.width; ++j) {
                ret += resultBoard[i][j] === COLOR.black ? 1 : resultBoard[i][j] === COLOR.white ? -1 : 0;
            }
        }
        if(rules_type === "japanese"){
            ret -= dead_black; // убрать оценки от очков. потому что умершие объекты занимают своими местами.
            ret += dead_white;
        }
        else if(rules_type === "chinese"){
        }
        // if(komi){
        //     ret -= rules_type === "Japanese" ? 6.5 : 3.75;
        // }

        ret -= komi;
        return ret;
    }
    print_board(board: Array<COLOR[]>){
        for(let i = 0; i< 19; i++){
            let output = '';
            for(let j = 0; j< 19; j++){
                output += board[i][j].toString();
                output += " ";
            }
            console.log(output);
        }
        console.log();
    }

    j_rules(board: Array<COLOR[]>){
        // let black_count = 0;
        // let white_count = 0;
        for(let i = 0; i < this.height; ++i ){
            this.board[i].map((item,index)=>{
                // item === COLOR.black ? black_count++ : item === COLOR.white ? white_count++ : "";
                if (item === board[i][index] ) {
                    board[i][index] = COLOR.empty;
                }
            })            
        }
    }
    c_rules(board: Array<COLOR[]>){

    }
}

export default Estimator