import React from "react";
import Profile from "../views/profile/Profile";
import Main from "../views/main/Main";
import GoPage from "../views/go/GoPage";
import CreateGame from "../views/createGame/CreateGame";
import TvPage from "../views/tv/TvPage";
import AdminContestsPage from "../views/admin/contests/ContestsPage";
import CreateContestsPage from "../views/admin/createContests/CreateContestsPage";
import ContestPage from "../views/contest/contestOne/ContestPage";
import ContestsListPage from "../views/contest/contestsList/ContestsListPage";
import Ratings from "../views/ratings/Ratings";
import ProfileEdit from "../views/profileEdit";
import CompetitionRegulations from "../views/competitionsRegulations/CompetitionRegulations";
import Users from "../views/admin/users/Users";
import CompetitionRegulation from "../views/competitionRegulation/CompetitionRegulation";
import Archive from "../views/archive/Archive";
import ChessPageWithComputer from '../views/go/ChessPageWithComputer';
import Language from "../views/admin/language/Language";

export interface IRoute {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
}

export enum RouteName {
  MAIN = "/",
  PROFILE = "/profile",
  PROFILE_EDIT = "/profile/edit",
  PUBLIC_PROFILE = "/profile/:id",
  TEST = "/test",
  TV = "/tv",
  CREATE_GAME = "/create-game",
  COMPUTER_GAME = "/computer-game",
  GAME = "/game/:gameId",
  ADMIN = "/admin",
  ADMIN_CONTEST = "/admin/contests",
  CONTEST_CREATE = "/admin/contests/create",
  CONTESTS_LIST = "/contests",
  CONTEST = "/contests/:id",
  RATINGS = "/ratings",
  COMPETITIONS_REGULATIONS = "/competition/regulations/:page",
  COMPETITION_REGULATION = "/competition/regulation/:id",
  USERS = "/users",
  ARCHIVE = "/archive",
  LANGUAGE_CONFIG = "/admin/language",
}

export const publicRoutes: IRoute[] = [
  { path: RouteName.MAIN, exact: true, component: Main },
  { path: RouteName.PUBLIC_PROFILE, exact: true, component: Profile },
  { path: RouteName.CONTESTS_LIST, exact: true, component: ContestsListPage },
  { path: RouteName.CONTEST, exact: true, component: ContestPage },
  {
    path: RouteName.COMPETITIONS_REGULATIONS,
    exact: true,
    component: CompetitionRegulations,
  },
  {
    path: RouteName.COMPETITION_REGULATION,
    exact: true,
    component: CompetitionRegulation,
  },
  { path: RouteName.COMPUTER_GAME, exact: true, component: ChessPageWithComputer },
  { path: RouteName.GAME, exact: true, component: GoPage },
  { path: RouteName.RATINGS, exact: true, component: Ratings },
];

export const privateRoutes: IRoute[] = [
  { path: RouteName.PROFILE, exact: true, component: Profile },
  { path: RouteName.PROFILE_EDIT, exact: true, component: ProfileEdit },
  { path: RouteName.PUBLIC_PROFILE, exact: true, component: Profile },
  { path: RouteName.TEST, exact: true, component: Profile },
  { path: RouteName.CREATE_GAME, exact: true, component: CreateGame },
  { path: RouteName.GAME, exact: true, component: GoPage },
  { path: RouteName.TV, exact: true, component: TvPage },
];

export const adminRoutes: IRoute[] = [
  { path: RouteName.ADMIN_CONTEST, exact: true, component: AdminContestsPage },
  { path: RouteName.USERS, exact: true, component: Users },
  {
    path: RouteName.CONTEST_CREATE,
    exact: true,
    component: CreateContestsPage,
  },
  { path: RouteName.ARCHIVE, exact: true, component: Archive },
  { path: RouteName.LANGUAGE_CONFIG, exact: true, component: Language },  
];
