import React, { useContext, useEffect, useState } from "react";
import contestStore from "../../../store/contest.store";
import winnerStore from "../../../store/winner.store";
import { useParams } from "react-router";
import openNotificationWithIcon from "../../../utils/openNotification";
import { observer } from "mobx-react-lite";
import { Avatar, Spin, TableColumnsType } from "antd";

import "./contestPage.scss";
import ContestDescriptionCard from "../../../components/contestDescriptionCard/ContestDescriptionCard";
import moment from "moment";
import history from "../../../history";
import SadEmoji from "../../../svg/SadEmoji";
import { RouteName } from "../../../routers";
import RatingTable from "../../../components/ratingTable/RatingTable";
import ratingStore from "../../../store/rating.store";
import { toDataContestRating } from "../../../services/utils/toDataContestRating";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { toDataContestWinner } from "../../../services/utils/toDataContestWinner";
import WinnerTable from "../../../components/winnerTable/WinnerTable";
import { IContestWinner } from "../../../models/IContestWinner";
import { Context } from "../../../index";
import i18next from "i18next";

const ContestPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { authStore } = useContext(Context);
  const detail = contestStore.contest?.details?.filter(item=>item.LANGUAGE===i18next.language)[0];

  const initEffectHandler = () => {
    contestStore
      .getContestById(id)
      .then()
      .catch(() =>
        openNotificationWithIcon(
          "error",
          t('error'),
          t('failToFetchCompetition')
        )
      );
    ratingStore.getContestRating(0, 5, id);
    winnerStore.getWinners(id);

    return destroyEffectHandler;
  };

  const destroyEffectHandler = () => {
    contestStore.clearContest();
    ratingStore.clearContestRatingList();
    winnerStore.clearWinners();
  };

  useEffect(initEffectHandler, []);
  const update = ()=>{
    winnerStore.getWinners(id);
  }

  const ratingTab = (
    <div className="rating">
    <h2 className="title">{t("playerRating")}</h2>
    <div className="card d-flex align-items-center info">
      <div>{t("RatingBasedOnTime")}</div>
    </div>
    <Spin spinning={ratingStore.isLoading}>
      {!!ratingStore.contestRatingList.length && (
        <RatingTable
          data={toDataContestRating(ratingStore.contestRatingList)}
        />
      )}
    </Spin>
  </div>
  );

  const winnerTab = (
    <div className="winners">
    <h2 className="title">{t("winners")}</h2>
    <Spin spinning={contestStore.isLoading}>
      {!!winnerStore.winners && (
      <WinnerTable
        data={toDataContestWinner(winnerStore.winners)}
        update={update}
      />)}
    </Spin>
  </div>
  )

  return (
    <Spin spinning={contestStore.isLoading}>
      <div className="contest container">
        <div onClick={() => history.goBack()}>
          <span className="back">{t("back")}</span>
        </div>
        <h1 className="title">{detail?.name || contestStore.contest?.name}</h1>
        <div className="d-flex gap-1">
          <ContestDescriptionCard
            prize={contestStore.contest?.prize}
            status={contestStore.contest?.status}
            description={detail?.description || contestStore.contest?.description}
            date={`${moment(contestStore.contest?.dateStart).format(
              "DD MMM yyyy"
            )} 
          - ${moment(contestStore.contest?.dateEnd).format("DD MMM yyyy")}`}
            users={contestStore.contest?.userCount || 0}
            link={RouteName.COMPETITION_REGULATION.replace(":id", id)}
          />
          {contestStore.contest?.status === "closed" &&
            !!winnerStore.winners?.length && (
              <div
                className="contest-description-card"
                style={{ maxWidth: 282 }}
              >
                <div className="d-flex align-items-center justify-center flex-column">
                  <div
                    className="description__title"
                    style={{ marginBottom: 35 }}
                  >
                    {t("winner")}
                  </div>
                  <div className="photo">
                    {winnerStore.winners[0].userId["photo"] ? (
                      <img
                        src={
                          winnerStore.winners[0].userId["photo"]
                            ? winnerStore.winners[0].userId["photo"]
                            : ""
                        }
                      />
                    ) : (
                      <Avatar size={100} className="avatar">
                        <UserOutlined />
                      </Avatar>
                    )}
                  </div>
                  <div
                    className="description__title"
                    style={{ marginBottom: 35 }}
                  >
                    {winnerStore.winners[0]
                      ? winnerStore.winners[0].userId["lastName"] +
                        " " +
                        winnerStore.winners[0].userId["firstName"]
                      : "unknown"}
                  </div>
                </div>

                <div
                  className="d-flex align-items-center justify-center"
                  style={{ width: "100%" }}
                >
                  <div className="prize d-flex align-items-center">
                    {contestStore.contest?.placesDistribution[0] === null
                      ? contestStore.contest?.prize
                      : contestStore.contest?.placesDistribution[0]}{" "}
                    ₽
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="d-flex justify-between align-items-center complaint card">
          <div className="d-flex align-items-center">
            <SadEmoji />
            <p>{t("ifYou")}</p>
          </div>
          <button>{t("sendReport")}</button>
        </div>
        {contestStore.contest?.status !== "closed"
          ? ratingTab
          : authStore.user.role && authStore.user.role.name === "ADMIN"
          ? winnerTab
          : ratingTab}
      </div>
    </Spin>
  );
};

export default observer(ContestPage);
