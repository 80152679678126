import axios, {AxiosResponse} from 'axios';
import $api from '../http';

export default class CountryCitiesService {
  static async getCountryCities(): Promise<AxiosResponse<any>> {
    return await axios.get('https://raw.githubusercontent.com/David-Haim/CountriesToCitiesJSON/master/countriesToCities.json');
  }

  static async getCountries() {
    return $api.get(`/users/countries`);
  }

  static async getCities(id: number) {
    return $api.get(`/users/cities/${id}`);
  }
}