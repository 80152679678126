import {AxiosResponse} from 'axios';
import $api from '../http';
import {IContestWinner} from '../models/IContestWinner';

export default class ContestWinnerService {
  static async getWinners(id:string): Promise<AxiosResponse<IContestWinner[]>> {
    return $api.get(`/winner/all/${id}`);
  }
  static async setPayed(data: {_id: string, payed: boolean}): Promise<AxiosResponse<Boolean>> {
    return $api.post("/winner/setpayed", data);
  }

}