import React, { Component } from "react";
import PropTypes from "prop-types";
import Chess from "chess.js"; // import Chess from  "chess.js"(default) if recieving an error about new Chess not being a constructor

const STOCKFISH = window.STOCKFISH;
const game = new Chess();

class StockFish extends Component {
  static propTypes = {
    children: PropTypes.func,
    color: PropTypes.string,
    level: PropTypes.string,
    timePlayer: PropTypes.any,
    timeComputer: PropTypes.any,
  };

  state = { fen: "start" };

  componentDidMount() {
    this.setState({ fen: game.fen() });
    this.engineGame().setSkillLevel(this.props.level || 4);
    this.engineGame().prepareMove();
    console.log(this.props);
  }

  onMove = (sourceSquare, targetSquare) => {
    const move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q",
    });

    // illegal move
    if (move === null) return;
    return new Promise((resolve) => {
      this.setState({ fen: game.fen() });
      resolve();
    }).then(() => this.engineGame().prepareMove());
  };

  engineGame = (options) => {
    options = options || {};

    /// We can load Stockfish via Web Workers or via STOCKFISH() if loaded from a <script> tag.
    let engine =
      typeof STOCKFISH === "function"
        ? STOCKFISH()
        : new Worker(options.stockfishjs || "stockfish.js");
    let evaler =
      typeof STOCKFISH === "function"
        ? STOCKFISH()
        : new Worker(options.stockfishjs || "stockfish.js");
    let engineStatus = {};
    let time = { wtime: 30000, btime: 30000, winc: 2000, binc: 2000 };
    let playerColor = this.props.color;
    let clockTimeoutID = null;
    // let isEngineRunning = false;
    let announced_game_over;
    // do not pick up pieces if the game is over
    // only pick up pieces for White

    setInterval(function () {
      if (announced_game_over) {
        return;
      }

      if (game.game_over()) {
        announced_game_over = true;
        alert("Game Over");
      }
    }, 500);

    function uciCmd(cmd, which) {
      (which || engine).postMessage(cmd);
    }
    uciCmd("uci");

    const displayClock = (color, t) => {
      let isRunning = false;
      if (time.startTime > 0 && color == time.clockColor) {
        t = Math.max(0, t + time.startTime - Date.now());
        isRunning = true;
      }
      let id =
        color == playerColor ? this.props.timePlayer : this.props.timeComputer;
      let sec = Math.ceil(t / 1000);
      let min = Math.floor(sec / 60);
      sec -= min * 60;
      let hours = Math.floor(min / 60);
      min -= hours * 60;
      let display =
        hours + ":" + ("0" + min).slice(-2) + ":" + ("0" + sec).slice(-2);
      if (isRunning) {
        display += sec & 1 ? " <--" : " <-";
      }

      id.current = display;
    }

    function updateClock() {
      displayClock("white", time.wtime);
      displayClock("black", time.btime);
    }

    function clockTick() {
      updateClock();

      let t =
        (time.clockColor === "white" ? time.wtime : time.btime) +
        time.startTime -
        Date.now();
      let timeToNextSecond = (t % 1000) + 1;
      clockTimeoutID = setTimeout(clockTick, timeToNextSecond);
    }

    function stopClock() {
      if (clockTimeoutID !== null) {
        clearTimeout(clockTimeoutID);
        clockTimeoutID = null;
      }
      if (time.startTime > 0) {
        let elapsed = Date.now() - time.startTime;
        time.startTime = null;
        if (time.clockColor === "white") {
          time.wtime = Math.max(0, time.wtime - elapsed);
        } else {
          time.btime = Math.max(0, time.btime - elapsed);
        }
      }
    }

    function startClock() {
      if (game.turn() === "w") {
        time.wtime += time.winc;
        time.clockColor = "white";
      } else {
        time.btime += time.binc;
        time.clockColor = "black";
      }
      time.startTime = Date.now();
      clockTick();
    }

    function get_moves() {
      let moves = "";
      let history = game.history({ verbose: true });

      for (let i = 0; i < history.length; ++i) {
        let move = history[i];
        moves +=
          " " + move.from + move.to + (move.promotion ? move.promotion : "");
      }

      return moves;
    }

    const prepareMove = () => {
      stopClock();
      // this.setState({ fen: game.fen() });
      let turn = game.turn() === "w" ? "white" : "black";
      if (!game.game_over()) {
        // if (turn === playerColor) {
        if (turn !== playerColor) {
          // playerColor = playerColor === 'white' ? 'black' : 'white';
          uciCmd("position startpos moves" + get_moves());
          uciCmd("position startpos moves" + get_moves(), evaler);
          uciCmd("eval", evaler);

          if (time && time.wtime) {
            uciCmd(
              "go " +
                (time.depth ? "depth " + time.depth : "") +
                " wtime " +
                time.wtime +
                " winc " +
                time.winc +
                " btime " +
                time.btime +
                " binc " +
                time.binc
            );
          } else {
            uciCmd("go " + (time.depth ? "depth " + time.depth : ""));
          }
          // isEngineRunning = true;
        }
        if (game.history().length >= 2 && !time.depth && !time.nodes) {
          startClock();
        }
      }
    };

    evaler.onmessage = function (event) {
      let line;

      if (event && typeof event === "object") {
        line = event.data;
      } else {
        line = event;
      }

      // console.log('evaler: ' + line);

      /// Ignore some output.
      if (
        line === "uciok" ||
        line === "readyok" ||
        line.substr(0, 11) === "option name"
      ) {
        return;
      }
    };

    engine.onmessage = (event) => {
      let line;

      if (event && typeof event === "object") {
        line = event.data;
      } else {
        line = event;
      }
      // console.log('Reply: ' + line);
      if (line === "uciok") {
        engineStatus.engineLoaded = true;
      } else if (line === "readyok") {
        engineStatus.engineReady = true;
      } else {
        let match = line.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
        /// Did the AI move?
        if (match) {
          // isEngineRunning = false;
          game.move({ from: match[1], to: match[2], promotion: match[3] });
          this.setState({ fen: game.fen() });
          prepareMove();
          uciCmd("eval", evaler);
          //uciCmd("eval");
          /// Is it sending feedback?
        } else if (
          (match = line.match(/^info .*\bdepth (\d+) .*\bnps (\d+)/))
        ) {
          engineStatus.search = "Depth: " + match[1] + " Nps: " + match[2];
        }

        /// Is it sending feed back with a score?
        if ((match = line.match(/^info .*\bscore (\w+) (-?\d+)/))) {
          let score = parseInt(match[2], 10) * (game.turn() === "w" ? 1 : -1);
          /// Is it measuring in centipawns?
          if (match[1] === "cp") {
            engineStatus.score = (score / 100.0).toFixed(2);
            /// Did it find a mate?
          } else if (match[1] === "mate") {
            engineStatus.score = "Mate in " + Math.abs(score);
          }

          /// Is the score bounded?
          if ((match = line.match(/\b(upper|lower)bound\b/))) {
            engineStatus.score =
              ((match[1] === "upper") === (game.turn() === "w")
                ? "<= "
                : ">= ") + engineStatus.score;
          }
        }
      }
      // displayStatus();
    };

    return {
      start: function () {
        uciCmd("ucinewgame");
        uciCmd("isready");
        engineStatus.engineReady = false;
        engineStatus.search = null;
        prepareMove();
        announced_game_over = false;
      },
      setSkillLevel: function (skill) {
        let max_err, err_prob, difficulty_slider;

        if (skill < 0) {
          skill = 0;
        }
        if (skill > 20) {
          skill = 20;
        }

        time.level = skill;

        /// Change thinking depth allowance.
        if (skill < 5) {
          time.depth = "1";
        } else if (skill < 10) {
          time.depth = "2";
        } else if (skill < 15) {
          time.depth = "3";
        } else {
          /// Let the engine decide.
          time.depth = "";
        }

        uciCmd("setoption name Skill Level value " + skill);

        ///NOTE: Stockfish level 20 does not make errors (intentially), so these numbers have no effect on level 20.
        /// Level 0 starts at 1
        err_prob = Math.round(skill * 6.35 + 1);
        /// Level 0 starts at 10
        max_err = Math.round(skill * -0.5 + 10);

        uciCmd("setoption name Skill Level Maximum Error value " + max_err);
        uciCmd("setoption name Skill Level Probability value " + err_prob);
      },
      prepareMove: function () {
        prepareMove();
      },
    };
  };

  calcMovable = () => {
    const dests = new Map();

    game.SQUARES.forEach((s) => {
      const ms = game.moves({ square: s, verbose: true });

      if (ms.length) {
        dests.set(
          s,
          ms.map((m) => m.to)
        );
      }
    });

    return {
      free: false,
      dests,
      color: this.props.color,
    };
  };

  turnColor = () => {
    return game.turn() === "w" ? "white" : "black";
  };

  render() {
    const { fen } = this.state;
    return this.props.children({
      position: fen,
      onMove: this.onMove,
      movable: this.calcMovable,
      turnColor: this.turnColor,
    });
  }
}

export default StockFish;
