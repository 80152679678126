import './App.scss';
import './components/components.scss';

import React, {FC, useContext, useEffect, useState} from 'react';
import AppRouter from './components/AppRouter';
import {Layout, Spin} from 'antd';
import Navbar from './components/navbar/Navbar';
import {Content} from 'antd/es/layout/layout';
import './assets/styles/css/flag-icon.min.css';
import MainFooter from './components/footer/Footer';
import {Context} from './index';
import SocketService from './services/Socket.service';
import {observer} from 'mobx-react-lite';
import Messages from './components/messages/Messages';
import makeRandomString from './utils/makeRandomString';
import messageStore from './store/message.store';
import gamesStore from './store/games.store';
import {ThemeContext} from './providers/ThemeProvider';
import Sidebar from './components/sidebar/Sidebar';
import {TTheme} from './store/theme.store';
import i18next, {t} from 'i18next';
import moment from 'moment';
const App: FC = (props) => {
  const {authStore} = useContext(Context);
  const {themeStore} = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const lang = new URLSearchParams(window.location.search).get('lang') || 'ru';
    localStorage.setItem('lang', lang);
    moment.locale(lang)
    i18next.changeLanguage(lang);

    // document.title = t("title");
    // document.getElementsByTagName("meta")[4].content = t('meta_prop_content');
  }, []);

  const useEffectGetAuthCallback = () => {
    const checkAuthCallback = (res: any) => {
      SocketService.userListener(res!.id);
      gamesStore.getOnlineGames();
    };

    if (localStorage.getItem('token')) {
      authStore
        .checkAuth()
        .then(checkAuthCallback)
        .catch(e => console.log(e))
        .finally(() => setIsLoading(false));
    } else {
      SocketService.userListener(makeRandomString());
      gamesStore.getOnlineGames();
      setIsLoading(false);
    }
  };

  useEffect(useEffectGetAuthCallback, []);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      themeStore.theme = theme as TTheme;
    }
  }, []);

  return (

      isLoading ?
        <div className='loader-wrapper'><Spin size="large"/></div>
        :
        <Layout className={`${themeStore.theme === 'dark' ? 'dark' : 'light'}`}>
          <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />
          <Navbar />
          {
            messageStore.isVisible &&
            <Messages
              message={messageStore.message}
              color={messageStore.color}
              onClick={messageStore.onClick} />
          }
          <Content className={'w-1400'}>
            <AppRouter />
          </Content>
          <MainFooter/>
        </Layout>
  );
}

export default observer(App);
