import React from "react";
import MiniTvProps, { GameType } from "../../../types/props/miniTvProps";
import BlitzSvg from "../../../svg/BlitzSvg";
import gameType from "../../../models/vocabluary";
import PlayerInfoCard from "../../playerInfoCard/PlayerInfoCard";
import OutlineButton from "../../buttons/outlineButton/OutlineButton";
import RapidSvg from "../../../svg/RapidSvg";
import ClassicSvg from "../../../svg/ClassicSvg";
import "./miniTVCard.scss";
import history from "../../../history";
import { t } from "i18next";

const MiniTvCard = (props: MiniTvProps) => {
  return (
    <div className="mini-rating-card mini-tv-card d-flex align-items-center justify-between">
      <div className="d-flex align-items-center justify-between game-info__wrapper">
        {props.type === GameType.rapid ? (
          <RapidSvg />
        ) : props.type === GameType.blitz ? (
          <BlitzSvg />
        ) : (
          <ClassicSvg />
        )}

        <div className="info-wrapper">
          <span className="type">
            {/*@ts-ignore*/}
            {t(props.type)}
          </span>
          <span className="time">{props.time}</span>
        </div>
      </div>
      <div className="mini-rating-card__between d-flex align-items-center justify-between">
        <div className="d-flex align-items-center players-wrapper">
          <PlayerInfoCard
            className={"player-info-card-mini"}
            {...props.userWhite}
          />
          <span className="vs">vs</span>
          <PlayerInfoCard
            className={"player-info-card-mini"}
            {...props.userBlack}
          />
        </div>
        <OutlineButton
          title={t("watch")}
          handleOnClick={() => {
            history.push(`/game/${props.gameId}`);
          }}
        />
      </div>
    </div>
  );
};

export default MiniTvCard;
