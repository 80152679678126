import "./fastStart.scss";

import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../index";

import OutlineButton from "../buttons/outlineButton/OutlineButton";
import FilledButton from "../buttons/filledButton/FilledButton";
import ModalWaitTimer from "./modalWaitTimer/ModalWaitTimer";
import ModalNotFound from "./modalWaitTimer/modalNotFound/ModalNotFound";

import emitter from "../../emitter/Emitter";
import history from "../../history";

import { ICreateGame } from "../../views/createGame/components/gameSettings/GameSettings";
import { GameType } from "../../types/props/miniTvProps";
import { useTranslation } from "react-i18next";
import GameSetting from "../go/components/gameSetting/GameSetting";
import SelectUi from "../ui/SelectUI";

const FastStart = () => {
  const { t } = useTranslation();
  const { authStore, goStore } = useContext(Context);
  const [visibleAwaitGameModal, setVisibleAwaitGameModal] = useState({
    visible: false,
    timer: 0,
  });
  const [visibleNotFoundModal, setVisibleNotFoundModal] = useState<boolean>(false);
  const [size, setSize] = useState<string>("19x19");

  const handlerOnClickRedirect = () => {
    if (authStore.isAuth) {
      history.push("/create-game");
    } else {
      emitter.emit("openLogin", null);
    }
  };

  const handlerOnClick = (e: any, time: string) => {
    if (authStore.isAuth) {
      let gameData: ICreateGame = {} as ICreateGame;
      if (e.currentTarget) {
        const buttonData = e.currentTarget.id;
        gameData.type = buttonData;
        // gameData.mainTime = buttonData == "blitz" ? 10 : buttonData == "slow" ? 86400 : 30 ;
        // gameData.additionalTime = +buttonData[1];
        gameData.size = size;
        gameData.matchName = "fast start";
        gameData.isPrivate = false;
        gameData.isRengo = false;
        gameData.isRating = true;
        gameData.size = size;
        gameData.rules = "japanese";
        gameData.speed = "slow";
        gameData.timeControl = "fisherWatch";
        gameData.startingTime = time;
        gameData.additionalTime = "60";
        gameData.maxTime = "604800";
        gameData.stopOnWeekends = true;
        gameData.fora = false;
        gameData.komi = "6.5";
        gameData.color = "auto";
        gameData.stopAnalyze = false;
        gameData.limitedLevel = false;
        console.log("gameData",gameData);
      } else {
        gameData = e as ICreateGame;
        console.log("gameData else",gameData);
      }
      goStore.createGame(gameData).then();
      setVisibleAwaitGameModal((prevState) => ({
        ...prevState,
        visible: true,
      }));

      setTimeout(timerCallback, 180000);
    } else {
      emitter.emit("openLogin", null);
    }
  };

  const timerCallback = () => {
    setVisibleNotFoundModal(true);

    setVisibleAwaitGameModal((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleCloseNotFoundModal = () => {
    setVisibleNotFoundModal(false);
  };

  const handleClickContinue = () => {
    setVisibleNotFoundModal(false);

    setVisibleAwaitGameModal((prevState) => ({
      ...prevState,
      visible: true,
    }));

    setTimeout(timerCallback, 180000);
  };

  const handleCloseGame = () => {
    setVisibleAwaitGameModal({ visible: false, timer: 0 });
    goStore.closeGame();
  };

  useEffect(() => {
    if (authStore.isAuth && visibleAwaitGameModal.visible) {
      setTimeout(() => {
        setVisibleAwaitGameModal((prevState) => ({
          ...prevState,
          timer: prevState.timer + 1,
        }));
      }, 1000);
    }
  }, [visibleAwaitGameModal]);

  useEffect(() => {
    return () => {
      setVisibleAwaitGameModal({ visible: false, timer: 0 });
      goStore.closeGame();
    };
  }, []);

  useEffect(() => {
    const emitterCallback = (data: any) => {
      handlerOnClick(data,"");
    };
    emitter.on("createGame", emitterCallback);

    return () => {
      emitter.removeListener("createGame", emitterCallback);
    };
  }, []);

  const setLayout = (e : any)=>{
    setSize(e.currentTarget.id)
  }
  const btns_style = {
    width: "auto",
    padding: ".5rem .5rem"
  }

  return (
    <div className={"fast-start d-flex flex-column main-card-wrapper"}>
      <div
        className="header d-flex justify-between align-items-center"
        style={{ display: "flex" }}
      >
        <h4 className={"green-title"}>{t("quickStart")}</h4>
        <div className="buttons">
          <OutlineButton
            className={`fast-start__layout ${size == "9x9" ? "selected" : ""}`}
            title={"9x9"}
            id={"9x9"}
            data-value={"9x9"}
            handleOnClick={setLayout}
            style={btns_style}
          ></OutlineButton>
          <OutlineButton
            className={`fast-start__layout ${size == "13x13" ? "selected" : ""}`}
            title={"13x13"}
            id={"13x13"}
            data-value={"13x13"}
            handleOnClick={setLayout}
            style={btns_style}
          ></OutlineButton>
          <OutlineButton
            className={`fast-start__layout ${size === "19x19" ? "selected" : ""}`}
            title={"19x19"}
            id={"19x19"}
            data-value={"19x19"}
            handleOnClick={setLayout}
            style={btns_style}
          ></OutlineButton>
          <SelectUi list={["9x9", "13x13", "19x19"]} defaultValue="19x19" onChange={(value)=>{setSize(value)}} />
        </div>
        <GameSetting />
      </div>
      <div className="d-flex justify-center mb">
        <OutlineButton
          className={"fast-start__card"}
          title={t("blitz")}
          id={"blitz"}
          handleOnClick={(e)=>{handlerOnClick(e,"10")}}
        >
          ~ 10 {t('second')} {t('onMove')}
        </OutlineButton>
        <OutlineButton
          className={"fast-start__card"}
          title={t('normal')}
          id={"normal"}
          handleOnClick={(e)=>{handlerOnClick(e,"30")}}
        >
          ~ 30 {t('second')} {t('onMove')}
        </OutlineButton>
        {/* <OutlineButton
          className={"fast-start__card"}
          title={"5+0"}
          id={"5+0"}
          handleOnClick={handlerOnClick}
        >
          {t("blitz")}
        </OutlineButton>
        <OutlineButton
          className={"fast-start__card"}
          title={"5+1"}
          id={"5+1"}
          handleOnClick={handlerOnClick}
        >
          {t("blitz")}
        </OutlineButton> */}
      </div>
      <div className="d-flex justify-center mb">
        <OutlineButton
          className={"fast-start__card"}
          title={t('normal')}
          id={"normal"}
          handleOnClick={(e)=>{handlerOnClick(e,"60")}}
        >
          ~ 1 {t('minute')} {t('onMove')}
        </OutlineButton>
        <OutlineButton
          className={"fast-start__card"}
          title={t('slow')}
          id={"slow"}
          handleOnClick={(e)=>{handlerOnClick(e,"86400")}}
        >
          ~ 1 {t('day')} {t('onMove')}
        </OutlineButton>
        {/* <OutlineButton
          className={"fast-start__card"}
          title={"30+0"}
          id={"30+0"}
          handleOnClick={handlerOnClick}
        >
          {t("classic")}
        </OutlineButton>
        <OutlineButton
          className={"fast-start__card"}
          title={"30+10"}
          id={"30+10"}
          handleOnClick={handlerOnClick}
        >
          {t("classic")}
        </OutlineButton> */}
      </div>
      <FilledButton
        className={"content-button"}
        title={t("start")}
        handleOnClick={handlerOnClickRedirect}
      />

      <ModalWaitTimer
        timer={visibleAwaitGameModal.timer}
        visible={visibleAwaitGameModal.visible}
        className={"wait-timer"}
        handleCancel={handleCloseGame}
      />

      <ModalNotFound
        onClickContinue={handleClickContinue}
        visible={visibleNotFoundModal}
        handleCancel={handleCloseNotFoundModal}
        className={"wait-timer"}
      />
    </div>
  );
};

export default FastStart;
