import React, {useContext} from 'react';
import {ModalProps} from '../types';
import {Form, Input} from 'antd';
import FilledButton from '../../buttons/filledButton/FilledButton';
import AuthModal from '../modal/AuthModal';
import {Context} from '../../../index';
import messageStore from '../../../store/message.store';
import { useTranslation } from 'react-i18next';

const RemindPassword = (props: ModalProps) => {
  const {authStore} = useContext(Context);
  const { t } = useTranslation();

  const submit = (e: {email: string}) => {
    authStore
      .remindPassword(e.email)
      .finally(() => {
        props.handleCancel();
        messageStore.openMessage(
          '#2FB590',
          () => {messageStore.isVisible = false},
          t('linkToChange'));
      });
  }

  const modalProps = {
    className: 'registration',
    title: t('recovery'),
    visible: props.visible,
    handleCancel: props.handleCancel
  }

  return (
    <AuthModal {...modalProps}>
      <Form name='remindPassword' onFinish={submit}>
        <p>{ t('for') }</p>
        <Form.Item label={null} name='email'
          rules={[
            {required: true, message: t('requiredField')},
            {type: 'email', message: t('fieldForEmail')}
          ]}>
          <Input aria-autocomplete={'none'} type="email" placeholder= { t('email') }/>
        </Form.Item>
        <p>{ t('term') }</p>
        <FilledButton title={ t('send') } />
      </Form>
    </AuthModal>
  );
};

export default RemindPassword;