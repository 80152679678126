import { ITableRowForWinnerTable } from "../../components/winnerTable/WinnerTable";
import { IContest } from "../../models/IContest";
import { IUser } from "../../types/models/IUser";
import { IContestWinner } from "../../models/IContestWinner";
import userStore from "../../store/user.store";

export const toDataContestWinner = (
  winners: IContestWinner[]
): ITableRowForWinnerTable[] => {
  let rowData = Array<ITableRowForWinnerTable>();
  winners.forEach((winner) => {
    rowData.push({
      _id: winner._id ? winner._id : '',
      name:
        winner.userId.firstName && winner.userId.lastName
          ? winner.userId.firstName + " " + winner.userId.lastName
          : "unknown",
      country: winner.userId.country
        ? winner.userId.country
        : "ru",
      photo: winner.userId.photo,
      rank: winner.userId.rank,

      amount: winner.amount,
      hasPassport: winner.userId.passport?.length === 0 ? false : true,
      hasSentMoney: winner.payed,
    })
  });
  return rowData;
};

  // export const toDataContestWinner = (data : IContest) :  ITableRowForWinnerTable[] => {

  //     return data.places? data.places?.map((user) => {
  //         return {
  //             _id: user._id,
  //             name: user.firstName + " " + user.lastName,
  //             country: user.country,
  //             photo: user.photo,
  //             rank: user.rank,

  //             amount: 1000,
  //             hasPassport: user.passport ? true : false,
  //             hasSentMoney: false
  //         }
  //     }) : []

  // return[
  //     {
  //         _id: "0",
  //         name: "Jane Doe",
  //         country: "ru",
  //         photo: undefined,
  //         rank: undefined,

  //         amount: 1000, // надо как-то узнать сколько юзер получил на конкурсе.
  //         hasPassport: true,
  //         hasSentMoney: false,
  //     },
  //     {
  //         _id: "1",
  //         name: "John Doe",
  //         country: "ru",
  //         photo: undefined,
  //         rank: undefined,

  //         amount: 500, // надо как-то узнать сколько юзер получил на конкурсе.
  //         hasPassport: false,
  //         hasSentMoney: true,
  //     },
  //     {
  //         _id: "2",
  //         name: "Foxy Doe",
  //         country: "ru",
  //         photo: undefined,
  //         rank: undefined,

  //         amount:1500, // надо как-то узнать сколько юзер получил на конкурсе.
  //         hasPassport: true,
  //         hasSentMoney: true,
  //     },

  // ]
