import {IAuth} from '../types/models/IUser';
import {makeAutoObservable} from 'mobx';
import AuthService from '../services/Auth.service';
import axios from 'axios';
import {AuthResponse} from '../types/Response/AuthResponse';
import {API_URL} from '../config/consts';
import {rejects} from 'assert';
import emitter from '../emitter/Emitter';

export default class AuthStore {
  user = {} as IAuth;
  isAuth: boolean = false;
  isLoading: boolean = false;
  isMiniLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool: boolean): void {
    this.isAuth = bool;
  }

  setUser(user: IAuth): void {
    this.user = user;
  }

  setLoading(bool: boolean): void {
    this.isLoading = bool;
  }

  setMiniLoading(bool: boolean): void {
    this.isMiniLoading = bool;
  }

  async login(email: string, password: string) {
    try {
      this.setMiniLoading(true);
      const response = await AuthService.login(email, password);
      if (!response.data.user.isActivated) {
        emitter.emit('openMailConfirm', response.data.user.email);
        throw new Error();
        return ;
      }
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      return response.data.user;
    } catch (e: any) {
      return new Promise((resolve, reject) => reject(e.response?.data?.message))  ;
    } finally {
      this.setMiniLoading(false);
    }
  }

  async registration(email: string, password: string) {
    try {
      this.setMiniLoading(true);
      const response = await AuthService.registration(email, password);
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      return response.data.user;
    } catch (e: any) {
      return new Promise((resolve, reject) => reject(e.response?.data?.message))  ;
    } finally {
      this.setMiniLoading(false);
    }
  }

  async logout() {
    try {
      await AuthService.logout();
      localStorage.removeItem('token');
      this.setAuth(false);
      this.setUser({} as IAuth);
    } catch (e: any) {
      console.log(e.response?.data?.message);
    }
  }

  async remindPassword(email: string) {
    try {
      this.setMiniLoading(true);
      await AuthService.remindPassword(email);
    } catch (e: any) {
      console.log(e.response?.data?.message);
    } finally {
      this.setMiniLoading(false);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await axios.get<AuthResponse>(`${API_URL}/auth/refresh`, {
        withCredentials: true
      });
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      return response.data.user;
    } catch (e: any) {
      localStorage.removeItem('token');
      throw e.response?.data?.message;
    } finally {
      this.setLoading(false);
    }
  }
}