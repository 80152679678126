// @ts-nocheck
import "./dictionaryList.scss";

import React, {FC, useContext, useEffect} from 'react';
import { ThemeContext } from "../../../../../../../providers/ThemeProvider";
import { observer } from "mobx-react-lite";
import languageStore from '../../../../../../../store/language.store';

interface IDictionaryListProps {
  search: string;
  setKey: Function;

  langDictionary: string;
}

const DictionaryList: FC<IDictionaryListProps> = ({
  search,
  setKey,
  langDictionary,
}) => {
  const { themeStore } = useContext(ThemeContext);

  return (
    <div className="dictionary-list">
      <div className={`menu-list ${themeStore.theme}`}>
        <div className="menu-list-content">
          {!!languageStore.translation[langDictionary] && Object.entries(languageStore.translation[langDictionary])
            .filter((element) =>
              element[1].toLowerCase().includes(search.toLowerCase())
            )
            .sort((a, b) => (a[1] < b[1] ? -1 : 1))
            .map((element) => (
              <div
                className="menu-list-items"
                key={element[0]}
                onClick={() => setKey([element[0], element[1]])}
              >
                {element[1]}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default observer(DictionaryList);
