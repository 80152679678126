import "./emptyDescription.scss";
import React, { useContext } from "react";
import history from "../../../../../../history";
import { useParams } from "react-router";
import { Context } from "../../../../../../index";
import { useTranslation } from "react-i18next";

const EmptyDescription = () => {
  const { id } = useParams<{ id: string }>();
  const { authStore } = useContext(Context);
  const { t } = useTranslation();
  
  return authStore.user.id === id ? (
    <div className="empty-description">
      <div>
        <p className={"info"}>
          { t('emptyInfo') }{' '}
          <span onClick={() => history.push("/profile/edit")}>
          { t('fillTheInfo') } &gt;
          </span>
        </p>
      </div>
    </div>
  ) : (
    <div className="empty-description">
      <div>
        <p className={"info"}>{ t('infoStillEmpty') }</p>
      </div>
    </div>
  );
};

export default EmptyDescription;
