import "./historyProfile.scss";
import React, { useEffect, useState } from "react";
import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { IPropsHistoryProfile } from "./types";
import PlayerInfoCard from "../../../../components/playerInfoCard/PlayerInfoCard";
import PlayerCardProps from "../../../../types/props/playerCardProps";
import EmptyGames from "./component/EmptyGames";
import gameType from "../../../../models/vocabluary";
import history from "../../../../history";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const MobileHistory = ({ data, id }: { data: any; id?: any }) => {
  const { t } = useTranslation();
  return (
    <div className="mobile-history">
      <div className="card">
        {data.playerWhite &&
          data.playerBlack &&
          (id ? (
            data.playerWhite._id == id ? (
              <PlayerInfoCard
                photo={data.playerBlack.photo}
                country={data.playerBlack.country}
                rank={data.playerBlack.rank}
                name={`${data.playerBlack.lastName} ${data.playerBlack.firstName}`}
              />
            ) : (
              <PlayerInfoCard
                photo={data.playerWhite.photo}
                country={data.playerWhite.country}
                rank={data.playerWhite.rank}
                name={`${data.playerWhite.lastName} ${data.playerWhite.firstName}`}
              />
            )
          ) : (
            <>
              <PlayerInfoCard
                photo={data.playerBlack.photo}
                country={data.playerBlack.country}
                rank={data.playerBlack.rank}
                name={`${data.playerBlack.lastName} ${data.playerBlack.firstName}`}
              />
              <PlayerInfoCard
                photo={data.playerWhite.photo}
                country={data.playerWhite.country}
                rank={data.playerWhite.rank}
                name={`${data.playerWhite.lastName} ${data.playerWhite.firstName}`}
              />
            </>
          ))}
        <div className="data">
          <div className="d-flex justify-between line">
            <div className="title">{ t('round') }</div>
            <div className="value">
              {`${gameType[data.type as "blitz" | "rapid" | "classic"]} ${
                data.startingTime / 60
              } + ${data.additionalTime}`}
            </div>
          </div>
          <div className="d-flex justify-between line">
            <div className="title">{ t('result') }</div>
            <div className="value">{data.winner == id ? "1 - 0" : "0 - 1"}</div>
          </div>
          {/*<div className="d-flex justify-between">*/}
          {/*  <div className="title">Ходов</div>*/}
          {/*  <div className="value">*/}
          {/*    /!*{`${gameType[text]} ${data.mainTime / 60} + ${data.additionalTime}`}*!/*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="d-flex justify-between" style={{ paddingTop: 7 }}>
            <div className="title">{ t('dateAndTime') }</div>
            <div className="value">
              {new Date(data.created).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HistoryProfile = ({ data, id }: IPropsHistoryProfile): JSX.Element => {
  const [innerWidth, setInnerWidth] = useState<number>();
  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [innerWidth]);

  const columns: ColumnsType<any> = [
    {
      title: t('competitor'),
      dataIndex: "opponent",
      render: (opponentObject: PlayerCardProps, data) => {
        if (data.playerWhite && data.playerBlack) {
          if (data.playerWhite._id == id) {
            return (
              <PlayerInfoCard
                photo={data.playerBlack.photo}
                country={data.playerBlack.country}
                rank={data.playerBlack.rank}
                name={`${data.playerBlack.lastName} ${data.playerBlack.firstName}`}
              />
            );
          } else {
            return (
              <PlayerInfoCard
                photo={data.playerWhite.photo}
                country={data.playerWhite.country}
                rank={data.playerWhite.rank}
                name={`${data.playerWhite.lastName} ${data.playerWhite.firstName}`}
              />
            );
          }
        }
      },
    },
    {
      title: t('round'),
      dataIndex: "type",
      render: (text: "blitz" | "rapid" | "classic", data) =>
        `${t(text)} ${data.startingTime / 60} + ${data.additionalTime}`,
    },
    {
      title: t('result'),
      dataIndex: "result",
      render: (value, data) => {
        if (data.winner == id) {
          return "1 - 0";
        } else {
          return "0 - 1";
        }
      },
    },
    {
      title: t('dateAndTime'),
      dataIndex: "created",
      render: (value) => new Date(value).toLocaleString(),
    },
  ];

  const tableOptions: TableProps<any> = {
    pagination: false,
    columns,
  };

  return (
    <div className="history-profile">
      {innerWidth && data ? (
        innerWidth > 840 ? (
          <Table
            onRow={(record) => {
              return {
                onClick: () => {
                  history.push(`/game/${record.gameId}`);
                },
              };
            }}
            {...tableOptions}
            dataSource={data}
          />
        ) : (
          data.map((el) => <MobileHistory data={el} id={id} />)
        )
      ) : (
        <EmptyGames />
      )}
    </div>
  );
};

export default HistoryProfile;
