import React from 'react';

const UserSvg = () => {
  return (
    <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.86013 9.84448C7.86013 4.77562 11.9522 0.666504 16.9999 0.666504C22.0477 0.666504 26.1397 4.77562 26.1397 9.84448C26.1397 14.9133 22.0477 19.0225 16.9999 19.0225C11.9522 19.0225 7.86013 14.9133 7.86013 9.84448ZM16.9999 3.90579C13.7337 3.90579 11.0859 6.56463 11.0859 9.84448C11.0859 13.1243 13.7337 15.7832 16.9999 15.7832C20.2661 15.7832 22.9139 13.1243 22.9139 9.84448C22.9139 6.56463 20.2661 3.90579 16.9999 3.90579Z" fill="#555770"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.39777 25.501C5.72542 25.501 3.55906 27.6764 3.55906 30.36V32.9261C3.55906 32.9651 3.58726 32.9985 3.62566 33.0048C12.4832 34.4569 21.5166 34.4569 30.3742 33.0048C30.4126 32.9985 30.4408 32.9651 30.4408 32.9261V30.3599C30.4408 27.6764 28.2744 25.501 25.6021 25.501H24.869C24.8123 25.501 24.756 25.51 24.7021 25.5277L22.8408 26.138C19.0455 27.3825 14.9543 27.3825 11.159 26.138L9.2977 25.5277C9.24382 25.51 9.1875 25.501 9.13082 25.501H8.39777ZM0.333252 30.36C0.333252 25.8874 3.94386 22.2617 8.39777 22.2617H9.13082C9.52758 22.2617 9.92184 22.3247 10.299 22.4484L12.1603 23.0587C15.305 24.0899 18.6948 24.0899 21.8395 23.0587L23.7008 22.4484C24.078 22.3247 24.4723 22.2617 24.869 22.2617H25.6021C30.056 22.2617 33.6666 25.8874 33.6666 30.3599V32.9261C33.6666 34.5527 32.4926 35.9396 30.894 36.2017C21.6922 37.7103 12.3077 37.7103 3.10588 36.2017C1.5072 35.9396 0.333252 34.5527 0.333252 32.9261V30.36Z" fill="#555770"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.86013 9.84448C7.86013 4.77562 11.9522 0.666504 16.9999 0.666504C22.0477 0.666504 26.1397 4.77562 26.1397 9.84448C26.1397 14.9133 22.0477 19.0225 16.9999 19.0225C11.9522 19.0225 7.86013 14.9133 7.86013 9.84448ZM16.9999 3.90579C13.7337 3.90579 11.0859 6.56463 11.0859 9.84448C11.0859 13.1243 13.7337 15.7832 16.9999 15.7832C20.2661 15.7832 22.9139 13.1243 22.9139 9.84448C22.9139 6.56463 20.2661 3.90579 16.9999 3.90579Z" stroke="white" strokeWidth="0.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.39777 25.501C5.72542 25.501 3.55906 27.6764 3.55906 30.36V32.9261C3.55906 32.9651 3.58726 32.9985 3.62566 33.0048C12.4832 34.4569 21.5166 34.4569 30.3742 33.0048C30.4126 32.9985 30.4408 32.9651 30.4408 32.9261V30.3599C30.4408 27.6764 28.2744 25.501 25.6021 25.501H24.869C24.8123 25.501 24.756 25.51 24.7021 25.5277L22.8408 26.138C19.0455 27.3825 14.9543 27.3825 11.159 26.138L9.2977 25.5277C9.24382 25.51 9.1875 25.501 9.13082 25.501H8.39777ZM0.333252 30.36C0.333252 25.8874 3.94386 22.2617 8.39777 22.2617H9.13082C9.52758 22.2617 9.92184 22.3247 10.299 22.4484L12.1603 23.0587C15.305 24.0899 18.6948 24.0899 21.8395 23.0587L23.7008 22.4484C24.078 22.3247 24.4723 22.2617 24.869 22.2617H25.6021C30.056 22.2617 33.6666 25.8874 33.6666 30.3599V32.9261C33.6666 34.5527 32.4926 35.9396 30.894 36.2017C21.6922 37.7103 12.3077 37.7103 3.10588 36.2017C1.5072 35.9396 0.333252 34.5527 0.333252 32.9261V30.36Z" stroke="white" strokeWidth="0.4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default UserSvg;