import React from "react";
import DashedInfo from "../dashedInfo/DashedInfo";
import {IPropsInfo} from './types';


const Info = ({infos}: IPropsInfo): JSX.Element => {
  return (
    <>
      {infos.map((info) => (
        <DashedInfo {...info} />
      ))}
    </>
  );
};

export default Info;
