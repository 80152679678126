import $api from '../http';
import {AxiosResponse} from 'axios';
import {AuthResponse} from '../types/Response/AuthResponse';

export default class AuthService {
  static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/login', {email, password});
  }

  static async registration(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/registration', {email, password});
  }

  static async logout(): Promise<void> {
    await $api.get<AuthResponse>('/auth/logout');
  }

  static async remindPassword(email: string): Promise<void> {
    await $api.post<AuthResponse>('/auth/remindPassword', {email});
  }
}