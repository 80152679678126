import { createContext } from "react";
import ThemeStore from "../store/theme.store";
import themeStore from "../store/theme.store";

interface IStore {
  themeStore: typeof ThemeStore;
}

export const ThemeContext = createContext<IStore>({
  themeStore,
});

const ThemeProvider = (props: { children: any }) => {
  return (
    <ThemeContext.Provider value={{ themeStore }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
