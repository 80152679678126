import { t } from "i18next";
import React, { FC, useEffect, useState } from "react";
import { COLOR } from "../../../../types/models/game/IGo";
import Board from "../goBoard/GoBoard";
import "./BoardIntersection.scss";

interface IBoardIntersectionProps {
  row: number;
  col: number;
  color: COLOR;
  board: Board;
  onPlay: Function;
}

const BoardIntersection: FC<IBoardIntersectionProps> = ({
  color,
  board,
  onPlay,
  row,
  col,
}) => {
  // let style = {
  //   top: row * 30 + 20,
  //   left: col * 30 + 20,
  // };
  let style;
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);

  useEffect(()=>{
    const handleResize = ()=>{setInnerWidth(window.innerWidth)}
    window.addEventListener('resize', handleResize);
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[]);
  if(innerWidth < 460) style = {
    top: row * 20 + 20,
    left: col * 20 + 20,
    width: 20,
    height: 20
  }
  else style = {
    top: row * 30 + 20,
    left: col * 30 + 20,
    width: 30,
    height: 30
  }

  let classes = "intersection";

  if (color != COLOR.empty)
    classes += color == COLOR.black ? " black" : " white";

  const handleClick = () => {
    if (board.currentColor.toString() !== board.playerColor.toString()) return;
    if (board.play(row, col)) onPlay({i:row,j:col});
  };

  return (
    style && <div onClick={handleClick} className={classes} style={style}></div>
  );
};

export default BoardIntersection;
