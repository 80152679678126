import React from 'react';

const SadEmoji = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7929 24.6016C11.281 21.6379 13.8612 19.3366 16.9993 19.3366C20.1373 19.3366 22.7175 21.603 23.2057 24.6016C23.2754 25.1246 22.857 25.6127 22.334 25.6127C21.8807 25.6127 21.532 25.2989 21.4623 24.8805C21.1136 22.7187 19.2656 21.08 16.9993 21.08C14.7677 21.08 12.8849 22.7187 12.5362 24.8805C12.4665 25.2989 12.0829 25.6127 11.6645 25.6127C11.1415 25.6127 10.7231 25.1246 10.7929 24.6016Z" fill="#8F90A6"/>
      <path d="M12.1184 13.27C13.4857 13.27 14.594 12.1616 14.594 10.7944C14.594 9.42714 13.4857 8.31878 12.1184 8.31878C10.7512 8.31878 9.64285 9.42714 9.64285 10.7944C9.64285 12.1616 10.7512 13.27 12.1184 13.27Z" fill="#8F90A6"/>
      <path d="M24.3562 10.7944C24.3562 12.1616 23.2479 13.27 21.8807 13.27C20.5134 13.27 19.4051 12.1616 19.4051 10.7944C19.4051 9.42714 20.5134 8.31878 21.8807 8.31878C23.2479 8.31878 24.3562 9.42714 24.3562 10.7944Z" fill="#8F90A6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.333496 17.0007C0.333496 7.79563 7.79514 0.333984 17.0002 0.333984C26.2052 0.333984 33.6668 7.79563 33.6668 17.0007C33.6668 26.1708 26.2052 33.6673 17.0002 33.6673C7.83001 33.6673 0.333496 26.2057 0.333496 17.0007ZM2.07687 17.0007C2.07687 25.2294 8.77143 31.9239 17.0002 31.9239C25.2289 31.9239 31.9235 25.2294 31.9235 17.0007C31.9235 8.77192 25.2289 2.07736 17.0002 2.07736C8.77143 2.07736 2.07687 8.77192 2.07687 17.0007Z" fill="#8F90A6"/>
    </svg>
  );
};

export default SadEmoji;