import React, { useEffect, useState } from "react";
import { Table, TableProps } from "antd";
import history from "../../history";
import EmptyGames from "../profile/components/historyProfile/component/EmptyGames";
import { ColumnsType } from "antd/es/table";
import PlayerCardProps from "../../types/props/playerCardProps";
import PlayerInfoCard from "../../components/playerInfoCard/PlayerInfoCard";
import gameType from "../../models/vocabluary";
import { RouteName } from "../../routers";
import userStore from "../../store/user.store";
import {observer} from 'mobx-react-lite';
import {MobileHistory} from '../profile/components/historyProfile/HistoryProfile';
import { useTranslation } from "react-i18next";

const Archive = () => {
  const [innerWidth, setInnerWidth] = useState<number>();
  const { t } = useTranslation();
  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [innerWidth]);

  useEffect(() => {
    userStore.getAdminGameList(0);

    return () => userStore.clearGameList();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: t('competitor'),
      dataIndex: "opponent",
      render: (opponentObject: PlayerCardProps, data) => {
        if (data.playerWhite && data.playerBlack) {
          return (
            <>
              <PlayerInfoCard
                photo={data.playerBlack.photo}
                country={data.playerBlack.country}
                rank={data.playerBlack.rank}
                name={`${data.playerBlack.lastName} ${data.playerBlack.firstName}`}
              />
              <PlayerInfoCard
                photo={data.playerWhite.photo}
                country={data.playerWhite.country}
                rank={data.playerWhite.rank}
                name={`${data.playerWhite.lastName} ${data.playerWhite.firstName}`}
              />
            </>
          );
        }
      },
    },
    {
      title: t('round'),
      dataIndex: "type",
      render: (text: "blitz" | "rapid" | "classic", data) =>
        `${gameType[text]} ${data.startingTime / 60} + ${data.additionalTime}`,
    },
    {
      title: t('result'),
      dataIndex: "result",
      render: (value, data) => {
        // if (data.winner == id) {
        //   return "1 - 0";
        // } else {
          return "0 - 1";
        // }
      },
    },
    {
      title: t('dateAndTime'),
      dataIndex: "created",
      render: (value) => new Date(value).toLocaleString(),
    },
  ];

  const tableOptions: TableProps<any> = {
    pagination: { position: ['bottomRight'], total: userStore.adminGameList?.count, pageSize: 10 },
    columns,

  };

  return (
    <div className="container history-profile">
      {innerWidth && userStore.adminGameList ? (
        innerWidth > 840 ? (
          <Table
            onRow={(record) => {
              return {
                onClick: () => {
                  history.push(RouteName.GAME.replace(":gameId", record.gameId));
                },
              };
            }}
            {...tableOptions}
            dataSource={userStore.adminGameList}
          />
        ): (
          userStore.adminGameList.map((el: any) => <MobileHistory data={el} />)
        )
      ) : (
        <EmptyGames />
      )}
    </div>
  );
};

export default observer(Archive);
