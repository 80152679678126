import {action, makeAutoObservable} from 'mobx';

class MessageStore {
  isVisible: boolean = false;
  color: string = '';
  onClick: any = () => {};
  message: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  openMessage(color: string, onClick: any, message: string) {
    this.color = color;
    this.message = message;
    this.onClick = onClick;
    this.isVisible = true;

    setTimeout(() => {
      this.color = '';
      this.message = '';
      this.onClick = () => {};
      this.isVisible = false;
    }, 3000);
  }
}

export default new MessageStore();