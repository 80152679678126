import React from "react";
import { Table, TableColumnsType } from "antd";
import PlayerInfoCard from "../playerInfoCard/PlayerInfoCard";
import { calculateProc } from "../ratingCard/RatingCard";
import ArrowSvg from "../../svg/ArrowSvg";
import history from "../../history";
import { useTranslation } from "react-i18next";

export interface ITableRowForRatingTable {
  name: string;
  country: string;
  photo: string | undefined;
  rank: string | undefined;
  ratingElo: number;
  ratingPlaymaker: number;

  draw: number;
  gamesCount: number;
  lose: number;
  wins: number;
  _id: string;
}

interface IProps {
  data: ITableRowForRatingTable[];
}

const RatingTable = ({ data }: IProps) => {
  const { t } = useTranslation();
  const columnsData: TableColumnsType<ITableRowForRatingTable> = [
    {
      title: t('player'),
      dataIndex: "name",
      render: (value, record) => (
        <PlayerInfoCard
          photo={record.photo}
          country={record.country}
          name={record.name}
          rank={record.rank}
        />
      ),
    },
    {
      title: t('rating'),
      dataIndex: "ratingPlaymaker",
      render: (value) => (
        <span
          style={{
            color: "#8797F5",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          {new Intl.NumberFormat("ru").format(value.toFixed(2))}
        </span>
      ),
    },
    {
      title: t('ResultWin'),
      dataIndex: "wins",
      render: (value, record) => {
        return (
          <>
            {value}
            <span
              style={{
                fontWeight: 700,
                fontSize: "9px",
                lineHeight: "12px",
                verticalAlign: "top",
                marginLeft: "4px",
                color: "#8F90A6",
              }}
            >
              {calculateProc(record.wins, record.gamesCount)}%
            </span>
          </>
        );
      },
    },
    {
      title: t('ResultLose'),
      dataIndex: "lose",
      render: (value, record) => {
        return (
          <>
            {value}
            <span
              style={{
                fontWeight: 700,
                fontSize: "9px",
                lineHeight: "12px",
                verticalAlign: "top",
                marginLeft: "4px",
                color: "#8F90A6",
              }}
            >
              {calculateProc(record.lose, record.gamesCount)}%
            </span>
          </>
        );
      },
    },
    {
      title: t('ResultDraw'),
      dataIndex: "draw",
    },
    {
      title: "",
      dataIndex: "_id",
      render: (value) => (
        <div
          className="d-flex align-items-center justify-center"
          style={{
            border: 0,
            borderRadius: "50%",
            background: "#555770",
            width: 24,
            height: 24,
            cursor: "pointer",
            fontSize: "9px",
          }}
          onClick={() => history.push(`/profile/${value}`)}
        >
          <ArrowSvg height={6} width={8} />
        </div>
      ),
    },
  ];

  return <Table pagination={false} columns={columnsData} dataSource={data} />;
};

export default RatingTable;
