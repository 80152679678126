import React, {useEffect, useState} from 'react';
import FilledButton from '../../../components/buttons/filledButton/FilledButton';
import history from '../../../history';

import './contests.scss';
import {observer} from 'mobx-react-lite';
import img from '../../../assets/images/bannerContest.png';
import FilterRow from '../../../components/filterRow/FilterRow';
import {labelStatus} from '../../contest/components/label/ContestCardStatusLabel';
import contestStore from '../../../store/contest.store';
import {Spin} from 'antd';
import ContestCard from '../../contest/components/card/ContestCard';
import { useTranslation } from 'react-i18next';

const AdminContestsPage = () => {
  const [filter, setFilter] = useState<labelStatus | "">("");


  const closePageHandler = () => {
    contestStore.clearContestList();
  };

  const initPageHandler = () => {
    contestStore.getContestList(filter).then();

    return closePageHandler;
  };

  useEffect(initPageHandler, [filter]);

  const onClick = (e: any) => {
    setFilter(e.currentTarget.dataset.value);
  };

  const { t } = useTranslation();
  return (
    <div className='admin-contests container'>
      <div className="d-flex justify-between align-items-center">
        <h1 className='title'>{ t('manage') }</h1>
        <FilledButton
          handleOnClick={() => history.push('/admin/contests/create')}
          className='admin-contests__button-create'
          title={ t('createCompetition') } />
      </div>

      <div>
        <FilterRow>
          <div
            onClick={onClick}
            className={`${filter === "" ? "active" : ""}`}
            data-value={""}
          >
            { t('all') }
          </div>
          <div
            onClick={onClick}
            className={`${filter === labelStatus.active ? "active" : ""}`}
            data-value={labelStatus.active}
          >
            { t('statusActive') }
          </div>
          <div
            onClick={onClick}
            className={`${filter === labelStatus.await ? "active" : ""}`}
            data-value={labelStatus.await}
          >
            { t('statusWaiting') }
          </div>
          <div
            onClick={onClick}
            className={`${filter === labelStatus.check ? "active" : ""}`}
            data-value={labelStatus.check}
          >
            { t('statusReviewing') }
          </div>
          <div
            onClick={onClick}
            className={`${filter === labelStatus.closed ? "active" : ""}`}
            data-value={labelStatus.closed}
          >
            { t('statusComplete') }
          </div>
        </FilterRow>
        {contestStore.isLoading ? (
          <Spin spinning={true} />
        ) : (
          <>
            {contestStore.contestsList?.map((contest) => (
              <ContestCard {...contest} key={contest._id} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default observer(AdminContestsPage);