import { makeAutoObservable } from "mobx";
import LanguageService, {ICreateLangBody, IUpdateLangBody} from '../services/Language.service';

export type TLang = "ru" | "en";

interface ILanguageStore {
  lang: TLang;
  languages: TLang[];
  translation: any;

  updateLang: (body: IUpdateLangBody) => Promise<void>;
  createLang: (body: ICreateLangBody) => Promise<void>;
  getAccessLang: () => Promise<void>;
  getTranslation: () => Promise<void>;
}

class LanguageStore implements ILanguageStore {

  get lang(): TLang {
    return this._lang;
  }
  set lang(value: TLang) {
    this._lang = value;
  }
  private _lang: TLang = "ru";

  get languages(): TLang[] {
    return this._languages;
  }
  set languages(value: TLang[]) {
    this._languages = value;
  }
  private _languages: TLang[] = [];

  get translation(): any {
    return this._translation;
  }

  set translation(value: any) {
    this._translation = value;
  }
  private _translation: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  async updateLang(body: IUpdateLangBody): Promise<void> {
    try {
      await LanguageService.update(body);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async createLang(body: ICreateLangBody): Promise<void> {
    try {
      await LanguageService.createLang(body);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async getAccessLang(): Promise<void> {
    try {
      this._languages = (await LanguageService.getAccessLanguages()).data;
    } catch (e) {
      console.log(e);
    } finally {

    }
  }

  async getTranslation(): Promise<void> {
    try {
      this._translation = (await LanguageService.getTranslation()).data;
      console.log(this._translation);
    } catch (e) {
      console.log(e);
    } finally {

    }
  }
}

export default new LanguageStore();
