import {makeAutoObservable} from 'mobx';
import {GameType} from '../types/props/miniTvProps';
import socketStore from './socket.store';
import SocketService from '../services/Socket.service';

export interface IGamePlayer {
  _id: string;
  email: string;

  photo?: string;
  firstName?: string;
  lastName?: string;
  rank?: string;
  country?: string;
}

export interface IGame {
  _id: string;
  gameId: string;
  playerWhite: IGamePlayer;
  playerBlack: IGamePlayer;
  type: 'blitz'|'rapid'|'classic';
  startingTime: string;
  additionalTime: string;
}

interface IGameStore {
  games: IGame[];
  getOnlineGames: () => void;
}

class GamesStore implements IGameStore{
  games: IGame[] = [];
  constructor() { makeAutoObservable(this) };

  getOnlineGames(): void {
    SocketService.getOnlineGames();
  }

  setGames(newGames: IGame[]) {
    this.games = newGames;
  }
}

export default new GamesStore();