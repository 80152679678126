import PlayerCardProps from './playerCardProps';
export enum GameType {
  'blitz',
  'rapid',
  'classic'
}
export default interface MiniTvProps {
  type: GameType|'blitz'|'rapid'|'classic';
  time: string;
  userWhite: PlayerCardProps;
  userBlack: PlayerCardProps;
  gameId?: string;
}