import React from "react";
import PriceSvg from "../../../../svg/PriceSvg";
import ClassicSvg from "../../../../svg/ClassicSvg";
import "./gameResult.scss";
import MiniRatingCard from "../../../../components/miniRatingCard/MiniRatingCard";
import MiniTVCard from "../../../../components/miniTv/miniTVCard/MiniTVCard";
import PlayerInfoCard from "../../../../components/playerInfoCard/PlayerInfoCard";
// import { IGame } from "../../../../types/models/game/IGame";
import { observer } from "mobx-react-lite";
import gameType from "../../../../models/vocabluary";
import { RatingType } from "../../../createGame/components/gameSettings/GameSettings";
import { Calendar } from "antd";
import CalendarSvg from "../../../../svg/CalendarSvg";
import { useTranslation } from "react-i18next";
import { IGame } from "../../../../types/models/game/IGo";

const testData = [
  {
    user: { name: "Михаил Суворов", rank: "Мастер ФИДЕ", country: "ru" },
    rating: "59 873",
  },
  {
    rating: "38 648",
    user: { rank: "Гроссмейстер", name: "Charlie Rosser", country: "sm" },
  },
];

interface IProps {
  game: IGame;
}

const GameResult = ({ game }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="game-result">
      <h4 className="game-result__title">{t('gameResult')}</h4>
      <div className="game-result__players">
        <div className="game-result__players-black d-flex justify-between align-items-center">
          <PlayerInfoCard
            className={"player-info-card-mini"}
            name={
              game.playerWhite.lastName
                ? `${game.playerWhite.lastName} ${game.playerWhite.firstName}`
                : "unknown"
            }
            country={game.playerWhite.country || "ru"}
            rank={game.playerWhite.rank}
          />
          {Math.floor(+game.playerWhite.ratingElo!)}
        </div>
        <div className="game-result__players-white d-flex justify-between align-items-center">
          <PlayerInfoCard
            className={"player-info-card-mini"}
            name={
              game.playerBlack.lastName
                ? `${game.playerBlack.lastName} ${game.playerBlack.firstName}`
                : "unknown"
            }
            country={game.playerBlack.country || "ru"}
            rank={game.playerBlack.rank}
          />
          {Math.floor(+game.playerBlack.ratingElo!)}
        </div>
      </div>
      <div className="game-result__info">
        <div className="info-result">
          <PriceSvg />
          {game.draw ? (
            <>
              <h5>{t('resultDraw')}</h5>
            </>
          ) : game.winner === game.playerWhite._id ? (
            <>
              {/* <h5>{t('match')}</h5> */}
              <p>{t('whiteWin')}</p>
            </>
          ) : (
            <>
              {/* <h5>{t('match')}</h5> */}
              <p>{t('blackWin')}</p>
            </>
          )}
          <p className="players-result">
            {/*<span>1</span> – <span>0</span>*/}
          </p>
        </div>
      </div>
      <div className="game-info">
        <div className="game-info__static">
          <ClassicSvg />
          {t(game.type)} {game.startingTime / 60} + {game.additionalTime}
        </div>
        <div className="game-info__data">{t(game.ratingType)}</div>
      </div>
      <div className="game-info">
        <div className="game-info__static">
          <ClassicSvg />
          {t('score')}
        </div>
        <div className="game-info__data">{game.score}</div>
      </div>
      <div className="game-info">
        <div className="game-info__static">
          <ClassicSvg />
          {t('komi')}
        </div>
        <div className="game-info__data">{game.komi}</div>
      </div>
      <div className="game-info">
        <div className="game-info__static">
          <ClassicSvg />
          {t("ruleOfGame")}
        </div>
        <div className="game-info__data">{t(game.rules)}</div>
      </div>
      <div className="game-info">
        <div className="game-info__static">
          <CalendarSvg />
            {t('date')}
        </div>
        <div className="game-info__data">
          {new Date(game.created).toLocaleDateString()}
        </div>
      </div>
    </div>
  );
};

export default observer(GameResult);
