import {action, makeAutoObservable} from 'mobx';
import history from '../history';
import {COLOR, IGame} from '../types/models/game/IGo';
import GoService from '../services/game/Go.service';
import { IPublicGame } from '../types/models/game/IPublicGame';
import { ICreateGame } from '../views/createGame/components/gameSettings/GameSettings';

class GoStore {
  current_color = COLOR.black;
  game: IGame|null = null;
  isLoading: boolean = false;
  onlineGames: IPublicGame[]|null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setOnlineGames(games: IPublicGame[]|null) {
    this.onlineGames = games;
  }

  async createGame(data?: ICreateGame) {
    try {
      await GoService.createGame(data);
    } catch (e: any) {
      console.log(e);
    }
  }

  async getGame(gameId: string) {
    try {
      const game = (await GoService.getGame(gameId)).data;
      if (!game) {
        history.push("/");
        throw new Error("Игры не существует");
      }
      this.current_color = game.color;
      this.game = game;
    } catch (e) {
      console.log(e);
    }
  }

  async getGameHistory(gameId: string) {
    this.isLoading = true;
    try {
      return (await GoService.getGameHistory(gameId)).data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async getDeadPieces(gameId: string) {
    this.isLoading = true;
    try {
      return (await GoService.getDeadPiece(gameId)).data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async finishGame(gameId: string, color?: string) {
    try {
      await GoService.finishGame(gameId, color);
      this.current_color = COLOR.empty;
    } catch (e) {
      console.log(e);
    }
  }

  async closeGame() {
    try {
      await GoService.closeGame();
    } catch (e) {
      console.log(e);
    }
  }

  async getOnlineGames() {
    this.isLoading = true;
    try {
      this.setOnlineGames((await GoService.getOnlineGames()).data);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }


  @action setColor(color: COLOR) {
    this.current_color = color;
  }

  @action getColor() {
    return this.current_color;
  }

  @action clearGame() {
    this.game = null;
    this.current_color = COLOR.empty;
  }
}


export default new GoStore();