import "./regulationCard.scss";

import React from "react";
import ContestCardStatusLabel, {
  labelStatus,
} from "../../../contest/components/label/ContestCardStatusLabel";
import { IContest } from "../../../../models/IContest";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IProps extends IContest{
  labeled?: boolean;
}

const RegulationCard = ({
  name,
  dateStart,
  dateEnd,
  status,
  description,
  prize,
  labeled=true,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="regulation-card">
      <div className="d-flex align-items-center justify-between">
        <div className="regulation-card__title">{name}</div>
        {labeled && <ContestCardStatusLabel status={status || labelStatus.check} />}
      </div>
      <div className="regulation-card__description">{description}</div>
      <div className="d-flex flex-column">
        <div
          className="d-flex justify-between align-items-center regulation-card__bordered"
          style={{ paddingBottom: "8px" }}
        >
          <div className="regulation-card__sub-title trophy">{ t('sponsor') }</div>
          <div className="regulation-card__value">{prize}</div>
        </div>
        <div
          className="d-flex justify-between align-items-center"
          style={{ paddingTop: "8px" }}
        >
          <div className="regulation-card__sub-title date">{ t('validDate') }</div>
          <div className="regulation-card__value">
            {moment(dateStart).format("DD MMM YYYY")} -{" "}
            {moment(dateEnd).format("DD MMM YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegulationCard;
