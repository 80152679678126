import "../../../../components/auth/modal.scss";
import React from "react";
import {Modal, ModalProps} from 'antd';

interface IProps extends ModalProps{
  visible: boolean;
  onClose: any;
  onSubmit: any;
  title: string;
  text: string;
}

const GameModal = ({ text, onSubmit, title, onClose, visible, ...props }: IProps) => {
  return (
    <div>
      <Modal
        style={{ background: "rgb(48, 50, 73)" }}
        title={title}
        visible={visible}
        onCancel={onClose}
        onOk={onSubmit}
        {...props}
      >
        <div style={{ color: "white" }}> {text}</div>
      </Modal>
    </div>
  );
};

export default GameModal;
