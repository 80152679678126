import { makeAutoObservable } from "mobx";
import { IUser } from "../types/models/IUser";
import RatingService from "../services/Rating.service";
import { ERatingFilter } from "../views/ratings/Ratings";

export interface IContestRating {
  draw: number;
  gamesCount: number;
  lose: number;
  wins: number;
  ratingElo: number;
  ratingPlaymaker: number;
  user: IUser;
}

class RatingStore {
  isLoading: boolean = false;
  ratingList: IUser[] = [];

  contestRatingList: IContestRating[] = [];

  gameStatisticList: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getRatingList(skip = 0, limit = 5, filter = "ratingElo") {
    try {
      this.setIsLoading(true);
      const ratingList = await RatingService.fetchRatingList(
        skip,
        limit,
        filter
      );
      this.ratingList.push(...ratingList.data);
    } catch (e) {
      console.log(e);
    } finally {
      this.setIsLoading(false);
    }
  }

  async getGameStatisticList(skip = 0, limit = 5, ratingType?: string) {
    try {
      this.setIsLoading(true);
      const gameStatistic = await RatingService.fetchGameStatisticList(
        skip,
        limit,
        ratingType
      );
      this.gameStatisticList.push(...(gameStatistic.data as []));
    } catch (e) {
      console.log(e);
    } finally {
      this.setIsLoading(false);
    }
  }
  async getDiffStatistic(
    skip = 0,
    limit = 5,
    dateStart: string,
    dateEnd: string
  ) {
    try {
      this.setIsLoading(true);
      const diffStatistic = await RatingService.getDiffStatistic(
        skip,
        limit,
        dateStart,
        dateEnd
      );
      this.gameStatisticList.push(...(diffStatistic.data as []));
    } catch (e) {
      console.log(e);
    } finally {
      this.setIsLoading(false);
    }
  }

  async getContestRating(skip = 0, limit = 5, id: string) {
    try {
      this.setIsLoading(true);
      const contestRatingList = await RatingService.getContestRating(
        skip,
        limit,
        id
      );
      this.contestRatingList.push(...(contestRatingList.data as []));
    } catch (e) {
      console.log(e);
    } finally {
      this.setIsLoading(false);
    }
  }

  private setIsLoading(bool: boolean) {
    this.isLoading = bool;
  }

  clearRatingList() {
    this.ratingList = [];
  }

  clearGameStatisticList() {
    this.gameStatisticList = [];
  }

  clearContestRatingList() {
    this.contestRatingList = [];
  }
}

export default new RatingStore();
