import {IContest, IContestDetails} from "../../../../models/IContest";
import ContestCardStatusLabel, {
  labelStatus,
} from "../label/ContestCardStatusLabel";
import history from "../../../../history";
import ArrowSvg from "../../../../svg/ArrowSvg";
import React, { useEffect, useState } from "react";

import "./contestCard.scss";
import moment from "moment";
import contestStore from "../../../../store/contest.store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import i18next from "i18next";

const ContestCard = (props: IContest) => {
  const [detail, setDetails] = useState<IContestDetails | undefined>(
      undefined
  );
  useEffect(
      () =>
          setDetails(
              contestStore.contest?.details?.filter(
                  (item) => item.LANGUAGE === i18next.language
              )[0]
          ),
      [i18next.language]
  );
  const mobileContestCard = (
      <div className="mobile-contest-card">
        <div className="card d-flex justify-between" style={{ margin: "0.5rem" }}>
          <div className="data">
            <div className="name" style={{ margin: "0 0 0.5rem 0" }}>
              {detail?.name || props.name}
              <ContestCardStatusLabel
                  status={props.status || labelStatus.check}
              />
            </div>
            <div className="date d-flex">
              {`${moment(props.dateStart).format("DD MMM YY")} 
            - ${moment(props.dateEnd).format("DD MMM YY")}`}
            </div>
            <div className="users d-flex" style={{ display: "flex" }}>
              {props.userCount || 0}
            </div>
            <div className="prize d-flex">{props.prize} ₽</div>
          </div>
          <Button
              type="primary"
              shape="circle"
              icon={<ArrowRightOutlined />}
              onClick={() => history.push(`/contests/${props._id}`)}
          />
        </div>
      </div>
  );

  return (
      <>
        <div className="contest-card">
          <div className="d-flex align-items-center">
            <div className="name d-flex align-items-center">
              {detail?.name || props.name}
              <ContestCardStatusLabel
                  status={props.status || labelStatus.check}
              />
            </div>
            <div className="date d-flex align-items-center">
              {`${moment(props.dateStart).format("DD MMM YY")} 
              - ${moment(props.dateEnd).format("DD MMM YY")}`}
            </div>
            <div
                className="users d-flex align-items-center"
                style={{ display: "flex" }}
            >
              {props.userCount || 0}
            </div>
            <div className="prize d-flex align-items-center">{props.prize} ₽</div>
            <div
                onClick={() => history.push(`/contests/${props._id}`)}
                className="redirect d-flex align-items-center"
            >
              <ArrowSvg />
            </div>
          </div>
        </div>
        <div className="mobile-contest-card">{mobileContestCard}</div>
      </>
  );
};

export default observer(ContestCard);
