import {notification} from 'antd';

const openNotificationWithIcon = (type: any, message: string, description: string) => {
  // @ts-ignore
  notification[type]({
    message,
    description
  });
};

export default openNotificationWithIcon;