import "./competitionsRegulations.scss";

import React, { useEffect } from "react";
import Title from "../../components/title/Title";
import contestStore from "../../store/contest.store";
import { Spin } from "antd";
import RegulationCard from "./components/regulationCard/RegulationCard";
import { observer } from "mobx-react-lite";
import history from '../../history';
import {RouteName} from '../../routers';
import { useTranslation } from "react-i18next";

const CompetitionRegulations = () => {
  const { t } = useTranslation();
  useEffect(() => {
    contestStore.getContestList();
  }, []);

  return (
    <div className="competition-regulations">
      <div className="container">
        <Title title={ t('regulations')} />
        <Spin spinning={contestStore.isLoading}>
          {contestStore.contestsList?.length &&
            contestStore.contestsList.map((contest) => (
              <div className="card" onClick={() => history.push(RouteName.COMPETITION_REGULATION.replace(':id', contest._id!))}>
                <RegulationCard {...contest} />
              </div>
            ))}
        </Spin>
      </div>
    </div>
  );
};

export default observer(CompetitionRegulations);
