import "./language.scss";

import React, { useEffect, useState } from "react";
import SearchComponent from "./components/searchComponent/SearchComponent";
import EditorComponent from "./components/editorComponent/EditorComponent";
import languageStore from "../../../store/language.store";
import {observer} from 'mobx-react-lite';

function Language() {
  const [currentKey, setCurrentKey] = useState<[string, string]>(["", ""]);

  useEffect(() => {
    languageStore.getTranslation();
    return () => {
      languageStore.translation = [];
    };
  }, []);

  useEffect(() => {
    languageStore.getAccessLang();
    return () => {
      languageStore.languages = [];
    };
  }, []);

  return (
    <div className="admin-lang">
      <div className="fluid-container  w-100">
        <div className="card d-flex md--flex-column">
          { languageStore.translation.ru && !!languageStore.languages.length &&
            <>
              <SearchComponent setKey={setCurrentKey} />
              <EditorComponent currentKey={currentKey} />
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default observer(Language);
