import { AxiosResponse } from 'axios';
import $api from '../http';
import {IPrivateUser, IUser, IUserCount} from '../types/models/IUser';
import {IRating} from '../types/models/IRating';

type AdminGameList = {
  result: any;
}

export default class UserService {
  static async fetchUsers(): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>('/users/list');
  }

  static async fetchUser(id: string): Promise<AxiosResponse<IUser>> {
    return $api.get<IUser>(`/users/${id}`);
  }

  static async fetchMe(): Promise<AxiosResponse<IPrivateUser>> {
    return $api.get<IPrivateUser>(`/users/me`);
  }

  static async fetchRating(type: 'ratingElo'|'ratingPlaymaker'): Promise<AxiosResponse<IRating[]>> {
    return $api.get<IRating[]>(`/users/rating?t=${type}`);
  }

  static async fetchUsersCount(): Promise<AxiosResponse> {
    return $api.get(`/users/count`);
  }

  static async updateMe(body: any) {
    return $api.patch(`/users/me`, body);
  }

  static async deleteUser(id: string) {
    return $api.delete(`/users/${id}`);
  }

  static async updateUser(id: string, body: any) {
    return $api.patch(`/users/${id}`, body);
  }

  static async updatePhoto(form: FormData) {
    return $api.patch(`/users/photo/`, form);
  }

  static async updatePassport(form: FormData) {
    return $api.patch(`/users/passport/`, form);
  }

  static async getGameList(userId: string) {
    return $api.get(`/users/list/${userId}`);
  }

  static async getAdminGameList(skip: number): Promise<AxiosResponse<AdminGameList>> {
    return $api.get<AdminGameList>(`users/list/admin?skip=${skip}`);
  }

  static async reActivate(email: string) {
    return  $api.get(`/auth/reActivate/${email}`);
  }
}