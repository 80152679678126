import "./profileEdit.scss";
import React, {useEffect} from 'react';
import history from "../../history";
import AvatarUploader from './components/avatarUploader';
import FormProfileEdit from './components/formProfileEdit';
import {observer} from 'mobx-react-lite';
import userStore from '../../store/user.store';
import { useTranslation } from "react-i18next";

const ProfileEdit = () => {
  const { t } = useTranslation();
  useEffect(() => {
    userStore.getMe();
  }, []);

  return (
    <div className="profile-editing">
      <div className="container">
        <div className="back" onClick={() => history.goBack()}>
          { t('back') }
        </div>
        <div className="title">{ t('editProfile') }</div>
        <div className="card d-flex">
          <AvatarUploader />
          <FormProfileEdit />
        </div>
      </div>
    </div>
  );
};

export default observer(ProfileEdit);
