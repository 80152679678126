import "./ratingCardProfile.scss";

import React, { useContext } from "react";
import { IPropsRatingCardProfile } from "./types";
import { ThemeContext } from "../../../../providers/ThemeProvider";
import {observer} from 'mobx-react-lite';
import { useTranslation } from "react-i18next";

const RatingCard = ({ ratingType, value }: IPropsRatingCardProfile) => {
  const { themeStore } = useContext(ThemeContext);
  const formatter = new Intl.NumberFormat("ru");
  const { t } = useTranslation();
  return (
    <div
      className={`card ${
        themeStore.theme === "dark" ? "dark" : "light"
      } d-flex justify-center align-items-center flex-column rating-card-profile`}
    >
      <h5>{ratingType === "Рейтинг ЭЛО" ? t('ratingElo') : t('ratingPlaymaker')}</h5>
      <h2>{formatter.format(+Number(value).toFixed(2))}</h2>
    </div>
  );
};

export default observer(RatingCard);
