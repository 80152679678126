import { ITimer } from "../ITimer";
import { IUser } from "../IUser";

export enum COLOR {
    empty = "empty",
    black = "b",
    white = "w"
}

export enum EGameColor {
  black = "b",
  white = "w",
}

export interface IPosition {
  i: number;
  j: number;
}

export interface IIntersection{
    position : IPosition,
    color : COLOR
}

// export interface ICreateGame {
//     rating?: string;
//     rival?: string;
//     color?: string;
//     size?: number;
//     mainTime: number;
//     additionalTime: number;
//     ratingType?: string;
//     type?: "blitz" | "normal" | "slow";
//   }

export interface IGame {
  gameId: string;
  // board?: Array<COLOR>;
  color: COLOR;
  created: Date;
  startingTime: number;
  additionalTime: number;
  playerBlack: IUser;
  playerWhite: IUser;
  started: string;
  timer: ITimer | null;
  status: string;
  ratingType: "friendly" | "rate";
  size?: number;
  winner: string;
  draw: boolean;
  rating?: string;
  rival?: string;
  type: "blitz" | "normal" | "slow";
  speed: string;
  matchName?: string;
  isPrivate: boolean;
  isRengo: boolean;
  isRating: boolean;
  rules: string;
  timeControl: string;
  maxTime: string;
  stopOnWeekends: boolean;
  fora: boolean;
  komi: string;
  stopAnalyze: boolean;
  limitedLevel: boolean;
  score?: number;
}