import React, { FC, useEffect, useState } from "react";
import { EGameColor } from "../../../../types/models/game/IGo";
import { IUser } from "../../../../types/models/IUser";
import styled from "styled-components";

interface ITimerProps {
  playerColor: EGameColor;
  player: IUser;
  stones: number;
  time: number;
  moveColor: EGameColor;
  handleOnTimeout: Function;
  stopTiming: boolean;
}

type TTimerWrapperProps = {
  playerColor: EGameColor;
};

const TimerWrapper = styled.div<TTimerWrapperProps>`
  background: ${(props) =>
    props.playerColor == EGameColor.white
      ? "linear-gradient(180deg,#ccc 0,#eee 30%,#fff)"
      : "linear-gradient(180deg,#7d7e7d 0,#0e0e0e)"};
  border-radius: 8px;
  height: 25%;
  width: 100%;
`;

const TimerProfile = styled.div<TTimerWrapperProps>`
  color: ${(props) =>
    props.playerColor == EGameColor.white ? "#3070a7" : "#9dc6ff"};
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
`;

const Timer: FC<ITimerProps> = ({
  player,
  playerColor,
  stones,
  time,
  moveColor,
  handleOnTimeout,
  stopTiming,
}) => {
  const [timeToEnd, setTimeToEnd] = useState<number>(Date.now() + time * 1000);
  const [timeLeft, setTimeLeft] = useState<number>(timeToEnd - Date.now());

  
  useEffect(() => {
    if (moveColor == playerColor && !stopTiming) {
      setTimeout(() => {
        setTimeLeft(timeToEnd - Date.now());
      }, 250);
    }
  }, [timeLeft, moveColor,time]);

  useEffect(() => {
    setTimeToEnd(Date.now() + time * 1000);
    setTimeLeft( time * 1000)
  }, [moveColor,time,stopTiming]);

  if (timeLeft <= 0) {
    handleOnTimeout(playerColor);
  }

  return (
    <TimerWrapper playerColor={playerColor}>
      <TimerProfile playerColor={playerColor}>
        <div>{player.email}</div>
        <div>Камни: {stones}</div>
        <div>{timeConversion(timeLeft)}</div>
      </TimerProfile>
    </TimerWrapper>
  );
};

export default Timer;

function timeConversion(millisec: number): string {
  let seconds = +(millisec / 1000).toFixed(0);
  let minutes = Math.floor(seconds / 60).toString();
  let hours = "";

  if (+minutes > 59) {
    hours = Math.floor(+minutes / 60).toString();
    hours = +hours >= 10 ? hours : "0" + hours;
    minutes = (+minutes - +hours * 60).toString();
    minutes = +minutes >= 10 ? minutes : "0" + minutes;
  }

  seconds = Math.floor(seconds % 60);
  const stringSeconds = seconds >= 10 ? seconds : "0" + seconds;
  if (hours != "") {
    return hours + ":" + minutes + ":" + stringSeconds;
  }
  return minutes + ":" + stringSeconds;
}
