import './emptyGames.scss';
import React, {useContext} from 'react';

import empty from '../../../../../assets/images/empty.png';
import FilledButton from '../../../../../components/buttons/filledButton/FilledButton';
import history from '../../../../../history';
import {useParams} from 'react-router';
import {Context} from '../../../../../index';
import { useTranslation } from 'react-i18next';

const EmptyGames = () => {
  const {id} = useParams<{id: string}>();
  const {authStore} = useContext(Context);
  const { t } = useTranslation();
  return (
    <div className='empty-games d-flex justify-center flex-column align-items-center'>
      <img src={empty} alt="empty box"/>
      {
        authStore.user.id === id ?
          <>
            <h3>{t('hereWillSave')}</h3>
            <FilledButton handleOnClick={() => history.push('/create-game')} title={t('start')} /></>:
          <>
            <h3>{t('notOneGame')}</h3>
          </>
      }

    </div>
  );
};

export default EmptyGames;