import "./ratingCardWrapper.scss";

import React, { useEffect, useState } from "react";
import RatingCard from "../ratingCard/RatingCard";
import ratingStore from "../../store/rating.store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const RatingCardWrapper = (props: any) => {
  const [innerWidth, setInnerWidth] = useState<number>();
  const { t } = useTranslation();

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [innerWidth]);

  const initUseEffect = () => {
    if (props.dateStart) {
      ratingStore.getDiffStatistic(0, 5, props.dateStart, props.dateEnd);
    } else if (props.ratingType) {
      ratingStore.getGameStatisticList(0, 5, props.ratingType);
    } else {
      ratingStore.getGameStatisticList(0, 5);
    }
    return () => ratingStore.clearGameStatisticList();
  };

  useEffect(initUseEffect, [props.ratingType]);

  return (
    <div className="rating-card-wrapper">
      <div className="align-items-center columns">
        <div className="firstColumn">{t("player")}</div>
        <div className="secondColumn">{t("rating")}</div>
        {innerWidth && innerWidth > 630 && (
          <>
            <div className="thirdColumn">{t("resultWin")}</div>
            <div className="fourthColumn">{t("resultLose")}</div>
            <div className="fifthColumn">{t("resultDraw")}</div>
          </>
        )}
      </div>
      {!!ratingStore.gameStatisticList.length &&
        ratingStore.gameStatisticList.map((stat: any) => {
          return (
            <RatingCard
              ratingType={props.ratingType}
              key={stat._id}
              {...stat}
              contest={false}
            />
          );
        })}
    </div>
  );
};

export default observer(RatingCardWrapper);
