import React from 'react';
import Contests from '../../components/contests/Contests';
import FastStart from '../../components/fastStart/FastStart';
import './main.scss';
import MiniRating from '../../components/miniRating/MiniRating';
import MiniTv from '../../components/miniTv/MiniTv';
import Statistic from '../../components/statistics/Statistic';
import {observer} from 'mobx-react-lite';

const Main = () => {
  return (
    <div className={'main fluid-container'}>
      <div className={'d-flex justify-between main-info main-top'}>
        {/* <Contests /> */}
        <FastStart />
        <Statistic />
      </div>
      <div className={'d-flex justify-between main-info md-column w-1400'}>
        {/* <MiniRating /> */}
        <MiniTv  />
      </div>
    </div>
  );
};

export default observer(Main);