import { AxiosResponse } from 'axios';
import $api from '../http';
import {IUser} from '../types/models/IUser';

export default class RatingService {
  static async fetchRatingList(skip: number, limit: number, filter: string): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>(`/rating/list?s=${skip}&l=${limit}&f=${filter}`);
  }

  static async fetchGameStatisticList(skip: number, limit: number, ratingType?: string): Promise<AxiosResponse> {
    return $api.get(`/rating/list/gameStatistic?s=${skip}&l=${limit}&t=${ratingType}`);
  }

  static async getDiffStatistic(skip: number, limit: number, dateStart: string, dateEnd: string) {
    return $api.get(`/rating/list/diff?s=${skip}&l=${limit}&ds=${dateStart}&de=${dateEnd}`);
  }

  static async getContestRating(skip: number, limit: number, id: string) {
    return $api.get(`/rating/list/contest?s=${skip}&l=${limit}&c=${id}`);
  }
}