import "./formProfileEdit.scss";
import React, { useContext, useEffect, useState } from "react";
import FormCard from "./formCard";
import { DatePicker, Form, Input, Select, Upload, UploadProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import MaskedInput from "antd-mask-input";
import OutlineButton from "../../../../components/buttons/outlineButton/OutlineButton";
import FilledButton from "../../../../components/buttons/filledButton/FilledButton";
import countryCitiesStore from "../../../../store/countryCities.store";
import { observer } from "mobx-react-lite";
import { useForm } from "antd/es/form/Form";
import userStore from "../../../../store/user.store";
import openNotification from "../../../../utils/openNotification";
import moment from "moment";
import history from "../../../../history";
import ImgCrop from "antd-img-crop";
import { ThemeContext } from "../../../../providers/ThemeProvider";
import { useTranslation } from "react-i18next";

const TestData = {
  passport: null,
};

const FormProfileEdit = () => {
  const { t } = useTranslation();
  const { themeStore } = useContext(ThemeContext);

  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    countryCitiesStore.getCountryCityList();
  }, []);

  const [form] = useForm();

  const onFinish = (values: any) => {
    userStore
      .updateMe(values)
      .then(() =>
        openNotification("success", t("success"), t("successSavingData"))
      )
      .then(() =>
        userStore
          .getMe()
          .then(() => history.push(`/profile/${userStore.userData!._id}`))
      );
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    setFileList([...info.fileList]);
  };

  return (
    <div className="form--profile-edit d-flex flex-column">
      {userStore.userData && (
        <Form form={form} onFinish={onFinish} style={{ width: "100%" }}>
          <FormCard title={t("personalInfo")}>
            <div className="d-flex input-wrapper">
              <Form.Item
                name="firstName"
                initialValue={userStore.userData.firstName}
              >
                <Input placeholder={t("firstName")} />
              </Form.Item>
              <Form.Item
                name="lastName"
                initialValue={userStore.userData.lastName}
              >
                <Input placeholder={t("lastName")} />
              </Form.Item>
            </div>
            <div className="d-flex input-wrapper">
              <Form.Item
                name="birthday"
                initialValue={moment(userStore.userData.birthday)}
              >
                <DatePicker
                  format={"DD.MM.YYYY"}
                  showToday={false}
                  bordered={false}
                  suffixIcon={null}
                  placeholder={t("birthday")}
                />
              </Form.Item>

              <Form.Item
                name="experience"
                initialValue={userStore.userData.experience}
              >
                <Input placeholder={t("experience")} />
              </Form.Item>
            </div>
            {!!countryCitiesStore.countriesList.length && (
              <div className="d-flex input-wrapper">
                <Form.Item
                  name="country"
                  initialValue={userStore.userData.country?._id}
                >
                  {countryCitiesStore.countriesList.length && (
                    <Select
                      disabled={countryCitiesStore.isLoading}
                      onChange={(value) => {
                        countryCitiesStore.getCities(value);
                      }}
                      placeholder={t("country")}
                    >
                      {countryCitiesStore.countriesList.map((el, index) => (
                        <Select.Option value={el._id} key={index}>
                          {el.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item name="city" initialValue={userStore.userData.city}>
                  <Select
                    placeholder={t("city")}
                    disabled={
                      countryCitiesStore.isLoading ||
                      !countryCitiesStore.citiesList.length
                    }
                  >
                    {countryCitiesStore.citiesList.length &&
                      countryCitiesStore.citiesList.map((el, index) => (
                        <Select.Option key={index} value={el.name}>
                          {el.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            )}
          </FormCard>
          <FormCard title={t("AboutMyself")}>
            <div className="d-flex input-wrapper">
              <Form.Item
                name="aboutMe"
                initialValue={userStore.userData.aboutMe}
              >
                <TextArea placeholder={t("tellAboutyourself")} />
              </Form.Item>
            </div>
          </FormCard>
          <FormCard title={t("chessTitle")}>
            <div className="d-flex input-wrapper">
              <Form.Item name="rank" initialValue={userStore.userData.rank}>
                <Input placeholder={t("nameOfTitle")} />
              </Form.Item>
            </div>
          </FormCard>
          <FormCard title={t("contact")}>
            <div className="d-flex input-wrapper">
              <Form.Item name="phone" initialValue={userStore.userData.phone}>
                <Input placeholder={t("phoneNumber")} />
              </Form.Item>
              <Form.Item name="email">
                <Input placeholder={t("emailRu")} />
              </Form.Item>
            </div>
          </FormCard>
          <FormCard title={t("document")}>
            <div className="documents">
              <div
                className={`card ${
                  themeStore.theme === "dark" ? "dark" : "light"
                }`}
              >
                <div className="d-flex align-items-center justify-between form--profile-edit__info">
                  {userStore.userData.passport?.length ? (
                    userStore.userData.passport.map((el: string) => (
                      <img
                        src={el}
                        alt="avatar"
                        style={{
                          maxWidth: "143px",
                          borderRadius: 0,
                          maxHeight: 143,
                        }}
                      />
                    ))
                  ) : (
                    <div className="before d-flex align-items-center">
                      <pre>{t("forJoining_inBlank")}</pre>
                    </div>
                  )}
                  <Form.Item
                    name="passport"
                    colon={false}
                    style={{ margin: 0 }}
                  >
                    <ImgCrop
                      rotate
                      modalTitle={t("saveThePhoto")}
                      modalOk={t("save")}
                    >
                      <Upload
                        name="passport"
                        className="avatar-uploader"
                        onChange={handleChange}
                        multiple={true}
                        showUploadList={false}
                        customRequest={({ file, onSuccess }) => {
                          userStore
                            .updatePassport(file)
                            .then(() => userStore.getMe())
                            .then(onSuccess)
                            .then(() =>
                              openNotification(
                                "success",
                                t("success"),
                                `${t("successSaveAvatar")}`
                              )
                            )
                            .catch((e: any) =>
                              openNotification(
                                "error",
                                t("fail"),
                                `${t("failSaveAvatar")}`
                              )
                            );
                        }}
                      >
                        <>
                          <OutlineButton type={"button"} title={"Загрузить"} />
                          {fileList.map((file) => (
                            <p style={{ color: "white" }}>{file.name}</p>
                          ))}
                        </>
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </div>
              </div>
              <FilledButton title={t("saveChanges")} type="submit" />
            </div>
          </FormCard>
          <FormCard title={t("changePassword")}>
            <div className="d-flex input-wrapper">
              <Form.Item>
                <Input type="password" />
              </Form.Item>
              <Form.Item>
                <Input type="password" />
              </Form.Item>
            </div>
            <FilledButton title={t("changePassword")} />
          </FormCard>
        </Form>
      )}
    </div>
  );
};

export default observer(FormProfileEdit);
