import React from "react";

const AvatarUploaderSvg = () => {
  return (
    <svg
      width="42"
      height="36"
      viewBox="0 0 42 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8482 19.1721C12.8482 14.6698 16.498 11.02 21.0003 11.02C25.5026 11.02 29.1525 14.6698 29.1525 19.1721C29.1525 23.6745 25.5026 27.3243 21.0003 27.3243C16.498 27.3243 12.8482 23.6745 12.8482 19.1721ZM21.0003 14.2808C18.2989 14.2808 16.109 16.4707 16.109 19.1721C16.109 21.8735 18.2989 24.0634 21.0003 24.0634C23.7017 24.0634 25.8916 21.8735 25.8916 19.1721C25.8916 16.4707 23.7017 14.2808 21.0003 14.2808Z"
        fill="#8F90A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9919 3.41126C15.7198 3.41126 13.8779 5.25317 13.8779 7.52528C13.8779 8.97302 12.7666 10.1783 11.3236 10.2955L6.4766 10.6891C5.52493 10.7664 4.74226 11.4702 4.56463 12.4083C3.5891 17.5605 3.51636 22.843 4.34965 28.0201L4.56107 29.3336C4.76073 30.574 5.78167 31.5178 7.03396 31.6195L11.2564 31.9624C17.7417 32.4891 24.259 32.4891 30.7442 31.9624L34.9667 31.6195C36.219 31.5178 37.2399 30.574 37.4396 29.3336L37.651 28.0201C38.4843 22.843 38.4116 17.5605 37.436 12.4083C37.2584 11.4702 36.4757 10.7664 35.5241 10.6891L30.6771 10.2955C29.2341 10.1783 28.1228 8.97302 28.1228 7.52528C28.1228 5.25317 26.2808 3.41126 24.0087 3.41126H17.9919ZM10.6302 7.08018C10.8604 3.21439 14.0683 0.150391 17.9919 0.150391H24.0087C27.9323 0.150391 31.1403 3.21439 31.3704 7.08018L35.788 7.43895C38.203 7.63508 40.1892 9.42097 40.64 11.8017C41.6858 17.3251 41.7638 22.9882 40.8704 28.5383L40.659 29.8518C40.2207 32.5747 37.9796 34.6464 35.2306 34.8696L31.0082 35.2126C24.3472 35.7535 17.6534 35.7535 10.9925 35.2126L6.77001 34.8696C4.02104 34.6464 1.77992 32.5747 1.34163 29.8518L1.13022 28.5383C0.236887 22.9882 0.314868 17.3251 1.36068 11.8017C1.81145 9.42096 3.79761 7.63508 6.21265 7.43895L10.6302 7.08018Z"
        fill="#8F90A6"
      />
    </svg>
  );
};

export default AvatarUploaderSvg;
