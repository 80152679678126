import "./ratings.scss";

import React, { useEffect, useState } from "react";
import history from "../../history";
import FilterRow from "../../components/filterRow/FilterRow";
import RatingCardWrapper from "../../components/ratingCardWrapper/RatingCardWrapper";
import ratingStore from "../../store/rating.store";
import { labelStatus } from "../contest/components/label/ContestCardStatusLabel";
import { useTranslation } from "react-i18next";

export enum ERatingFilter {
  elo = "ratingElo",
  playmaker = "ratingPlaymaker",
}

const Ratings = () => {
  const [filter, setFilter] = useState<ERatingFilter>(ERatingFilter.elo);
  const { t } = useTranslation();

  return (
    <div className="ratings container">
      <div className="back" onClick={() => history.goBack()}>
        { t('back') }
      </div>
      <div className="title">{ t('rating') }</div>
      <div>
        <FilterRow>
          <div
            className={filter === ERatingFilter.elo ? "active" : ""}
            onClick={() => {
              setFilter(ERatingFilter.elo);
            }}
          >
            { t('elo') }
          </div>
          <div
            className={filter === ERatingFilter.playmaker ? "active" : ""}
            onClick={() => {
              setFilter(ERatingFilter.playmaker);
            }}
          >
            { t('playmaker') }
          </div>
        </FilterRow>
      </div>
      <div className="card">
        <div className="warning">
          {/* { t('inTheRating') } */}
          warning
        </div>
      </div>
      <RatingCardWrapper ratingType={filter} />
    </div>
  );
};

export default Ratings;
