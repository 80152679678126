import React from 'react';
import { useTranslation } from 'react-i18next';

import './contestCardStatusLabel.scss';

export enum labelStatus {
  active = 'active',
  await = 'await',
  check = 'check',
  closed = 'closed'
}


const ContestCardStatusLabel = ({status}: {status: labelStatus}) => {
  const { t } = useTranslation();
  const statuses = {
    active: {
      className: 'active',
      label: t('statusActive')
    },
    await: {
      className: 'await',
      label: t('statusWaiting')
    },
    check: {
      className: 'check',
      label: t('statusReviewing')
    },
    closed: {
      className: 'closed',
      label: t('statusComplete')
    }
  };

  return (
    <span className={`contest-card__status-label ${statuses[status].className}`}>
      {statuses[status].label}
    </span>
  );
}

export default ContestCardStatusLabel;