import React, {ButtonHTMLAttributes, DetailedHTMLProps} from 'react';
import './gameButton.scss';

interface GameButtonProps extends ButtonHTMLAttributes<any> {
  children?: any;
  onClick: any;
}

const GameButton = ({onClick, ...props}: GameButtonProps) => {
  return (
    <button
      onClick={onClick}
      className='game-button'
      {...props}
    >
      <props.children />
    </button>
  );
};

export default GameButton;