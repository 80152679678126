import "./index.scss";
import React, { createContext } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AuthStore from "./store/auth.store";
import { Router } from "react-router";
import history from "./history";
import ru_RU from "antd/lib/locale/ru_RU";
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/ru";
import ThemeProvider from "./providers/ThemeProvider";
import LanguageProvider from "./providers/LanguageProvider";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dictionary from "./dictionary/dictionary.json";
import GoStore from "./store/go.store";

interface IStore {
  authStore: AuthStore;
  goStore: typeof GoStore;
}

moment.locale(localStorage.getItem("lang") || "ru");

const authStore = new AuthStore();
const goStore = GoStore;

export const Context = createContext<IStore>({
  authStore,
  goStore
});

i18n.use(initReactI18next).init({
  resources: {
    ...dictionary,
  },
  lng: localStorage.getItem("lang") || "ru",
  fallbackLng: Object.keys(dictionary),
});

ReactDOM.render(
  <ConfigProvider locale={ru_RU}>
    <Router history={history}>
      <Context.Provider value={{ authStore, goStore }}>
        <LanguageProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </LanguageProvider>
      </Context.Provider>
    </Router>
  </ConfigProvider>,
  document.getElementById("root")
);
