import React, {useRef} from 'react';
import StockFish from "./goPageComponents/stockfish/Stockfish";
import RapidSvg from "../../svg/RapidSvg";
import { useLocation } from "react-router";
// @ts-ignore
import Chessground from "react-chessground";
import { useTranslation } from 'react-i18next';

const boardStyle = {
  borderRadius: "5px",
  boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`,
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ChessPageWithComputer = () => {
  const query = useQuery();
  const color = query.get("col");
  const time = query.get("t");
  const additionalTime = query.get("ad");
  const level = query.get("l");

  const timeComputer = useRef(null);
  const timePlayer = useRef(null);
  
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="game-title">
        <div className="svg-type">
          <RapidSvg />
        </div>
        <div className="game-type">
          <h3>
             {t('playWithComputer')} {time ? +time : ""} + {additionalTime}
          </h3>
        </div>
      </div>
      <StockFish color={color} level={level} timeComputer={timeComputer} timePlayer={timePlayer}>
        {({
          position,
          onMove,
          movable,
          turnColor,
        }: {
          position: any;
          onMove: any;
          movable: any;
          turnColor: any;
          time: any;
        }) => (
          <>
            {/*// @ts-ignore*/}
            <div>{timeComputer.current}</div>
            <Chessground
              fen={position}
              onMove={onMove}
              orientation={color}
              movable={movable()}
              turnColor={turnColor()}
            />
            <div>{timePlayer.current}</div>
          </>
        )}
      </StockFish>
    </div>
  );
};

export default ChessPageWithComputer;
