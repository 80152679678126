import io, { Socket } from "socket.io-client";
import { SOCKET_URL } from "../config/consts";
import history from "../history";
import Emitter from "../emitter/Emitter";
import socketStore from "../store/socket.store";
import gamesStore from "../store/games.store";

/**
 * Класс обработчик сокет сообщений
 */
export default class SocketService {
  private static currentSocket: Socket | null = null;
  private static gameSocket: Socket | null = null;
  private static gameId: string = "";

  static roomListener(gameId: string) {
    this.gameSocket = io(`${SOCKET_URL}/game`, {
      path: "/api/socket",
      query: {
        gameId: gameId,
      },
      forceNew: true,
    });

    this.gameSocket.on("time", (data) => {
      Emitter.emit("time", data);
    });

    this.gameSocket.on("opponentGoMove", (data) => {
      Emitter.emit("opponentGoMove", data);
    });

    this.gameSocket.on("opponentGoPass", (data)=>{
      Emitter.emit("opponentGoPass",data);
    })

    this.gameSocket.on("opponentSubmitStart", () => {
      Emitter.emit("opponentSubmitStart", null);
    });

    this.gameSocket.on("gameStarted", (data: { time: number }) => {
      Emitter.emit("gameStarted", data);
    });

    this.gameSocket.on("gameOver", (data) => {
      Emitter.emit("gameOver", data);
    });

    this.gameSocket.on("disconnect", () => {
      Emitter.emit("disconnectGameSocket", null);
    });

    const suggestDraw = () => {
      Emitter.emit("SUGGEST_DRAW", null);
    };
    this.gameSocket.on("SUGGEST_DRAW", suggestDraw);
  }

  static getTimer() {
    this.gameSocket?.emit("getTimer");
  }

  static closeConnect() {
    this.gameSocket?.close();
  }

  static startGame(gameId: string) {
    this.gameSocket?.emit("submitStart", { gameId });
  }

  static goMove(data: any){
    this.gameSocket?.emit("goMove", data);
  }
  static goPass(data: any){
    this.gameSocket?.emit("goPass", data);
  }
  static saveDeadPiece(data: any){
    this.gameSocket?.emit("saveDeadPieces",data);
  }
  static saveScore(data: any){
    this.gameSocket?.emit("saveScore",data);
  }

  static suggestDraw(gameId: string) {
    this.gameSocket?.emit("SUGGEST_DRAW", gameId);
  }

  /**
   * Подключение к платформе
   * @param {string} userId - id пользователя
   */
  static userListener(userId: string) {
    const connectionOptions = {
      query: { userId },
      path: "/api/socket",
      forceNew: true,
    };
    this.currentSocket = io(`${SOCKET_URL}/lobby`, connectionOptions);

    const redirectGamePage = (data: { gameId: string }) => {
      history.push(`/game/${data.gameId}`);
    };

    const clientCount = (clientCount: number) => {
      Emitter.emit("clientCount", clientCount);
    };

    const onlineGamesCount = (onlineGamesCount: number) => {
      Emitter.emit("onlineGamesCount", onlineGamesCount);
    };

    this.currentSocket.on("redirectGamePage", redirectGamePage);
    this.currentSocket.on("clientCount", clientCount);
    this.currentSocket.on("onlineGamesCount", onlineGamesCount);
    this.currentSocket.on("connect", () => {
      socketStore.setLoading(false);
    });

    this.currentSocket.on("GAMES_UPDATE", (games) => {
      gamesStore.setGames(games);
    });
  }

  static getClientCount() {
    this.currentSocket?.emit("getClientCount");
  }

  static getOnlineGamesCount() {
    this.currentSocket?.emit("getOnlineGamesCount");
  }

  static disconnectRoom() {
    socketStore.setLoading(true);
    this.currentSocket?.disconnect();
  }

  static getOnlineGames() {
    this.currentSocket?.emit("GET_ONLINE_GAMES");
  }
}
