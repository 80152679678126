import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import PlayerCardProps from "../../types/props/playerCardProps";
import "./playerInfoCard.scss";

const PlayerInfoCard = (props: PlayerCardProps) => {
  let classes = "player-info-card align-items-center d-flex";
  if (props.className) {
    classes += ` ${props.className}`;
  }
  return (
    <div className={classes}>
      {props.photo ? (
        <Avatar className="avatar" src={props.photo} />
      ) : (
        <Avatar className="avatar">
          <UserOutlined />
        </Avatar>
      )}
      <div>
        <p className="name">
          {props.name}
          <span
            className={`flag-icon flag-icon-${props.country} flag-icon-squared`}
          />
        </p>
        {props.rank ? <p className={"rank"}>{props.rank}</p> : ""}
      </div>
    </div>
  );
};

export default PlayerInfoCard;
