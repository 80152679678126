import {AxiosResponse} from 'axios';
import $api from '../http';
import {TLang} from '../store/language.store';

export interface IUpdateLangBody {
  lang: string;
  translate: string;
  key: string;
}

export interface ICreateLangBody {
  lang: string;
}

export default class LanguageService {
  static async update(body: IUpdateLangBody): Promise<AxiosResponse> {
    return $api.post(`/lang/update`, body);
  }
  static async createLang(body: ICreateLangBody): Promise<AxiosResponse> {
    return $api.post(`/lang/create/lang`, body);
  }
  static async getAccessLanguages(): Promise<AxiosResponse<TLang[]>> {
    return $api.get<TLang[]>(`/lang/access`);
  }
  static async getTranslation(): Promise<AxiosResponse<any[]>> {
    return $api.get<any[]>(`/lang/translation`);
  }
}