import React from 'react';

const InfoTriangleSvg = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.5003 22.4999C32.5003 23.8806 31.381 24.9999 30.0003 24.9999C28.6196 24.9999 27.5003 23.8806 27.5003 22.4999C27.5003 21.1192 28.6196 19.9999 30.0003 19.9999C31.381 19.9999 32.5003 21.1192 32.5003 22.4999Z" fill="#8F90A6"/>
      <path d="M30.0003 29.3749C31.0358 29.3749 31.8753 30.2143 31.8753 31.2499V43.7499C31.8753 44.7854 31.0358 45.6249 30.0003 45.6249C28.9647 45.6249 28.1253 44.7854 28.1253 43.7499V31.2499C28.1253 30.2143 28.9647 29.3749 30.0003 29.3749Z" fill="#8F90A6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.6761 9.98147C32.9462 5.98731 27.0542 5.98729 24.3243 9.98146L23.2445 11.5613C16.4794 21.4594 10.667 31.9759 5.88517 42.9701L5.65963 43.4886C3.97449 47.363 6.5204 51.7623 10.7193 52.2316C23.5333 53.6638 36.4671 53.6638 49.2812 52.2316C53.48 51.7623 56.0259 47.363 54.3408 43.4886L54.1153 42.9701C49.3334 31.9759 43.5211 21.4594 36.7559 11.5613L35.6761 9.98147ZM27.4203 12.0975C28.6611 10.282 31.3393 10.282 32.5802 12.0975L33.66 13.6774C40.2915 23.3799 45.9891 33.6887 50.6765 44.4658L50.902 44.9843C51.5805 46.5444 50.5554 48.3159 48.8647 48.5048C36.3274 49.906 23.673 49.906 11.1358 48.5048C9.44505 48.3159 8.4199 46.5444 9.09844 44.9843L9.32398 44.4658C14.0114 33.6887 19.709 23.3799 26.3405 13.6773L27.4203 12.0975Z" fill="#8F90A6"/>
    </svg>

  );
};

export default InfoTriangleSvg;