import "./users.scss";

import ban from "../../../assets/images/ban.svg";
import deleteUser from "../../../assets/images/deleteUser.svg";
import deleteUserClose from "../../../assets/images/deleteUserClose.svg";

import React, { useEffect, useState } from "react";
import Title from "../../../components/title/Title";
import {
  DatePicker,
  InputNumber,
  Modal,
  Table,
  TableProps,
  TimePicker,
  Tooltip,
} from "antd";
import userStore from "../../../store/user.store";
import { ColumnsType } from "antd/es/table";
import PlayerCardProps from "../../../types/props/playerCardProps";
import PlayerInfoCard from "../../../components/playerInfoCard/PlayerInfoCard";
import { observer } from "mobx-react-lite";
import CloseModalSvg from "../../../svg/CloseModalSvg";
import FilledButton from "../../../components/buttons/filledButton/FilledButton";
import OutlineButton from "../../../components/buttons/outlineButton/OutlineButton";
import { useTranslation } from "react-i18next";

const Users = () => {
  const [banCandidate, setBanCandidate] = useState<string | null>();
  const [deleteCandidate, setDeleteCandidate] = useState<string | null>();
  const [banTime, setBanTime] = useState<string | null>();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showBanModal, setBanModal] = useState<boolean>(false);

  const [innerWidth, setInnerWidth] = useState<number>();

  const { t } = useTranslation();

  useEffect(() => {
    userStore.getUsers();
  }, []);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [innerWidth]);

  const columns: ColumnsType<any> = [
    {
      title: t("player"),
      dataIndex: "firstName",
      render: (_, record) => (
        <PlayerInfoCard
          photo={record.photo}
          country={record.country ? record.country : "ru"}
          name={
            record.lastName
              ? `${record.lastName} ${record.firstName}`
              : "unknown"
          }
        />
      ),
    },
    {
      title: t("city"),
      dataIndex: "city",
      render: (text) => (text ? text : "-----"),
    },
    {
      title: t("elo"),
      dataIndex: "ratingElo",
      render: (text) => (text ? parseInt(text, 10) : "-----"),
    },
    {
      title: t("playmaker"),
      dataIndex: "ratingPlaymaker",
      render: (text) => (text ? parseInt(text, 10) : "-----"),
    },
    {
      title: t("accrued"),
      dataIndex: "purse",
      render: (text) => (text ? text : "-----"),
    },
    {
      title: t("paid"),
      dataIndex: "purse",
      render: (text) => (text ? text : "-----"),
    },
    {
      title: t("balance"),
      dataIndex: "purse",
      render: (text) => (text ? text : "-----"),
    },
    {
      title: t("blockTime"),
      dataIndex: "purse",
      render: (text) => (text ? text : "-----"),
    },
    {
      title: "",
      dataIndex: "_id",
      render: (record, text) => (
        <div className="d-flex align-items-center gap-1 action">
          <img
            src={ban}
            alt=""
            onClick={() => {
              setBanModal(true);
              setBanCandidate(record);
            }}
          />
          <img
            src={deleteUser}
            alt=""
            onClick={() => {
              setDeleteCandidate(record);
              setShowDeleteModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const tableOptions: TableProps<any> = {
    pagination: false,
    columns,
  };

  const onCancelDelete = () => {
    setDeleteCandidate(null);
    setShowDeleteModal(false);
  };

  const onSubmitDelete = () => {
    userStore
      .deleteUser(deleteCandidate!)
      .then(() => onCancelDelete())
      .then(() => userStore.getUsers());
  };

  const onSubmitBan = () => {
    userStore.updateUser(banCandidate!, {});
  };

  const mobileUser = (
    <div className="mobile-users">
      <Title title={t("users")} />
      {userStore.users.map((user) => (
        <div className="card" key={user._id}>
          <div className="data">
            <div className="user d-flex justify-between">
              <PlayerInfoCard
                photo={user.photo}
                country={user.country ? user.country : "ru"}
                name={
                  user.lastName
                    ? `${user.lastName} ${user.firstName}`
                    : "unknown"
                }
              />
              <div className="btns">
                <img
                  src={ban}
                  alt=""
                  onClick={() => {
                    setBanModal(true);
                    setBanCandidate(user._id);
                  }}
                />
                <img
                  src={deleteUser}
                  alt=""
                  onClick={() => {
                    setDeleteCandidate(user._id);
                    setShowDeleteModal(true);
                  }}
                />
              </div>
            </div>
            <div className="city d-flex justify-between">
              <div>{t("city")}</div>
              <div>{user.city}</div>
            </div>
            <div className="elo d-flex justify-between">
              <div>{t("elo")}</div>
              <div>{user.ratingElo}</div>
            </div>
            <div className="playmaker d-flex justify-between">
              <div>{t("playmaker")}</div>
              <div>{user.ratingPlaymaker}</div>
            </div>
            <div className="accrued d-flex justify-between">
              <div>{t("accrued")}</div>
              <div>{user.purse}</div>
            </div>
            <div className="paid d-flex justify-between">
              <div>{t("paid")}</div>
              <div>{user.purse}</div>
            </div>
            <div className="balance d-flex justify-between">
              <div>{t("balance")}</div>
              <div>{user.purse}</div>
            </div>
            <div className="block-time d-flex justify-between">
              <div>{t("blockTime")}</div>
              <div>{user.purse}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className="users">
        <div className="fluid-container">
          <Title title={t("users")} />
          {userStore.users.length && (
            <Table
              pagination={false}
              {...tableOptions}
              dataSource={userStore.users}
            />
          )}
        </div>
      </div>
      {mobileUser}

      <div className="users-modals">
        <Modal
          closeIcon={<CloseModalSvg />}
          footer={false}
          className="card"
          visible={showDeleteModal}
          onCancel={onCancelDelete}
          closable={!userStore.isLoading}
        >
          <div className="d-flex flex-column justify-center align-items-center delete-modal">
            <img src={deleteUserClose} alt="" />
            <p>{t("deleteUser")}</p>
            <div className="d-flex" style={{ columnGap: ".5rem" }}>
              <FilledButton
                title={t("delete")}
                handleOnClick={onSubmitDelete}
              />
              <OutlineButton
                title={t("cancel")}
                handleOnClick={onCancelDelete}
              />
            </div>
          </div>
        </Modal>
        <Modal
          closeIcon={<CloseModalSvg />}
          footer={false}
          className="card"
          visible={showBanModal}
          onCancel={() => setBanModal(false)}
        >
          <div className="d-flex flex-column justify-center align-items-center delete-modal">
            <img src={ban} alt="" />
            <p>{t("blockUser")}</p>
            <Tooltip title={t("minutes")}>
              <InputNumber
                placeholder={t("setTime")}
                onChange={(value) => {if(value) setBanTime(value.toString())}}
              />
            </Tooltip>
            <FilledButton title={t("block")} handleOnClick={onSubmitBan} />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default observer(Users);
