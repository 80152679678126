import React from 'react';

const RapidSvg = () => {
  return (
    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1668 3.28418C13.3729 3.28418 12.7293 2.64059 12.7293 1.84668C12.7293 1.05277 13.3729 0.40918 14.1668 0.40918H20.9433C21.7372 0.40918 22.3808 1.05277 22.3808 1.84668C22.3808 2.64059 21.7372 3.28418 20.9433 3.28418H14.1668Z" fill="#555770"/>
      <path d="M7.51663 4.15033C8.07801 4.71171 8.07801 5.62188 7.51663 6.18326L2.72496 10.9749C2.16358 11.5363 1.25341 11.5363 0.69203 10.9749C0.130651 10.4135 0.130651 9.50337 0.69203 8.942L5.4837 4.15033C6.04508 3.58895 6.95525 3.58895 7.51663 4.15033Z" fill="#555770"/>
      <path d="M18.0002 8.52096C10.3257 8.52096 4.10433 14.7423 4.10433 22.4168C4.10433 30.0913 10.3257 36.3126 18.0002 36.3126C25.6746 36.3126 31.896 30.0913 31.896 22.4168C31.896 21.6229 32.5396 20.9793 33.3335 20.9793C34.1274 20.9793 34.771 21.6229 34.771 22.4168C34.771 31.6791 27.2624 39.1876 18.0002 39.1876C8.73789 39.1876 1.22933 31.6791 1.22933 22.4168C1.22933 13.1545 8.73789 5.64596 18.0002 5.64596C18.7941 5.64596 19.4377 6.28955 19.4377 7.08346C19.4377 7.87737 18.7941 8.52096 18.0002 8.52096Z" fill="#555770"/>
      <path d="M27.9442 13.5316C28.3893 12.9594 28.3386 12.1452 27.826 11.6326C27.3134 11.12 26.4992 11.0693 25.927 11.5144L19.8513 16.2399L15.8811 19.0758C15.033 19.6815 14.5297 20.6596 14.5297 21.7018C14.5297 23.4841 15.9746 24.9289 17.7568 24.9289C18.799 24.9289 19.7771 24.4256 20.3828 23.5775L23.2187 19.6073L27.9442 13.5316Z" fill="#555770"/>
    </svg>
  );
};

export default RapidSvg;