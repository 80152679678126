import React, {FC} from 'react';
import {Modal, ModalProps} from 'antd';

interface IModalUIProps extends ModalProps{

}

const ModalUi:FC<IModalUIProps> = ({...props}) => {
  return (
    <Modal {...props} />
  );
};

export default ModalUi;