import "./goPage.scss";
import React, { useContext, useEffect, useRef, useState } from "react";

import { useParams } from "react-router";
import { observer } from "mobx-react-lite";

import { Context } from "../../index";

import { IGame } from "../../types/models/game/IGame";

import Emitter from "../../emitter/Emitter";
import SocketService from "../../services/Socket.service";

import HalfSvg from "../../svg/HalfSvg";
import FlagSvg from "../../svg/FlagSvg";
import { useTranslation } from "react-i18next";
import { COLOR, EGameColor, IPosition } from "../../types/models/game/IGo";

import GoBoard from "../../components/go/components/goBoard/GoBoard";
import Estimator from "../../utils/estimator";
import GameTitle from "./components/gameTitle/GameTitle";
import Timer from "./components/timer/Timer";
import GameButton from "../../components/buttons/gameButton/GameButton";
import FilledButton from "../../components/buttons/filledButton/FilledButton";
import GameModal from "./goPageComponents/gameModal/GameModal";
import GameResult from "./goPageComponents/gameResult/GameResult";
import BoardView from "../../components/go/components/boardView/BoardView";

interface ITypeTimer<T> {
  [COLOR.white]: T;
  [COLOR.black]: T;
}

interface IGetTimeFinishOptions {
  color: COLOR.white | COLOR.black;
  opponentColor: COLOR.white | COLOR.black;
  spentTime: number;
}

const GoPage = observer(() => {
  const { authStore, goStore } = useContext(Context);
  const { gameId } = useParams<any>();
  const { t } = useTranslation();
  // @ts-ignore
  const [go, setGo] = useState<GoBoard>(new GoBoard(19));
  const [, setTimeStart] = useState<number | null>(null);
  const [lastMove, setLastMove] = useState<any>();
  const [color, setColor] = useState<COLOR>();
  const [timer, setTimer] = useState<any>({w:300,b:300});
  const [activeTimer, setActiveTimer] = useState(false);
  const [lastMoveTime, setLastMoveTime] = useState<any>({});
  const [ourSubmit, setOurSubmit] = useState<boolean>(false);
  const [opponentSubmit, setOpponentSubmit] = useState<boolean>(false);
  const [spentTime, setSpentTime] = useState(0);

  const [innerWidth, setInnerWidth] = useState<number>();

  const [gameStarted, setGameStarted] = useState({
    [COLOR.white]: false,
    [COLOR.black]: false,
  });
  const [timeOnFirstMoveBlack, setTimeOnFirstMoveBlack] = useState(300);
  const [timeOnFirstMoveWhite, setTimeOnFirstMoveWhite] = useState(300);

  const [orientation, setOrientation] = useState<COLOR>(COLOR.white);
  const [isOriented, setIsOriented] = useState<boolean>(false);

  const [movesHistory, setMovesHistory] = useState<any[]>([]);
  const [isVisibleModalSurrender, setIsVisibleModalSurrender] =
    useState<boolean>(false);
  const [isVisibleModalDraw, setIsVisibleModalDraw] = useState<boolean>(false);
  const [isVisibleModalDrawWait, setIsVisibleModalDrawWait] =
    useState<boolean>(false);
  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [innerWidth]);

  //fetch the data of moves
  useEffect(() => {
    goStore.getGame(gameId).then(() => {
      //@ts-ignore
      go.size = goStore.game?.size;
    });
    goStore
      .getGameHistory(gameId)
      .then((res: any) => {
        setMovesHistory(res);
        res.map((item: any) => {
          const { i, j } = item.position;
          const color = item.color;
          go.board[i][j] = color as COLOR;
          go.currentColor = color === COLOR.black ? COLOR.white : COLOR.black;
          color === COLOR.black ? go.stone_black++ : go.stone_white++;
        });
      })
      .then(() => {});
    goStore.getDeadPieces(gameId).then((res: any) => {
      res.map((item: any) => {
        const { i, j } = item.position;
        go.board[i][j] = COLOR.empty;
        item.color === COLOR.black ? go.dead_black++ : go.dead_white++;
        item.color === COLOR.black ? go.stone_black-- : go.stone_white--;
      });
    });
  }, []);

  const gameControlModuleChildren = [
    {
      children: HalfSvg,
      onClick: () => {
        SocketService.suggestDraw(goStore.game!.gameId);
      },
    },
    {
      children: FlagSvg,
      onClick: () => {
        setIsVisibleModalSurrender(true);
      },
    },
  ];

  const useEffectRoomStartCallback = () => {
    SocketService.roomListener(gameId);

    return () => {
      SocketService.closeConnect();
    };
  };

  const useEffectGetGameInformationCallback = () => {
    const getGameCallback = () => {
      if (goStore.game) {
        const { startingTime, timer: serverTimer } = goStore.game;
        let { color } = goStore.game;
        const setLastMoveTimeOptions: any = {};
        color =
          color.toString() == "black"
            ? COLOR.black
            : color.toString() == "white"
            ? COLOR.white
            : COLOR.empty;
        setColor(color);
        go.setPlayerColor(color);
        console.log(
          `current color: ${go.currentColor} , playercolor: ${go.playerColor}`
        );
        setOrientation(color);

        // сколько осталось время на первый ход
        // let timeLeft = 60 - (Date.now() - +new Date(goStore.game.created))/1000;
        // if(serverTimer){
        //   if(serverTimer.b.lastTurnTime) {
        //     timeLeft = 60 - (Date.now() - +new Date(serverTimer.b.lastTurnTime))/1000;
        //     setTimeOnFirstMoveWhite(timeLeft)
        //   }
        // }
        // else{
        //   setTimeOnFirstMoveBlack(timeLeft);
        // }

        if (goStore.game && go.stone_black + go.stone_white == 0) {
          const { startingTime } = goStore.game;
          setTimer(()=>{
            return{
            [COLOR.white]: startingTime,
            [COLOR.black]: startingTime,
          }});
        }
        if (serverTimer) {
          if (serverTimer.w.lastTurnTime) {
            setGameStarted((prevState) => ({
              ...prevState,
              w: true,
            }));
          }

          if (serverTimer.b.lastTurnTime) {
            setGameStarted((prevState) => ({
              ...prevState,
              b: true,
            }));
          }

          const lastTurnWhite = +new Date(serverTimer.w.lastTurnTime);
          const lastTurnBlack = +new Date(serverTimer.b.lastTurnTime);
          
          setLastMoveTimeOptions.w = lastTurnWhite;
          setLastMoveTimeOptions.b = lastTurnBlack;

          // const timeLeftWhite = +startingTime - serverTimer.w.spentTime;
          // const timeLeftBlack = +startingTime - serverTimer.b.spentTime;

          const timeLeftWhite = go.currentColor === COLOR.white ?  +startingTime - (Date.now() - lastTurnBlack)/1000 : +startingTime - serverTimer.w.spentTime;
          const timeLeftBlack = go.currentColor === COLOR.black ?  +startingTime - (Date.now() - lastTurnWhite)/1000 : +startingTime - serverTimer.b.spentTime;
          
          console.log(
            `timeLeftWhite:${timeLeftWhite} , timeLeftBlack: ${timeLeftBlack}, w.spentTime: ${serverTimer.w.spentTime},b.spentTime:${serverTimer.b.spentTime},`
          );
          setTimer({
            [COLOR.white]: timeLeftWhite,
            [COLOR.black]: timeLeftBlack,
          });

          let blackTimeFinish =
            +new Date(serverTimer.w.lastTurnTime) + startingTime * 1000;
          let whiteTimeFinish =
            +new Date(serverTimer.b.lastTurnTime) + startingTime * 1000;

          setOurSubmit(true);
          setOpponentSubmit(true);
          setActiveTimer(true);
        }

        setLastMoveTime(setLastMoveTimeOptions);
      }
    };

    const getGameCatchError = (e: any) => e;
    goStore.getGame(gameId).then(getGameCallback).catch(getGameCatchError);

    return () => {
      goStore.clearGame();
    };
  };

  const useEffectOpponentGoMoveCallback = () => {
    const handlerOpponentGoMove = (data: any) => {
      const setLastMoveTimeCallback = (prevState: any) => ({
        ...prevState,
        [data.color]: Date.now(),
      });
      const { position } = data;
      const setLastMoveOptions = position;
      go.play(position.i, position.j);

      setLastMove(setLastMoveOptions);
      setLastMoveTime(setLastMoveTimeCallback);
      setMovesHistory((prevState: any) => [...prevState, data]);
    };

    const handlerOpponentGoPass = (data: any) => {
      const color = data.color === COLOR.white ? COLOR.black : COLOR.white;
      const opponentColor: "w" | "b" = data.color as "w" | "b";
      const setLastMoveTimeCallback = (prevState: any) => ({
        ...prevState,
        [data.color]: Date.now(),
      });

      go.pass();

      setLastMoveTime(setLastMoveTimeCallback);
    };

    Emitter.on("opponentGoMove", handlerOpponentGoMove);
    Emitter.on("opponentGoPass", handlerOpponentGoPass);
    return () => {
      Emitter.removeListener("opponentGoMove", handlerOpponentGoMove);
      Emitter.removeListener("opponentGoPass", handlerOpponentGoPass);
    };
  };

  const useEffectOpponentSubmitCallback = () => {
    const handlerOpponentSubmitStart = () => {
      setOpponentSubmit(true);
      setGameStarted((prevState) => ({
        ...prevState,
        [go.currentColor]: true,
      }));
    };
    Emitter.on("opponentSubmitStart", handlerOpponentSubmitStart);

    return () => {
      Emitter.removeListener("opponentSubmitStart", handlerOpponentSubmitStart);
    };
  };

  const useEffectGameOverCallback = () => {
    const handlerGameOver = (data: IGame) => {
      if (data.gameId !== gameId) {
        console.log("testing...");
      }

      setActiveTimer(false);
      goStore.setColor(COLOR.empty);
      goStore.getGame(gameId);
    };

    Emitter.on("gameOver", handlerGameOver);

    return () => {
      Emitter.removeListener("gameOver", handlerGameOver);
    };
  };

  // const useEffectEstimationCallback = ()=>{
  //   const handlerEstimator = () =>{
  //     /**
  //      * here it runs twice?!
  //      */
  //     if(goStore.game){
  //     console.log("estimation started");
  //     const resultBoard = est.estimate(COLOR.white, 10000, 0.35);
  //     const {komi} = goStore.game;
  //     const score = est.score(resultBoard,goStore.game?.rules,0,0,komi === "auto" ? false : true);
  //     const winner = score > 0 ? COLOR.black : score < 0 ? COLOR.white : "DRAW";
  //     goStore.finishGame(gameId,winner).then(()=>{
  //       console.log(`${winner} ${winner === "DRAW" ? "" : "wins"} score:${score} komi:${komi}`);
  //     });
  //   }

  // }
  // Emitter.on("estimate", handlerEstimator);
  // return ()=>{
  //   Emitter.removeListener("estimate", handlerEstimator);
  // }
  // }

  const useEffectGameStartCallback = () => {
    const gameStart = (data: any) => {
      if (goStore.game) {
        const timeFinish = Date.now() + goStore.game.startingTime * 1000;
        const stateFinish = {
          [COLOR.white]: timeFinish,
          [COLOR.black]: timeFinish,
        };

        const stateLastMove = {
          [COLOR.white]: Date.now(),
          [COLOR.black]: Date.now(),
        };

        setTimeStart(Date.now());
        setActiveTimer(true);
        setLastMoveTime(stateLastMove);
      }
    };
    /**
     * Активирует таймер, после первого хода.
     */
    Emitter.on("gameStarted", gameStart);

    return () => {
      Emitter.removeListener("gameStarted", gameStart);
    };
  };

  const useEffectTimerCallback = () => {
    if (activeTimer && ourSubmit && opponentSubmit && lastMoveTime) {
      const color = go.currentColor as COLOR.black | COLOR.white;
      const opponentColor = color === COLOR.white ? COLOR.black : COLOR.white;
    }
  };
  const useEffectEstimationCallback = () => {
    if (go.isOver && goStore.game) {
      console.log("estimation started");
      const est = new Estimator(go);
      const resultBoard = est.estimate(COLOR.white, 10000, 0.35);
      const { rules, komi } = goStore.game;
      est.print_board(resultBoard);
      const score = est.score(
        resultBoard,
        rules,
        go.dead_black,
        go.dead_white,
        parseFloat(komi)
      );
      const winner = score > 0 ? COLOR.black : score < 0 ? COLOR.white : "DRAW";
      SocketService.saveScore({gameId,score});
      goStore.finishGame(gameId, winner).then(() => {
        console.log(
          `${winner} ${
            winner === "DRAW" ? "" : "wins"
          } score:${score} komi:${komi} rules:${rules}`
        );
      });
    }
  };
  useEffect(useEffectEstimationCallback, [go.isOver]);

  const useEffectDeadPieceCallback = () => {};
  useEffect(useEffectDeadPieceCallback, [go.dead_collections.length]);

  useEffect(useEffectRoomStartCallback, []);
  useEffect(useEffectGetGameInformationCallback, []);
  useEffect(useEffectOpponentGoMoveCallback, []);
  useEffect(useEffectOpponentSubmitCallback, []);
  useEffect(useEffectGameStartCallback, []);
  useEffect(useEffectGameOverCallback, []);

  const useEffectSuggestDraw = () => {
    const onSuggestDraw = () => {
      setIsVisibleModalDraw(true);
    };
    Emitter.on("SUGGEST_DRAW", onSuggestDraw);

    return () => {
      Emitter.removeListener("SUGGEST_DRAW", onSuggestDraw);
    };
  };
  useEffect(useEffectSuggestDraw, []);

  // useEffect(() => {
  //   if (!gameStarted[COLOR.white]) {
  //     if (timeOnFirstMoveWhite > 0) {
  //       setTimeout(() => {
  //         setTimeOnFirstMoveWhite(timeOnFirstMoveWhite - 1);
  //       }, 1000);
  //     } else {
  //       goStore.getGame(gameId);
  //     }
  //   } else if (!gameStarted[COLOR.black]) {
  //     if (timeOnFirstMoveBlack > 0) {
  //       setTimeout(() => {
  //         setTimeOnFirstMoveBlack(timeOnFirstMoveBlack - 1);
  //       }, 1000);
  //     } else {
  //       goStore.getGame(gameId);
  //     }
  //   }

  //   if (timeOnFirstMoveWhite <= 0) {
  //     console.log("Black win");
  //   }
  //   if (timeOnFirstMoveBlack <= 0) {
  //     console.log("White win");
  //   }
  // }, [gameStarted, timeOnFirstMoveWhite, timeOnFirstMoveBlack]);

  useEffect(useEffectTimerCallback, [
    timer,
    activeTimer,
    lastMoveTime,
    opponentSubmit,
    ourSubmit,
  ]);

  /**
   * Подтверждение начала игры
   */

  const submitGameStart = () => {
    /**
     * Сокет отправляет сообщение "submitStart", в качестве данных передает - id игры
     * На бэке обрабатывает функция "submitStartGame" -
     * 1) Отправляет в эвент событие "submitStartGame" -
     *  1.1) Если ход белого, создает можель таймера, по начальным данным spentTime у обоих игроков = 0
     *  1.2) В ином случае, обновлчет имеющуюся модель, выставляе время последнего хода, как now
     *  1.3) Отправляет эвент событие "gameStarted", обработчик которого отправляет событие на сокет "gameStarted"
     * 2) Оповещает всех кроме клиента, который отправил сообщение, что опонент сделал первый ход
     */
    SocketService.startGame(gameId);

    setOurSubmit(true);
    setGameStarted((prevState) => ({
      ...prevState,
      [go.turn()]: true,
    }));
  };

  const onMove = (position: IPosition) => {
    /**
     * Проверка начата ли игра
     */
    // @ts-ignore
    if (!gameStarted[color]) {
      /**
       * Если нет, то вызываем функцию инициализируюшую подтверждение начала игры
       */
      submitGameStart();
    }

    const setLastMoveTimeOptions = (prevState: any) => ({
      ...prevState,
      [go.turn()]: +Date.now(),
    });
    const opponentColor = color === COLOR.black ? COLOR.white : COLOR.black;
    console.log("opponentColor onMOve", opponentColor);
    const spentTime = activeTimer
      ? +Date.now() - lastMoveTime[opponentColor]
      : 0;
    const goMoveOptions = {
      position,
      color,
      gameId,
      spentTime,
    };

    // setTimeFinish(
    //   (prevState) =>
    //     ({
    //       // @ts-ignore
    //       [color]: prevState[color] + goStore.game?.additionalTime * 1000,
    //       [opponentColor]: prevState[opponentColor] + spentTime,
    //     } as { b: number; w: number })
    // );

    setTimer((prev: any) => {
      return {
        //@ts-ignore
        [color]: +goStore.game?.startingTime,
        //@ts-ignore
        [opponentColor]: +goStore.game?.startingTime ,
      } as { b: number; w: number };
    });


    setLastMove(position);
    setLastMoveTime(setLastMoveTimeOptions);

    setMovesHistory((prevState: any) => [...prevState, goMoveOptions]);

    if (go.dead_collections.length != 0) {
      go.dead_collections.map((position) => {
        const color = go.currentColor;
        const deadPieceData = {
          gameId,
          position,
          color,
        };
        SocketService.saveDeadPiece(deadPieceData);
      });
      go.dead_collections = [];
    }

    SocketService.goMove(goMoveOptions);
  };

  const onPass = () => {
    /**
     * Проверка начата ли игра
     */
    // @ts-ignore
    if (!gameStarted[color]) {
      /**
       * Если нет, то вызываем функцию инициализируюшую подтверждение начала игры
       */
      submitGameStart();
    }
    const setLastMoveTimeOptions = (prevState: any) => ({
      ...prevState,
      [go.turn()]: +Date.now(),
    });
    const opponentColor =
      go.currentColor === COLOR.white ? COLOR.black : COLOR.white;
    const spentTime = activeTimer
      ? +Date.now() - lastMoveTime[opponentColor]
      : 0;

    const goPassOption = {
      color,
      gameId,
      spentTime,
    };
    go.pass();
    setLastMoveTime(setLastMoveTimeOptions);
    setTimer((prev: any) => {
      return {
        //@ts-ignore
        [color]: +goStore.game?.startingTime,
        //@ts-ignore
        [opponentColor]: +goStore.game?.startingTime ,
      } as { b: number; w: number };
    });
    SocketService.goPass(goPassOption);
  };

  const handleOnTimeout = (playerColor: EGameColor) => {
    goStore
      .finishGame(
        gameId,
        playerColor == EGameColor.black ? EGameColor.white : EGameColor.black
      )
      .then();
    console.log(`${playerColor == EGameColor.black ? EGameColor.white : EGameColor.black} wins`)
  };
  return (
    goStore.game && (
      <div className="fluid-container">
        <GameTitle
          gameType={t(goStore.game.type)}
          ratingType={t(goStore.game.ratingType)}
        />
        <div className="go-ground d-flex justify-between gap-1">
          <div className="go-ground__wrapper">
            {go.size === goStore.game.size && (
              <BoardView onMove={onMove} board={go} />
            )}
          </div>
          {goStore.game.status != "finished" ? (
            <div className="w-100">
              <div className="d-flex justify-between gap-1 w-100">
                <Timer
                  time={
                    gameStarted[COLOR.black]
                      ? timer[COLOR.black]
                      : timeOnFirstMoveBlack
                  }
                  moveColor={go.currentColor as unknown as EGameColor}
                  playerColor={EGameColor.black}
                  player={goStore.game.playerBlack}
                  stones={go.stone_black}
                  handleOnTimeout={handleOnTimeout}
                  stopTiming = {go.isOver}
                />
                <Timer
                  time={
                    gameStarted[COLOR.white]
                      ? timer[COLOR.white]
                      : timeOnFirstMoveWhite
                  }
                  moveColor={go.currentColor as unknown as EGameColor}
                  playerColor={EGameColor.white}
                  player={goStore.game.playerWhite}
                  stones={go.stone_white}
                  handleOnTimeout={handleOnTimeout}
                  stopTiming={go.isOver}
                />
              </div>
              {go.isOver && goStore.game.status == "gaming" && (
                  <p className="module__game-notice ">Estimation has been started</p>
              )}
              <div className="module__game-control">
                {gameControlModuleChildren.map((el) => (
                  <GameButton type={"button"} onClick={el.onClick}>
                    {el.children}
                  </GameButton>
                ))}
                <FilledButton disabled={go.currentColor != color ? true : false} title="pass" handleOnClick={onPass} />
              </div>
            </div>
          ) : (
            <GameResult game={goStore.game} />
          )}
        </div>

        <GameModal
          visible={isVisibleModalSurrender}
          onClose={() => setIsVisibleModalSurrender(false)}
          onSubmit={() => {
            goStore
              .finishGame(
                gameId,
                color == COLOR.white ? COLOR.black : COLOR.white
              )
              .then(() => setIsVisibleModalSurrender(false));
          }}
          title={""}
          text={t("sureToGiveUp")}
        />
        <GameModal
          visible={isVisibleModalDraw}
          onClose={() => {
            setIsVisibleModalDraw(false);
          }}
          onSubmit={() => {
            goStore
              .finishGame(gameId, "DRAW")
              .then(() => setIsVisibleModalDraw(false));
          }}
          title={t("ResultDraw")}
          text={t("proposeDraw")}
        />
      </div>
    )
  );
});

export default GoPage;
