import {AxiosResponse} from 'axios';
import $api from '../http';
import {IContest} from '../models/IContest';

export default class ContestService {
  static async postContest(data: IContest): Promise<AxiosResponse<IContest>> {
    return $api.post<IContest>('/contest/create', data);
  }

  static async getContestList(filter: string): Promise<AxiosResponse<IContest[]>> {
    return $api.get<IContest[]>(`/contest/all?f=${filter}`);
  }

  static async getContestById(id: string): Promise<AxiosResponse<IContest>> {
    return $api.get<IContest>(`/contest/${id}`);
  }

}