import React, { useEffect, useState } from "react";
import "./tvPage.scss";
import FilterRow from "../../components/filterRow/FilterRow";
import Title from "../../components/title/Title";
import InterfaceLayoutSvg from "../../svg/InterfaceLayoutSvg";
import InterfaceRowSvg from "../../svg/InterfaceRowSvg";
import ChessComponent from "../../components/chess/ChessComponent";
import { IUser } from "../../types/models/IUser";
import MiniTVCard from "../../components/miniTv/miniTVCard/MiniTVCard";
import MiniTvProps, { GameType } from "../../types/props/miniTvProps";
import { useTranslation } from "react-i18next";
import LayoutCard from "./components/layoutCard/LayoutCard";
import gamesStore, { IGamePlayer } from "../../store/games.store";
import PlayerCardProps from "../../types/props/playerCardProps";

const TvPage = () => {
  const [isOpenedTimeFilter, setISOpenedTimeFilter] = useState<boolean>(false);
  const [innerWidth, setInnerWidth] = useState<number>();
  const [filter, setFilter] = useState<string>("all");
  const { t } = useTranslation();

  const translatePlayerToPlayerProps = (data: IGamePlayer): PlayerCardProps => {
    return {
      country: data.country || "ru",
      name: data.lastName ? `${data.lastName} ${data.firstName}` : 'unknown',
      photo: data.photo,
      rank: data.rank,
    };
  };

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [innerWidth,filter]);
  window.addEventListener("resize",()=>{
    if(window.innerWidth < 768) setInterfaceType('row');
  })
  const [interfaceType, setInterfaceType] = useState<"layout" | "row">(
    "layout"
  );

  const handlerTimeFilterBlock = (e: any) => {
    document
      .querySelectorAll(".filters-block")
      .forEach((el) => el.classList.remove("active"));
    e.currentTarget.classList.add("active");
  };

  const onClick = (e: any)=>{
    setFilter(e.currentTarget.dataset.value);
  };

  const testData: MiniTvProps[] = [
    {
      userWhite: { name: "Михаил Суворов", rank: "Мастер ФИДЕ", country: "ru" },
      userBlack: {
        rank: "Гроссмейстер",
        name: "Евгений Мальцев",
        country: "by",
      },
      type: "rapid",
      time: "5 + 3",
      gameId: "1",
    },
    {
      userWhite: {
        rank: "Гроссмейстер",
        name: "Charlie Rosser",
        country: "sm",
      },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "blitz",
      time: "15 + 10",
      gameId: "2",
    },
    {
      userWhite: {
        rank: "Гроссмейстер",
        name: "Евгений Мальцев",
        country: "by",
      },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "rapid",
      time: "5 + 3",
      gameId: "3",
    },
    {
      userWhite: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      userBlack: { name: "Михаил Суворов", rank: "Мастер ФИДЕ", country: "ru" },
      type: "classic",
      time: "5 + 1",
      gameId: "4",
    },
    {
      userWhite: { name: "Zain Franci", country: "ru" },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "rapid",
      time: "5 + 3",
      gameId: "5",
    },
    {
      userWhite: { name: "Zain Franci", country: "ru" },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "rapid",
      time: "5 + 3",
      gameId: "6",
    },
    {
      userWhite: { name: "Zain Franci", country: "ru" },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "rapid",
      time: "5 + 3",
      gameId: "7",
    },
    {
      userWhite: { name: "Zain Franci", country: "ru" },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "rapid",
      time: "5 + 3",
      gameId: "8",
    },
    {
      userWhite: { name: "Zain Franci", country: "ru" },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "rapid",
      time: "5 + 3",
      gameId: "9",
    },
    {
      userWhite: { name: "Zain Franci", country: "ru" },
      userBlack: { rank: "Гроссмейстер", name: "Omar Siphron", country: "in" },
      type: "rapid",
      time: "5 + 3",
      gameId: "10",
    },
  ];

  return (
    <div className="tv">
      <div className="fluid-container">
        <Title title={t("nowPlaying")} />
        <div
          className="d-flex align-items-start md-column"
          style={{ columnGap: "1.5rem", marginTop: "1.5rem" }}
        >
          <div className="tv-mini">
            <div className="tv-mini__filter">
              <h4
                onClick={() => setISOpenedTimeFilter(!isOpenedTimeFilter)}
                className={`title${isOpenedTimeFilter ? " active" : ""}`}
              >
                {t("duration")}
              </h4>
              {isOpenedTimeFilter && (
                <div className="filters">
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    1 + 1
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    3 + 0
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    5 + 0
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block active"
                  >
                    5 + 1
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    10 + 0
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    15 + 0
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    30 + 0
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    30 + 3
                  </div>
                  <div
                    onClick={handlerTimeFilterBlock}
                    className="filters-block"
                  >
                    30 + 10
                  </div>
                </div>
              )}
            </div>
            <div className="tv-mini__chess">
              <ChessComponent
                blackPlayer={
                  { firstName: "Alex", lastName: "Lomakin" } as IUser
                }
                whitePlayer={
                  { firstName: "Michael", lastName: "Suvorov" } as IUser
                }
              />

              <ChessComponent
                blackPlayer={
                  { firstName: "Alex", lastName: "Lomakin" } as IUser
                }
                whitePlayer={
                  { firstName: "Michael", lastName: "Suvorov" } as IUser
                }
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <FilterRow style={{ height: "auto" }}>
              <div
                onClick={onClick}
                className={filter === "all" ? "active" : ""}
                data-value="all"
              >
                {t("all")}
              </div>
              <div
                onClick={onClick}
                className={filter === "blitz" ? "active" : ""}
                data-value="blitz"
              >
                {t("blitz")}
              </div>
              <div
                onClick={onClick}
                className={filter === "rapid" ? "active" : ""}
                data-value="rapid"
              >
                {t("rapid")}
              </div>
              <div
                onClick={onClick}
                className={filter === "classic" ? "active" : ""}
                data-value="classic"
              >
                {t("classic")}
              </div>
              {innerWidth && innerWidth > 725 && (
                <>
                  <div
                    style={{ marginLeft: "auto" }}
                    onClick={() => setInterfaceType("layout")}
                    className={
                      interfaceType === "layout"
                        ? `interface-type active-interface`
                        : "interface-type"
                    }
                  >
                    <InterfaceLayoutSvg />
                  </div>
                  <div
                    onClick={() => setInterfaceType("row")}
                    className={
                      interfaceType === "row"
                        ? `interface-type active-interface`
                        : "interface-type"
                    }
                  >
                    <InterfaceRowSvg />
                  </div>
                </>
              )}
            </FilterRow>
            {interfaceType === "row" && gamesStore.games ? (
              gamesStore.games
                .filter((data) => data.type === filter || filter === "all")
                .map((data) => {
                  return (
                    <MiniTVCard
                      key={data.gameId}
                      time={`${+data.startingTime / 60} + ${data.additionalTime}`}
                      type={data.type || GameType.blitz}
                      userBlack={translatePlayerToPlayerProps(data.playerBlack)}
                      userWhite={translatePlayerToPlayerProps(data.playerWhite)}
                      gameId={data.gameId}
                    />
                  );
                })
            ) : (
              <div className="d-flex flex-wrap" style={{ gap: "0.5rem" }}>
                {gamesStore.games
                  .filter((data) => data.type === filter || filter === "all")
                  .map((data) => {
                    return (
                      <LayoutCard
                        key={data.gameId}
                        time={`${+data.startingTime / 60} + ${data.additionalTime}`}
                        type={data.type || GameType.blitz}
                        userBlack={translatePlayerToPlayerProps(
                          data.playerBlack
                        )}
                        userWhite={translatePlayerToPlayerProps(
                          data.playerWhite
                        )}
                        gameId={data.gameId}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TvPage;
