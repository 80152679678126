import "./ratingCard.scss";

import React, { useEffect, useState } from "react";
import PlayerInfoCard from "../playerInfoCard/PlayerInfoCard";
import history from "../../history";
import ArrowSvg from "../../svg/ArrowSvg";
import { ERatingFilter } from "../../views/ratings/Ratings";

export const calculateProc = (current: number, all: number) => {
  return Number((current / all) * 100).toFixed(0);
};

const RatingCard = (props: any) => {
  const [innerWidth, setInnerWidth] = useState<number>();
  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [innerWidth]);

  return (
    <div className="rating-card card align-items-center">
      <div className="firstColumn d-flex align-items-center ">
        <PlayerInfoCard
          name={`${
            props.firstName && props.lastName
              ? props.firstName + " " + props.lastName
              : "unknown"
          }`}
          country={"ru"}
          rank={props.rank}
          photo={props.photo}
        />
      </div>
      <div className="secondColumn d-flex align-items-center">
        {props.ratingType === ERatingFilter.elo
          ? Number(props.ratingElo).toFixed(2)
          : Number(props.ratingPlaymaker).toFixed(2)}
      </div>
      {innerWidth && innerWidth > 630 && (
        <>
          <div className="thirdColumn d-flex align-items-center">
            {!props.contest
              ? props.gamestatistic?.wins || "-"
              : props.wins || "-"}
            <span className="percent">
              {!props.contest
                ? props.gamestatistic &&
                  calculateProc(
                    props.gamestatistic?.wins,
                    props.gamestatistic?.gamesCount
                  )
                : calculateProc(props.wins, props.gamesCount)}
              %
            </span>
          </div>
          <div className="fourthColumn d-flex align-items-center">
            {!props.contest
              ? props.gamestatistic?.lose || "-"
              : props.lose || "-"}
            <span className="percent">
              {!props.contest
                ? props.gamestatistic &&
                  calculateProc(
                    props.gamestatistic?.lose,
                    props.gamestatistic?.gamesCount
                  )
                : calculateProc(props.lose, props.gamesCount)}
              %
            </span>
          </div>
          <div className="fifthColumn d-flex align-items-center">
            -<span className="percent">-</span>
          </div>
        </>
      )}
      <div
        onClick={() => history.push(`/profile/${props._id}`)}
        className="redirect d-flex align-items-center"
        style={{ marginLeft: "auto" }}
      >
        <ArrowSvg />
      </div>
    </div>
  );
};

export default RatingCard;
