import ContestCardStatusLabel, {labelStatus} from '../../views/contest/components/label/ContestCardStatusLabel';
import React from 'react';

import './contestDescriptionCard.scss';
import history from '../../history';
import { useTranslation } from 'react-i18next';


interface IContestCardProps {
  prize?: string;
  status?: labelStatus;
  description?: string;
  date?: string;
  users?: string|number;
  link?: string;
}


const ContestDescriptionCard = (
  {
    prize,
    status,
    description,
    date,
    users,
    link}: IContestCardProps) => {

  const { t } = useTranslation();
  return (
    <div className='contest-description-card'>
      <div className="d-flex justify-between align-items-center">
        <div className="prize d-flex align-items-center">
          {prize} ₽
        </div>
        <ContestCardStatusLabel status={status || labelStatus.check} />
      </div>
      <div className="description-wrapper">
        <div className="description__title">
          { t('description') }
        </div>
        <div className="description">
          {description}
        </div>
        <div className="description__link">
          <span onClick={() => history.push(link || '')}>{ t('regulation') }</span>
        </div>
      </div>
      <div className="d-flex justify-between align-items-center">
        <div className="date d-flex align-items-center">
          {date}
        </div>
        <div className="users d-flex align-items-center">
          {users}
        </div>
      </div>
    </div>
  );

}

export default ContestDescriptionCard;