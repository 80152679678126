import React from 'react';

const InterfaceRowSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.95714 2.19236C1.54554 3.52645 1.54554 4.97349 1.95714 6.30758C6.64146 6.75904 11.3583 6.75904 16.0427 6.30758C16.4543 4.97349 16.4543 3.52645 16.0427 2.19236C11.3583 1.74089 6.64146 1.74089 1.95714 2.19236ZM1.70865 0.709432C6.55792 0.235202 11.4419 0.235202 16.2912 0.709432C16.8257 0.761708 17.275 1.128 17.4392 1.63377C17.9888 3.32686 17.9888 5.17307 17.4392 6.86617C17.275 7.37194 16.8257 7.73823 16.2912 7.7905C11.4419 8.26473 6.55792 8.26473 1.70865 7.7905C1.1741 7.73823 0.724832 7.37194 0.560647 6.86617C0.0110343 5.17307 0.0110343 3.32686 0.560647 1.63377C0.724832 1.128 1.1741 0.761708 1.70865 0.709432Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.95714 11.6924C1.54554 13.0265 1.54554 14.4735 1.95714 15.8076C6.64146 16.259 11.3583 16.259 16.0427 15.8076C16.4543 14.4735 16.4543 13.0265 16.0427 11.6924C11.3583 11.2409 6.64146 11.2409 1.95714 11.6924ZM1.70865 10.2094C6.55792 9.7352 11.4419 9.7352 16.2912 10.2094C16.8257 10.2617 17.275 10.628 17.4392 11.1338C17.9888 12.8269 17.9888 14.6731 17.4392 16.3662C17.275 16.8719 16.8257 17.2382 16.2912 17.2905C11.4419 17.7647 6.55792 17.7647 1.70865 17.2905C1.1741 17.2382 0.724832 16.8719 0.560647 16.3662C0.0110343 14.6731 0.0110343 12.8269 0.560647 11.1338C0.724832 10.628 1.1741 10.2617 1.70865 10.2094Z" fill="white"/>
    </svg>
  );
};

export default InterfaceRowSvg;