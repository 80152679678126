import React from 'react';
import {Modal} from 'antd';
import CloseModalSvg from '../../../../svg/CloseModalSvg';
import {ModalProps} from '../../../auth/types';
import FilledButton from '../../../buttons/filledButton/FilledButton';
import OutlineButton from '../../../buttons/outlineButton/OutlineButton';
import InfoTriangleSvg from '../../../../svg/InfoTriangleSvg';
import '../modalWaitTimer.scss';
import { useTranslation } from 'react-i18next';

interface NotFoundModalProps extends ModalProps {
  onClickContinue: any;
}

const ModalNotFound = (props: NotFoundModalProps) => {
  const style = {
    background: '#303249',
    borderRadius: '12px',
    border: 0,
  };
  const { t } = useTranslation();

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={<CloseModalSvg />}
      maskClosable={false}
      visible={props.visible}
      bodyStyle={style}
      wrapClassName={'modal'}
      className={props.className}
      onCancel={props.handleCancel}
      centered={true}
    >
      <InfoTriangleSvg />
      <h2>{t('competitorNotFound')}</h2>
      <div className='d-flex justify-between' style={{width: '100%'}}>
        <FilledButton
          title={t('searchMore')}
          style={{width: '49%', height: 'auto'}}
          handleOnClick={props.onClickContinue}/>
        <OutlineButton title={t('playWithComputer')} style={{width: '49%', height: 'auto'}} />
      </div>
    </Modal>
  );
};

export default ModalNotFound;