import React, { useContext, useEffect } from "react";
import "./profile.scss";
import FilledButton from "../../components/buttons/filledButton/FilledButton";
import { Avatar, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import AboutMe from "./components/aboutMe/AboutMe";
import {
  IPropsRatingCardProfile,
  RatingType,
} from "./components/ratingCard/types";
import RatingCard from "./components/ratingCard";
import NameProfile from "./components/name/NameProfile";
import { IPropsNameProfile } from "./components/name/types";
import ShortCardProfile from "./components/shortCardProfile";
import HistoryProfile from "./components/historyProfile";
import { IDataSourceTableHistoryProfile } from "./components/historyProfile/types";
import { IPropsShortCardProfile } from "./components/shortCardProfile/types";
import history from "../../history";
import { useParams } from "react-router";
import userStore from "../../store/user.store";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useTranslation } from "react-i18next";

export interface IInfo {
  description: string;
  value: string;
}

const Profile = () => {
  const { id } = useParams<{ id: string }>();
  const { authStore } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {
    if (id === 'edit') {
      history.push(`/`);
    }
    userStore.getUser(id);
    userStore.getGameList(id);

    return () => {
      userStore.clearProfileData();
    };
  }, [id]);

  return (
    <div className="container">
      <div className="profile">
        <Spin spinning={userStore.isLoading}>
          <div className="d-flex justify-between align-items-center">
            <h1>{ t('profile') }</h1>
            {authStore.user.id === id && (
              <FilledButton
                handleOnClick={() => history.push("/profile/edit")}
                title={ t('edit') }
              />
            )}
          </div>
          <div className="profile--wrapper">
            <div
              className="d-flex md--flex-column"
              style={{ columnGap: "1.5rem", rowGap: "16px" }}
            >
              {userStore.profileData && (
                <>
                  <ShortCardProfile
                    avatar={userStore.profileData?.photo || null}
                    name={{
                      name: userStore.profileData?.lastName
                        ? `${userStore.profileData.lastName} ${userStore.profileData.firstName}`
                        : "unknown",
                      country: userStore.profileData?.country || null,
                      rank: userStore.profileData?.rank || null,
                    }}
                    email={"unknown"}
                    ratingElo={userStore.profileData.ratingElo || "0"}
                    ratingPlaymaker={
                      userStore.profileData.ratingPlaymaker || "0"
                    }
                  />
                  {console.log(userStore.profileData)}
                  {userStore.profileData && (
                    <AboutMe
                      description={userStore.profileData!.aboutMe || null}
                      infos={[
                        {
                          description: t('age'),
                          value: userStore.profileData?.age || "",
                        },
                        {
                          description: t('experience'),
                          value: userStore.profileData.experience || "",
                        },
                        {
                          description: t('country'),
                          value: (userStore.profileData.country && userStore.profileData.country.name) || "",
                        },
                        {
                          description: t('locality'),
                          value: userStore.profileData.city || "",
                        },
                      ]}
                    />
                  )}
                </>
              )}
            </div>
            <h2 className="subtitle">{ t('history') }</h2>
            <HistoryProfile data={userStore.gameList} id={id} />
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default observer(Profile);
