import React from 'react';
import {Modal} from 'antd';
import CloseModalSvg from '../../../svg/CloseModalSvg';
import {ModalProps} from '../../auth/types';
import ClockSvg from '../../../svg/ClockSvg';
import './modalWaitTimer.scss';
import { useTranslation } from 'react-i18next';

interface ModalWaitProps extends ModalProps{
  timer: number;
}

const ModalWaitTimer = (props: ModalWaitProps) => {
  const { t } = useTranslation();

  const style = {
    background: '#303249',
    borderRadius: '12px',
    border: 0,
  };

  const numberToTime = (number: number) => {
    const minutes = number >= 60 ? Math.floor(number / 60) : '0';
    let seconds: string = String(number % 60);
    seconds = +seconds >= 10 ? seconds : `0${seconds}`;
    return `${minutes} : ${seconds}`;
  } ;

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={<CloseModalSvg />}
      maskClosable={false}
      visible={props.visible}
      bodyStyle={style}
      wrapClassName={'modal'}
      className={props.className}
      onCancel={props.handleCancel}
      centered={true}
    >
      <ClockSvg />
      <h2>{t('searching')}</h2>
      <p>{numberToTime(props.timer)}</p>
    </Modal>
  );
};

export default ModalWaitTimer;