import { Button, Form, FormInstance, Modal } from 'antd';
import React, { createRef, useState } from 'react'
import GameSettingParam from './gameSettingParam/GameSettingParam';
import "./gameSetting.scss";
import themeStore from '../../../../store/theme.store';
import OutlineButton from '../../../buttons/outlineButton/OutlineButton';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import Emitter from '../../../../emitter/Emitter';
import CloseModalSvg from '../../../../svg/CloseModalSvg';
const GameSetting = ()=>{
    const [isSettingVisible, setSettingVisible] = useState<boolean>(false);
    const formRef = createRef<FormInstance>();
    const [speed, setSpeed] = useState<string>("");
    const handleOnChangeSpeed = (speed : string)=>{
      setSpeed(speed);
    }
  
    const { t } = useTranslation();
    const openSetting = ()=>{
        setSettingVisible(true);
    }
    const closeSetting = ()=>{
      setSettingVisible(false);
    } 

    const basic_setting = (
      <div className={`gameSetting ${themeStore.theme}`}>
        <div className='gameSetting__left'>
        {/* <GameSettingParam
            title={t('nameOfMatch')}
            type='input'
            formName='matchName'
            formRef={formRef}
          /> */}
          <GameSettingParam
            title={t('private')}
            type='checkbox'
            formName='isPrivate'
            defaultChecked={false}
            formRef={formRef}
          />
          <GameSettingParam
            title={t('rengo')}
            type='checkbox'
            formName='isRengo'
            defaultChecked={false}
            formRef={formRef}
          />
          </div>
          <div className='gameSetting__right'>
          <GameSettingParam
              title={t('rating')}
              formName='isRating'
              type='checkbox'
              defaultChecked={true}
              formRef={formRef}
            />
            <GameSettingParam
              title={t('sizeOfBoard')}
              formName='size'
              type='select'
              list={["9x9","13x13","19x19"]}
              defaultValue="19x19"
              formRef={formRef}
            />
            
          </div>
      </div>
    );
    const advanced_setting = (
      <div className={`gameSetting ${themeStore.theme}`}>
        <div className="gameSetting__left">
        <GameSettingParam
          title={t("ruleOfGame")}
          formName="rules"
          type="select"
          list={["japanese", "chinese"]}
          defaultValue={"japanese"}
          formRef={formRef}
          withTranslation={true}
        />
        <GameSettingParam
          title={t("speedOfGame")}
          formName="speed"
          type="select"
          list={["blitz", "normal", "slow"]}
          defaultValue={"normal"}
          formRef={formRef}
          withTranslation={true}
          onChange={handleOnChangeSpeed}
        />
        {/* <GameSettingParam
          title={t("controlOfTime")}
          formName="timeControl"
          type="select"
          list={["simple","fisherWatch"]}
          defaultValue="simple"
          formRef={formRef}
          withTranslation={true}
        /> */}
        {
        speed == "blitz" ? (
          <GameSettingParam
          title={t("initTime")}
          formName="startingTime"
          type="select"
          list={["5s", "10s", "30s", "1min"]}
          valueList={["5","10","30","60"]}
          formRef={formRef}
          withTranslation={true}
        />
        ) : speed == "slow" ? (
          <GameSettingParam
          title={t("initTime")}
          formName="startingTime"
          type="select"
          list={["1day", "2days", "3days"]}
          formRef={formRef}
          withTranslation={true}
          valueList={[`${1*86400}`,`${2*86400}`,`${3*86400}`] }
        />
        ) : (
          <GameSettingParam
          title={t("initTime")}
          formName="startingTime"
          type="select"
          list={["1min", "5mins", "10mins"]}
          valueList={[`${1*60}`,`${5*60}`,`${10*60}`]}
          formRef={formRef}
          withTranslation={true}
        />
        )
      }
        {/* <GameSettingParam
          title={t("addAdditionalTime")}
          formName="additionalTime"
          type="select"
          list={["1day", "3days"]}
          valueList={[`${1*86400}`,`${3*86400}`]}
          defaultValue={"1day"}
          formRef={formRef}
          withTranslation={true}
        /> */}
        {/* <GameSettingParam
          title={t("maxTime")}
          formName="maxTime"
          type="select"
          list={["3days", "7days"]}
          valueList={[`${3*86400}`,`${7*86400}`]}
          formRef={formRef}
          withTranslation={true}
        /> */}
        {/* <GameSettingParam
          title={t("breakOnWeekends")}
          formName="stopOnWeekends"
          type="checkbox"
          defaultChecked={true}
          formRef={formRef}
        /> */}
        </div>
        <div className="gameSetting__right">
        <GameSettingParam
          title={t("komi")}
          formName="komi"
          type="input"
          defaultValue={"6.5"}
          formRef={formRef}
          onlyNumber={true}
        />
        <GameSettingParam
          title={t("yourColor")}
          formName="color"
          type="select"
          list={["white", "black"]}
          defaultValue={"black"}
          formRef={formRef}
          withTranslation={true}
        />
        {/* <GameSettingParam
          title={t("disableAnalyze")}
          formName="stopAnalyze"
          type="checkbox"
          defaultChecked={false}
          withStar={true}
          formRef={formRef}
        /> */}
        {/* <GameSettingParam
          title={t("limitLevel")}
          formName="limitedLevel"
          type="checkbox"
          defaultChecked={false}
          formRef={formRef}
        /> */}
        {/* <p style={{ textAlign: "right" }}>
          * {t("alsoLimitMoves")} &nbsp;&nbsp;&nbsp;
        </p> */}
        </div>
      </div>
    );
    const btns_style = {
      width: "auto",
      padding: ".5rem .5rem"
    }
    const submit = (e : any) => {
      formRef.current?.validateFields().then(() => {
        setSettingVisible(false);
        Emitter.emit("createGame",e);
      });
    }
  return (
    <div>
      <OutlineButton
        title={t("setting")}
        handleOnClick={openSetting}
        style={btns_style}
      />
      <Modal
        visible={isSettingVisible}
        width="1000px"
        footer={false}
        onCancel={closeSetting}
        closeIcon={<CloseModalSvg />}
        className={`card gameSetting-modal ${themeStore.theme}`}
      >
        <Form name="gameSetting" onFinish={submit} ref={formRef}>
          <div className="d-flex flex-column justify-center gameSetting-modal">
            <h1 className={`gameSetting__title ${themeStore.theme}`}>
              {t("gameSetting")}
            </h1>
            {basic_setting}
            {advanced_setting}
            <div className="gameSetting__footer d-flex justify-between">
              <Button onClick={closeSetting}>{t("close")}</Button>
              <Button type="primary" htmlType="submit" onClick={submit}>
                {t("createMatch")}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default GameSetting;
