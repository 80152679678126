import React, { useContext, useState } from "react";
import { Form, Input } from "antd";
import FilledButton from "../../buttons/filledButton/FilledButton";
import OutlineButton from "../../buttons/outlineButton/OutlineButton";
import { ModalProps } from "../types";
import { Context } from "../../../index";
import { observer } from "mobx-react-lite";
import AuthModal from "../modal/AuthModal";
import SocketService from "../../../services/Socket.service";
import { useTranslation } from "react-i18next";

const Login = (props: ModalProps) => {
  const { t } = useTranslation();
  const { authStore } = useContext(Context);
  const [error, setError] = useState<string>("");

  const _ = () => {};

  const submit = (e: { email: string; password: string }) => {
    const loginCallback = (res: any) => {
      SocketService.disconnectRoom();
      SocketService.userListener(res.id);
    };
    authStore
      .login(e.email, e.password)
      .then(loginCallback)
      .catch((err) => {
        setError(t(err));
      });
  };

  const handlerChangeInput = () => {
    setError("");
  };

  const modalProps = {
    className: "login",
    title: t('login'),
    visible: props.visible,
    handleCancel: props.handleCancel,
  };

  return (
    <AuthModal {...modalProps}>
      <Form name={"login"} onFinish={submit}>
        <Form.Item
          label={null}
          name="email"
          rules={[
            { required: true, message: t('requiredField') },
            { type: "email", message: t('fieldForEmail') },
          ]}
        >
          <Input
            className={error && "input-error"}
            onChange={handlerChangeInput}
            type="email"
            placeholder={t('email')}
          />
        </Form.Item>
        <Form.Item
          label={null}
          name="password"
          rules={[{ required: true, message: t('requiredField') }]}
        >
          <Input
            className={error && "input-error"}
            onChange={handlerChangeInput}
            type="password"
            placeholder={ t('password') }
          />
        </Form.Item>
        <p onClick={props.remindPassword || _}>{t('recovery')}</p>
        {error && <p className="error">{error}</p>}
        <FilledButton title={ t("loginVerb") } />
        <OutlineButton
          type={"button"}
          title={ t('registration') }
          handleOnClick={props.openModal || _}
        />
      </Form>
    </AuthModal>
  );
};

export default observer(Login);
