import React, { FC, useEffect, useState } from "react";
import "./BoardView.scss";
import GoBoard from "../goBoard/GoBoard";
import BoardIntersection from "../boardIntersection/BoardIntersection";

interface IBoardView {
  className?: string;
  onMove: Function;
  board: GoBoard;
}

const BoardView: FC<IBoardView> = ({ className, onMove, board }) => {
  const intersections = [];
  for (let i = 0; i < board.size; i++) {
    for (let j = 0; j < board.size; j++) {
      intersections.push(
        BoardIntersection({
          row: i,
          col: j,
          color: board.board[i][j],
          board,
          onPlay: onMove,
        })
      );
    }
  }

  // const style = {
  //   width: board.size * 30 + 40,
  //   height: board.size * 30 + 40,
  // };

  let style;
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);

  useEffect(()=>{
    const handleResize = ()=>{setInnerWidth(window.innerWidth)}
    window.addEventListener('resize', handleResize);
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[]);
  if(innerWidth < 460) style = {
    width: board.size * 20 + 40,
    height: board.size * 20 + 40
  }
  else style = {
    width: board.size * 30 + 40,
    height: board.size * 30 + 40
  }
  return (
    <div style={style} id="board">
      {intersections.map((item, index) => (
        <div key={index} className={className}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default BoardView;
