import React, {useContext, useEffect} from 'react';
import { Form, Input, Row } from "antd";
import { Footer } from "antd/es/layout/layout";
import "./footer.scss";
import BigLogo from "../../svg/BigLogo";
import Age from "../../svg/Age";
import Frame from "../../svg/Frame";
import SocialLink from "../socialLink/SocialLink";
import Twitter from "../../svg/Twitter";
import Vk from "../../svg/Vk";
import Yt from "../../svg/Yt";
import ArrowSvg from "../../svg/ArrowSvg";
import {ThemeContext} from '../../providers/ThemeProvider';
import {useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MainFooter = () => {
  const submit = () => {};
  const { themeStore } = useContext(ThemeContext);
  const router = useHistory();
  const { t } = useTranslation();
  
  const [imgLight, setImgLight] = React.useState<string>();
  const [imgDark, setImgDark] = React.useState<string>();

  useEffect(()=>{
    import(`../../assets/images/logo/${localStorage.getItem("lang")}_logoDark.png`).then((img)=>setImgDark(img.default));
    import(`../../assets/images/logo/${localStorage.getItem("lang")}_logoLight.png`).then((img)=>setImgLight(img.default));
  })
  return (
    <Footer className={"footer"}>
      <div className="fluid-container">
        <Row
          className={"md-flex-column sm-align-items-center"}
          justify={"space-between"}
          align={"top"}
        >
          {themeStore.theme === "dark" ? (
            // <img src={imgDark} alt="" className='logo' />
            <h1>下围棋</h1>
          ) : (
            // <img src={imgLight} alt="" className='logo' />
            <h1>下围棋</h1>
          )}

          {/* <div
            className={
              "d-flex justify-between md-w-100 m-flex-column sm-align-items-center"
            }
          >
            <div className={"footer-info"}>
              <span className="footer-title"> { t('law') } :</span>
              <ul>
                <li>
                  <a target={"_blank"} href="/public_off.pdf" download>
                    { t('userAgreement') }
                  </a>
                </li>
                <li>
                  <a target={"_blank"} href="/politic_conf.pdf" download>
                    { t('privacyPolicy') }
                  </a>
                </li>
                <li>
                  <a target={"_blank"} href="/poloshenie.pdf" download>
                    { t('regulations') }
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-right">
              <div className="footer-title">
                <div style={{ marginBottom: ".75rem" }}>{ t('subscribe') }:</div>
                <div className={"d-flex flex-column"}>
                  <SocialLink
                    className={"d-flex align-items-center justify-center"}
                    style={{
                      minHeight: "2.28rem",
                      maxWidth: "26rem",
                      padding: 0,
                      paddingLeft: "70px",
                      paddingRight: 70,
                    }}
                    title={Yt}
                    handleOnClick={() => {window.open("https://www.youtube.com/channel/UCUM6kyotXIwfS5cSJCv5a2g")}}
                  >
                    <span style={{ marginLeft: ".6rem" }}>
                      { t('youtube') }
                    </span>
                  </SocialLink>
                  <div
                    className="d-flex footer-button"
                    style={{ marginTop: "0.44rem" }}
                  >
                    <SocialLink title={Frame} handleOnClick={() => {window.open("https://zen.yandex.ru/playmaker24")}} />
                    <SocialLink title={Twitter} handleOnClick={() => {window.open("https://twitter.com/plmkr24")}} />
                    <SocialLink title={Vk} handleOnClick={() => {window.open("https://vk.com/plmkr24")}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div>
            <div
              style={{ marginBottom: "0.7rem" }}
              className={"d-flex align-items-center credits"}
            >
              <Age />{" "}
              <span style={{ marginLeft: ".6rem" }}>{ t('for18') }</span>
            </div>
            <div className={"credits"}>
              { t('project') }.{" "}
            </div>
          </div> */}
        </Row>
      </div>
    </Footer>
  );
};

export default MainFooter;
