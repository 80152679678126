import "./name-profile.scss";
import React from "react";
import { IPropsNameProfile } from "./types";

const NameProfile = ({ name, country, rank }: IPropsNameProfile) => {
  return (
    <>
      <div className="d-flex align-items-center name-profile">
        <h4>{name}</h4>
        {country && (
          <span
            className={`flag-icon flag-icon-${country} flag-icon-squared`}
          ></span>
        )}
      </div>
      <p>{rank}</p>
    </>
  );
};

export default NameProfile;
