import "./chessComponent.scss";

// @ts-ignore
import Chessground from "react-chessground";

import React, { useState } from "react";
import { IUser } from "../../types/models/IUser";
import BlackSvg from "../../svg/BlackSvg";
import WhiteSvg from "../../svg/WhiteSvg";
import PlayerRow from "./components/playerRow/PlayerRow";

interface IProps {
  blackPlayer: IUser;
  whitePlayer: IUser;
}

const ChessComponent = ({ blackPlayer, whitePlayer }: IProps) => {

  const calcMovable = () => {
    return {
      color: null,
    };
  };

  return (
    <div className="chess-component">
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center player top">
          <PlayerRow svg={<WhiteSvg />} {...blackPlayer} />
        </div>
        <div className="chess-component__game">
          <Chessground
            movable={calcMovable()}
            coordinates={false}
            width={"auto"}
            height={"282px"}
          />
        </div>
        <div className="d-flex player bottom">
          <PlayerRow svg={<BlackSvg />} {...whitePlayer} />
        </div>
      </div>
    </div>
  );
};

export default ChessComponent;
