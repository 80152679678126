import React, {FC} from 'react';
import {IUser} from '../../../../types/models/IUser';
import {Avatar} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import WhiteSvg from '../../../../svg/WhiteSvg';

interface IProps extends IUser {
  svg: React.ReactElement;
}

const PlayerRow = ({photo, firstName, lastName, svg}: IProps) => {
  return (
    <div>
      <div className="d-flex align-items-center" style={{ columnGap: ".5rem" }}>
        {photo ? (
          <Avatar size={24} className="avatar" src={photo} />
        ) : (
          <Avatar size={24} className="avatar">
            <UserOutlined />
          </Avatar>
        )}
        <div>
          {lastName} {firstName}
        </div>
        <div className='d-flex align-items-center'>
          {svg}
        </div>
      </div>
    </div>
  );
};

export default PlayerRow;