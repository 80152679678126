import React from 'react';
import { useTranslation } from 'react-i18next';
import history from '../../history';

interface IProps{
  title: string;
}

const Title = ({title}: IProps) => {
  const{ t } = useTranslation();
  return (
    <div className='component__title'>
      <div className="back" onClick={() => history.goBack()}>
        {t('back')}
      </div>
      <div className="title">{title}</div>
    </div>
  );
};

export default Title;