import "./aboutMe.scss";
import React from "react";
import Info from "../info";
import { IInfo } from "../../Profile";
import EmptyDescription from "./components/emptyDescription/EmptyDescription";
import { useTranslation } from "react-i18next";

interface IProps {
  description: string|null;
  infos: IInfo[]|null;
}

const AboutMe = ({ description, infos }: IProps) => {
  const { t } = useTranslation();
  const infoData = [
    { description: t('age'), value: infos ? infos[0].value : '' },
    { description: t('experience'), value:  infos ? infos[1].value : '' },
    { description: t('country'), value:  infos ? infos[2].value : '' },
    { description: t('locality'), value:  infos ? infos[3].value : '' },
  ];
  return (
    <div className="card about-me">
      <h3>{ t('aboutMe') }</h3>
      {description ? <p>{description}</p> : <EmptyDescription />}
      <Info infos={infoData} />
    </div>
  );
};

export default AboutMe;
