import React from 'react';

const ClassicSvg = () => {
  return (
    <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8795 11.7523C13.1767 12.0664 13.5798 12.2429 14.0001 12.2429C14.4205 12.2429 14.8236 12.0664 15.1208 11.7523L17.2339 9.51932C17.6871 9.04034 17.8227 8.32 17.5774 7.69418C17.3321 7.06837 16.7542 6.66032 16.1132 6.66032H11.8871C11.2461 6.66032 10.6682 7.06837 10.4229 7.69418C10.1776 8.32 10.3132 9.04034 10.7664 9.51932L12.8795 11.7523Z" fill="#555770"/>
      <path d="M12.8795 27.248C13.1767 26.9339 13.5798 26.7575 14.0001 26.7575C14.4205 26.7575 14.8236 26.9339 15.1208 27.248L17.2339 29.481C17.6871 29.96 17.8227 30.6803 17.5774 31.3061C17.3321 31.932 16.7542 32.34 16.1132 32.34H11.8871C11.2461 32.34 10.6682 31.932 10.4229 31.3061C10.1776 30.6803 10.3132 29.96 10.7664 29.481L12.8795 27.248Z" fill="#555770"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.4281 19.5002L16.8239 19.1398C20.9982 15.3392 24.4688 10.753 27.0595 5.6141C28.0761 3.59752 26.8139 1.1388 24.66 0.939783L22.1802 0.710665C16.7372 0.207772 11.2631 0.207773 5.82016 0.710667L3.34036 0.939786C1.1864 1.1388 -0.0757546 3.59753 0.940869 5.61411C3.53155 10.753 7.00215 15.3392 11.1764 19.1398L11.5722 19.5002L11.1764 19.8605C7.00215 23.6611 3.53155 28.2473 0.940869 33.3862C-0.0757546 35.4028 1.1864 37.8615 3.34036 38.0605L5.82016 38.2897C11.2631 38.7926 16.7372 38.7926 22.1802 38.2897L24.66 38.0605C26.8139 37.8615 28.0761 35.4028 27.0595 33.3862C24.4688 28.2473 20.9982 23.6611 16.8239 19.8605L16.4281 19.5002ZM14.0079 17.2811C14.0088 17.2808 14.0106 17.2801 14.0139 17.277L14.755 16.6023C18.5736 13.1255 21.7582 8.94088 24.1524 4.25519L21.9041 4.04746C16.6448 3.56154 11.3555 3.56154 6.09623 4.04746L3.84791 4.25519C6.24213 8.94089 9.42674 13.1255 13.2453 16.6023L13.9864 17.277C13.9886 17.279 13.99 17.2799 13.9911 17.2805C13.9917 17.2808 13.9907 17.2804 13.9911 17.2805C13.9927 17.2811 13.9967 17.2824 14.0002 17.2824C14.0036 17.2824 14.0062 17.2818 14.0079 17.2811ZM14.0139 21.7233C14.0106 21.7203 14.0088 21.7196 14.0079 21.7192C14.0071 21.7189 14.0061 21.7186 14.0049 21.7184C14.0035 21.7181 14.002 21.7179 14.0002 21.7179C13.9967 21.7179 13.9941 21.7186 13.9925 21.7192C13.9915 21.7196 13.9898 21.7203 13.9864 21.7233L13.2453 22.3981C9.42674 25.8749 6.24213 30.0594 3.84791 34.7451L6.09623 34.9529C11.3555 35.4388 16.6448 35.4388 21.9041 34.9529L24.1524 34.7451C21.7582 30.0594 18.5736 25.8749 14.755 22.3981L14.0139 21.7233Z" fill="#555770"/>
    </svg>
  );
};

export default ClassicSvg;